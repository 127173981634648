import React, { useState, useEffect, useRef } from "react";
import { FileUpload } from "../utilities/upload";
import { Close } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import CropImageModal from "./CropImageModal";

const AvatarUploader = ({ onChangeData, initialData, initialStyle, style }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageStyle, setImageStyle] = useState({
        width: "100%",
        height: "100%",
        transform: "translate(0%, 0%) scale(1)",
    });
    const imageRef = useRef(null);
    const isDragging = useRef(false);
    const lastMousePosition = useRef({ x: 0, y: 0 });

    useEffect(() => {
        if (initialData) {
            setSelectedImage(initialData);
        }
        if (initialStyle) {
            setImageStyle(initialStyle);
        }
    }, [initialData, initialStyle]);

    const handleImageUpload = async (fileBase64) => {
        // 画像が選択されていない場合のみ処理を行う
        if (fileBase64) {
            setLoading(true);
            try {
                const uploaded_url = await FileUpload(fileBase64);
                setSelectedImage(uploaded_url);
                onChangeData(uploaded_url, imageStyle);
            } catch (error) {
                console.error("画像のアップロードに失敗しました", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        onChangeData(null);
    };

    const handleMouseDown = (event) => {
        isDragging.current = true;
        lastMousePosition.current = { x: event.clientX, y: event.clientY };
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    const handleMouseMove = (event) => {
        if (isDragging.current && selectedImage && imageRef.current) {
            const deltaX = event.clientX - lastMousePosition.current.x;
            const deltaY = event.clientY - lastMousePosition.current.y;
            lastMousePosition.current = { x: event.clientX, y: event.clientY };

            setImageStyle((prevStyle) => {
                const translateMatch =
                    prevStyle.transform?.match(/translate\(([^)]+)\)/);
                const scaleMatch =
                    prevStyle.transform?.match(/scale\(([^)]+)\)/);
                if (translateMatch && scaleMatch) {
                    const [translateX, translateY] = translateMatch[1]
                        .split(",")
                        .map(parseFloat);
                    const newTranslateX = translateX + deltaX;
                    const newTranslateY = translateY + deltaY;
                    return {
                        ...prevStyle,
                        transform: `translate(${newTranslateX}px, ${newTranslateY}px) scale(${scaleMatch[1]})`,
                    };
                }
                return prevStyle;
            });
        }
    };

    // const handleWheel = (event) => {
    //     if (selectedImage && imageRef.current) {
    //         const delta = event.deltaY < 0 ? 1.1 : 0.9;
    //         setImageStyle((prevStyle) => {
    //             const scaleMatch =
    //                 prevStyle.transform?.match(/scale\(([^)]+)\)/);
    //             const translateMatch =
    //                 prevStyle.transform?.match(/translate\(([^)]+)\)/);
    //             if (scaleMatch && translateMatch) {
    //                 const scale = parseFloat(scaleMatch[1]);
    //                 const newScale = scale * delta;
    //                 return {
    //                     ...prevStyle,
    //                     transform: `translate(${translateMatch[1]}) scale(${newScale})`,
    //                 };
    //             }
    //             return prevStyle;
    //         });
    //     }
    // };

    return (
        <div className="relative">
            <label htmlFor="avatar-upload">
                <div
                    style={style}
                    className="w-[150px] h-[150px] max-[800px]:w-[110px] max-[800px]:h-[110px] rounded-full bg-white overflow-hidden cursor-pointer"
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    // onWheel={handleWheel}
                >
                    {selectedImage ? (
                        <img
                            ref={imageRef}
                            src={selectedImage}
                            alt="Avatar"
                            className="w-full h-full object-contain"
                            style={imageStyle}
                        />
                    ) : (
                        <svg
                            className="w-full h-full text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="0.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="12" y1="10" x2="12" y2="14" />
                            <line x1="10" y1="12" x2="14" y2="12" />
                        </svg>
                    )}
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                            <CircularProgress />
                        </div>
                    )}
                </div>
            </label>
            {selectedImage && (
                <Close
                    className="absolute top-0 right-0 -mt-2 -mr-2 text-white bg-gray-800 rounded-full p-1 cursor-pointer"
                    onClick={handleRemoveImage}
                />
            )}
            <CropImageModal
                id="avatar-upload"
                className="hidden"
                accept="image/*"
                onChange={handleImageUpload}
            />
        </div>
    );
};

export default AvatarUploader;
