import React, { useCallback, useEffect, useState } from "react";
import Imagelinkitem from "./Imagelinkitem";

export default function Twoimagelink({
    FileUpload,
    isClear,
    onChangeData,
    data,
    setIsLoading,
}) {
    const defaultChildData = [
        {
            imageLink: "",
            title: "",
            text: "",
            url: "",
            id: 0,
        },
        {
            imageLink: "",
            title: "",
            text: "",
            url: "",
            id: 1,
        },
    ];
    const [childDataArray, setChildDataArray] = useState(defaultChildData);

    useEffect(() => {
        if (data) {
            if (data.length) {
                setChildDataArray([...data]);
            } else {
                setChildDataArray([
                    {
                        imageLink: data.url1,
                        title: data.title1,
                        text: data.text1,
                        url: data.link1,
                        id: 0,
                    },
                    {
                        imageLink: data.url2,
                        title: data.title2,
                        text: data.text2,
                        url: data.link2,
                        id: 1,
                    },
                ]);
            }
        }
    }, [data]);

    useEffect(() => {
        if (isClear) {
            setChildDataArray(defaultChildData);
        }
    }, [isClear]);

    const handleChildData = useCallback(
        (childData) => {
            childData = { ...childData };
            const updatedChildDataArray = [...childDataArray];
            const index = updatedChildDataArray.findIndex(
                (data) => data.id === childData.id
            );
            if (index !== -1) {
                updatedChildDataArray[index] = childData;
            } else {
                updatedChildDataArray.push(childData);
            }
            onChangeData({ data: updatedChildDataArray, type: "twoImage" });
            setChildDataArray(updatedChildDataArray);
        },
        [childDataArray]
    );

    return (
        <div className="flex gap-2 justify-between">
            <div>
                <Imagelinkitem
                    FileUpload={FileUpload}
                    data={childDataArray?.[0]}
                    isClear={isClear}
                    id={0}
                    onChangeState={handleChildData}
                    setIsLoading={setIsLoading}
                />
            </div>
            <div>
                <Imagelinkitem
                    FileUpload={FileUpload}
                    data={childDataArray?.[1]}
                    isClear={isClear}
                    id={1}
                    onChangeState={handleChildData}
                    setIsLoading={setIsLoading}
                />
            </div>
        </div>
    );
}
