import { Button, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Apis } from "../../api";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../auth/context";
import { googleLogout } from "@react-oauth/google";
import liff from "@line/liff";

function EmailVerify() {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const token = params.get("token");
    const { resetUserInfo, setLoading } = useAuthContext();
    const [status, setStatus] = useState(0);
    const [email, setEmail] = useState("");

    useEffect(() => {
        const validateToken = async () => {
            try {
                setLoading(true);
                const response = await Apis.myPost(
                    "account/verify-update-email",
                    {
                        token: token,
                    }
                );
                if (response) {
                    setEmail(response.data.data);
                    await liff.init({ liffId: "2005830145-p9AqqgA3" });
                    liff.logout();

                    resetUserInfo();
                    googleLogout();
                    localStorage.removeItem("userInfo");
                    localStorage.removeItem("Publish_cache");
                    localStorage.removeItem("Publish_Social_cache");
                    localStorage.removeItem(
                        "Public_PublishCompleteModalNotShowAgain"
                    );
                    localStorage.removeItem(
                        "SNS_PublishCompleteModalNotShowAgain"
                    );
                    localStorage.removeItem("shippingInfo");
                    setStatus(1);
                }
            } catch (error) {
                setStatus(2);
                toast.error("メール認証に失敗しました");
            } finally {
                setLoading(false);
            }
        };
        if (token) validateToken();
    }, [token]);

    const handleLoginRedirect = () => {
        navigate("/d-dashboard");
    };

    return (
        <div>
            {status === 2 && (
                <div className="flex flex-col justify-center items-center gap-24 pt-20">
                    <Typography variant="h6" color="error">
                        トークンが無効です。
                    </Typography>
                </div>
            )}
            {status === 1 && (
                <div className="flex flex-col justify-center items-center gap-24 pt-20">
                    <Typography
                        variant="h6"
                        color="primary"
                        className="w-full text-center p-4"
                    >
                        登録のメールアドレスを変更いたしました。
                        <br />
                        今後ともDegimeをよろしくお願い致します。
                        <br /> 変更後：{email}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoginRedirect}
                    >
                        ログインページへ
                    </Button>
                </div>
            )}
        </div>
    );
}
export default EmailVerify;
