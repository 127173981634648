import { Box, Button, Modal } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Apis } from "../../../api";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const stripePromise = loadStripe(
    "pk_test_51Pd1iFRqrBEakRTBLh4nqDMZOCBExQnpm5RgJS3jh4CBAjiw0XhVJd3OwmN7urshBMWxZ9WI74QfzHKLiMj2qty800AqvqdYVP"
);
const PuschaseModal = ({
    show,
    onClose,
    price,
    totalPrice,
    totalPriceWithTax,
}) => {
    const [clientSecret, setClientSecret] = useState("");
    const [paymentIntentId, setPaymentIntentId] = useState("");
    const getPaymentIntent = async (amount) => {
        try {
            const res = await Apis.myPost("payment/payment-intent", { amount });
            if (res.success) {
                const data = res.data;
                if (data) {
                    setPaymentIntentId(data.paymentIntentId);
                    const newClientSecret = data?.clientSecret;
                    setClientSecret(newClientSecret);
                }
                return;
            }
            throw new Error(res.message);
        } catch (error) {
            console.error(error);
            onClose();
            toast.error("エラーが発生しました。");
        }
    };
    useEffect(() => {
        if (price) {
            getPaymentIntent(price);
        }
    }, [price]);
    return (
        <Modal open={show} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                {clientSecret && stripePromise ? (
                    <Elements
                        stripe={stripePromise}
                        options={{ clientSecret, locale: "ja" }}
                    >
                        <CheckoutForm
                            clientSecret={clientSecret}
                            totalPriceWithTax={totalPriceWithTax}
                            totalPrice={totalPrice}
                        />
                    </Elements>
                ) : (
                    <div></div>
                )}
                <Button
                    variant="contained"
                    color="inherit"
                    className="w-full"
                    style={{ marginTop: "8px" }}
                    onClick={onClose}
                >
                    閉じる
                </Button>
            </Box>
        </Modal>
    );
};
export default PuschaseModal;
