import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

const AlarmPart = ({ data, onChange }) => {
    const [open, setIsOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickItem = (item) => {
        onChange(item.id);
        setIsOpen(false);
    };

    return (
        <div className="downBar relative h-5" ref={ref}>
            <button
                className="relative "
                onClick={() => setIsOpen((prevState) => !prevState)}
            >
                <img
                    alt="alarm"
                    src="/image/alarm.png"
                    id="alarm"
                    className="cursor-pointer"
                ></img>
                {data.length !== 0 && <div className="alarmBadge"></div>}
            </button>
            {open &&
                (data.length === 0 ? (
                    <div className="downList !w-fit whitespace-nowrap mt-[10px]">
                        <div className="list">
                            <div className="listLink">通知はありません</div>
                        </div>
                    </div>
                ) : (
                    <div className="downList pc:!w-fit pc:whitespace-nowrap mt-[10px] pc:max-h-[500px] overflow-y-auto hidden-scroll mobile:w-[300px] mobile:max-h-[400px] mobile:h-auto">
                        {data.map((item, index) => (
                            <div key={index} className="list text-left">
                                {item.redirect_url === "" ? (
                                    <div
                                        className="listLink cursor-pointer"
                                        onClick={() => handleClickItem(item)}
                                    >
                                        {item.content
                                            .split("\n")
                                            .map((line, i) => (
                                                <div key={i}>{line}</div>
                                            ))}
                                    </div>
                                ) : (
                                    <Link
                                        className="listLink"
                                        target="_blank"
                                        to={item.redirect_url}
                                        onClick={() => handleClickItem(item)}
                                    >
                                        {item.content
                                            .split("\n")
                                            .map((line, i) => (
                                                <div key={i}>{line}</div>
                                            ))}
                                    </Link>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );
};

export default AlarmPart;
