import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
    Autocomplete,
    Badge,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    FormControlLabel,
    Modal,
    Popover,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { DotsThreeVertical } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router";
import { Apis, urlAPI } from "../../api";
import DialogFeeHistory from "../../components/DialogFeeHistory";
import Emailmarketing from "./Emailmarketing";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function Secondaryagency() {
    const [csvData, setCsvData] = useState([]);
    const [contactUsers, setContactUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(100);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [info, setInfo] = useState();
    const [openPaidConfirm, setOpenPaidConfirm] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openBacking, setOpenBacking] = useState(false);
    const [openFeeHistory, setOpenFeeHistory] = useState(false);
    const [loadingBank, setLoadingBank] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bankData, setBankData] = useState({
        bankLabel: null,
        branchLabel: null,
        card_type: "NOR",
    });
    const [isDisableSaveBank, setIsDisableSaveBank] = useState(true);
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);
    const [openModalSendMail, setOpenModalSendMail] = useState(false);

    const navigate = useNavigate();

    const [isMounted, setIsMounted] = useState(false);

    /* -------------------------------------------------------------------------- */
    /*                             Banking infomation                             */
    /* -------------------------------------------------------------------------- */
    const handleClickOpenBanking = () => {
        setOpenBacking(true);
        if (info?.user_bank) {
            setBankData({
                bank_code: info.user_bank?.bank_code,
                bank_name_kana: info.user_bank?.bank_name_kana,
                bank_name_hira: info.user_bank?.bank_name_hira,
                bank_name: info.user_bank?.bank_name,
                bankLabel: {
                    code: info.user_bank?.bank_code,
                    kana: info.user_bank?.bank_name_kana,
                    hira: info.user_bank?.bank_name_hira,
                    name: info.user_bank?.bank_name,
                    label:
                        info.user_bank?.bank_name +
                        " 、" +
                        info.user_bank?.bank_code,
                },
                bank_branch_code: info.user_bank?.bank_branch_code,
                bank_branch_kana: info.user_bank?.bank_branch_kana,
                bank_branch_hira: info.user_bank?.bank_branch_hira,
                bank_branch_name: info.user_bank?.bank_branch_name,
                branchLabel: {
                    code: info.user_bank?.bank_branch_code,
                    kana: info.user_bank?.bank_branch_kana,
                    hira: info.user_bank?.bank_branch_hira,
                    name: info.user_bank?.bank_branch_name,
                    label:
                        info.user_bank?.bank_branch_name +
                        " 、" +
                        info.user_bank?.bank_branch_code,
                },
                card_type: info.user_bank?.card_type,
                bank_number: info.user_bank?.bank_number,
                name: info.user_bank?.name,
            });
        }
    };

    const handleCloseBanking = () => {
        setOpenBacking(false);
        setBankData({ bankLabel: null, branchLabel: null, card_type: "NOR" });
    };

    const fetchDataBank = async (value) => {
        setLoadingBank(true);
        try {
            const response = (
                await Apis.myGet(
                    `${urlAPI.searchBank}?keySearch=${value ?? ""}`
                )
            )?.data;
            const data = response?.map((item) => {
                return {
                    ...item,
                    label: item.name + " 、" + item.code,
                };
            });
            setBanks(data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoadingBank(false);
        }
    };

    const getBankBranches = async (bankCode, keySearch) => {
        if (!bankCode) {
            setBranches([]);
            return;
        }
        const response = (
            await Apis.myGet(
                `${urlAPI.searchBankBranch}?keySearch=${keySearch}&bankCode=${bankCode}`
            )
        )?.data;

        const data = response?.map((item) => {
            return {
                ...item,
                label: item.name + " 、" + item.code,
            };
        });
        setBranches(data);
    };

    const handleSelectBank = (value) => {
        if (value) {
            const data = {
                ...bankData,
                bank_code: value.code,
                bank_name_kana: value.kana,
                bank_name_hira: value.hira,
                bank_name: value.name,
                bankLabel: value,
            };
            setBankData(data);
        } else {
            setBankData({
                ...bankData,
                bank_code: null,
                bank_name_kana: null,
                bank_name_hira: null,
                bank_name: null,
                bankLabel: null,
            });
            setBranches([]);
        }
    };

    const handleSelectBranch = (value) => {
        if (value) {
            const data = {
                ...bankData,
                bank_branch_code: value.code,
                bank_branch_kana: value.kana,
                bank_branch_hira: value.hira,
                bank_branch_name: value.name,
                branchLabel: value,
            };
            setBankData(data);
        } else {
            setBankData({
                ...bankData,
                bank_code: null,
                bank_name_kana: null,
                bank_name_hira: null,
                bank_name: null,
                branchLabel: null,
            });
        }
    };

    const handleChargeCardType = (event) => {
        setBankData({ ...bankData, card_type: event.target.value });
    };

    const handleSaveBankInfo = async () => {
        const response = (
            await Apis.myPost(urlAPI.createUserBank, {
                user_id: info.userId,
                bank_code: bankData.bank_code,
                bank_name_kana: bankData.bank_name_kana,
                bank_name_hira: bankData.bank_name_hira,
                bank_name: bankData.bank_name,
                bank_branch_code: bankData.bank_branch_code,
                bank_branch_kana: bankData.bank_branch_kana,
                bank_branch_hira: bankData.bank_branch_hira,
                bank_branch_name: bankData.bank_branch_name,
                card_type: bankData.card_type,
                bank_number: bankData.bank_number,
                name: bankData.name,
            })
        )?.data;
        if (response) {
            await handleGetListContactUsers();
            setAnchorEl(null);
            handleCloseBanking();
        }
    };

    useEffect(() => {
        if (
            bankData.bankLabel &&
            bankData.branchLabel &&
            bankData.name &&
            bankData.bank_number
        ) {
            setIsDisableSaveBank(false);
        } else {
            setIsDisableSaveBank(true);
        }
    }, [bankData]);

    /* -------------------------------------------------------------------------- */
    /*                               Payment History                              */
    /* -------------------------------------------------------------------------- */

    const [feeHistory, setFeeHistory] = useState([]);

    const fetchFeeHistory = async () => {
        const response = (
            await Apis.myGet(
                `${urlAPI.paymentHistory}?agencyId=${info.agency.id}`
            )
        )?.data;
        if (response.data)
            setFeeHistory(
                response.data.map((item) => {
                    return {
                        ...item,
                        date: item.date
                            ? dayjs(item.date).format("YYYY/MM/DD HH:mm:ss")
                            : "",
                    };
                })
            );
    };

    const handleOpenFeeHistory = async () => {
        await fetchFeeHistory();
        setOpenFeeHistory(true);
    };

    const handleCloseFeeHistory = async () => {
        setOpenFeeHistory(false);
    };

    /* -------------------------------------------------------------------------- */
    /*                                 Data table                                 */
    /* -------------------------------------------------------------------------- */

    const handleGetListContactUsers = async () => {
        const listContactUsers = (
            await Apis.myGet(
                `${urlAPI.secondaryAgency}?page=${page}&per_page=${per_page}&keySearch=${search}`
            )
        )?.data;

        setContactUsers(
            listContactUsers?.data.map((item, index) => {
                let isPaid = false;
                if (
                    item.total_payment !== null &&
                    item.last_payment_time !== null &&
                    item.total_payment >= item.count_user_buy_card.amountProfit
                )
                    isPaid = true;

                const needPayAmount =
                    (item.count_user_buy_card.amountProfit ?? 0) -
                    (item.total_payment ?? 0);

                return {
                    ...item,
                    userId: item.id,
                    id:
                        listContactUsers?.totalItem -
                        ((page - 1) * per_page + index),
                    amount: item.count_user_buy_card?.amount,
                    totalUser: item.count_user_buy_card?.totalUser,
                    registerDate: dayjs(new Date(item.date_joined)).format(
                        "YYYY-MM-DD"
                    ),
                    last_payment_time: item.last_payment_time
                        ? dayjs(item.last_payment_time).format("YY/MM/DD")
                        : null,
                    estimatedAmount: item.count_user_buy_card.amountProfit ?? 0,
                    isAgency: item.agency,
                    agencyDate: item.agency?.date
                        ? dayjs(item.agency?.date).format("YY/MM/DD")
                        : "",
                    isPaid: isPaid,
                    needPayAmount: needPayAmount,
                };
            })
        );
        setTotal(listContactUsers?.totalItem);
        await handleGetListContactUsersExport();
        setIsMounted(true);
    };

    const handleGetListContactUsersExport = async () => {
        const listContactUsers = (
            await Apis.myGet(`${urlAPI.secondaryAgency}?keySearch=${search}`)
        )?.data;

        setCsvData(
            listContactUsers?.data.map((item, index) => ({
                No:
                    listContactUsers?.totalItem -
                    ((page - 1) * per_page + index),
                氏名: `${item.full_name}`,
                ユーザーID: item.username,
                新規登録日時: new Date(item.date_joined).toISOString(),
                つながり人数: item.count_introduced,
                前月増加分: item.count_contact_increase_this_month,
                購入者数: item.count_user_buy_card?.amount ?? 0,
                販売代金合計: item.count_user_buy_card?.totalUser ?? 0,
                代理店登録: item.agency?.date
                    ? dayjs(item.agency?.date).format("YY/MM/DD") + "稼働"
                    : "登録する",
                推定収益合計: item.count_user_buy_card.amountProfit ?? 0,
                振込結果:
                    item.agency && item.agency
                        ? item.agency.is_paid
                            ? "振込済み"
                            : "未振込"
                        : "",
            }))
        );
    };
    const handleSelectChange = (event) => {
        setPer_page(parseInt(event.target.value));
    };

    const handleStorageChange = (event) => {
        setSearch(localStorage.getItem("search"));
    };

    const handleCreateAgency = async (user) => {
        const payload = {
            memberId: user.userId,
            level: 2,
        };
        const listContactUsers = (await Apis.myPost(urlAPI.agency, payload))
            ?.data;

        if (listContactUsers.data) {
            await handleGetListContactUsers();
        }
    };

    const handlePaid = async () => {
        setLoading(true);
        const payload = {
            memberId: info.userId,
            totalBuyer: info.totalUser,
            amount: info.needPayAmount,
            fee: 0,
            totalSale: info.amount,
            totalRevenue: info.estimatedAmount,
            countContactIncreaseThisMonth:
                info.count_contact_increase_this_month,
            totalUser: info.count_introduced,
        };

        try {
            const paid = (await Apis.myPut(urlAPI.agency, payload))?.data;

            if (paid.success) {
                await handleGetListContactUsers();
            }

            setOpenPaidConfirm(false);
            setInfo(null);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenModalPaidConfirm = (item) => {
        setInfo(item);
        setOpenPaidConfirm(true);
    };

    const handleCloseModalPaidConfirm = () => {
        setOpenPaidConfirm(false);
        setInfo(null);
    };

    const handleDeleteConfirm = () => {
        setOpenDeleteConfirm(true);
    };

    const handleFamilyTree = () => {
        if (info) navigate(`/admin/genealogies?userId=${info.userId}&tab=1`);
    };

    const handleOpenDetail = () => {
        navigate(`/${info.url_name}`);
    };

    const handleCloseModalDeleteConfirm = () => {
        setOpenDeleteConfirm(false);
        setInfo(null);
    };

    useEffect(() => {
        window.addEventListener("localStorageChange", handleStorageChange);
        // Cleanup event listener khi component unmount
        return () => {
            window.removeEventListener(
                "localStorageChange",
                handleStorageChange
            );
            const event = new Event("localStorageClear");
            window.dispatchEvent(event);
        };
    }, []);

    const handleClick = (row) => (event) => {
        setInfo(row);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    // Delete user
    const handleDelete = async () => {
        setAnchorEl(null);

        const isDeleted = (
            await Apis.myDelete(urlAPI.agency + "?memberId=" + info.userId)
        )?.data;

        if (isDeleted.success) {
            await handleGetListContactUsers();
        }

        setOpenDeleteConfirm(false);
        setInfo(null);
    };

    const handleCloseModalSendMail = () => setOpenModalSendMail(false);

    const handleOpenModalSendMail = () => {
        setOpenModalSendMail(true);
    };

    useEffect(() => {
        if (isMounted) handleGetListContactUsers();
    }, [page, per_page]);

    useEffect(() => {
        setPage(1);
        if (isMounted) handleGetListContactUsers();
    }, [search]);

    useEffect(() => {
        handleGetListContactUsers();
    }, []);
    return (
        <div className="px-10 mb-3">
            <CSVLink data={csvData}>
                <div className="csvDownload absolute z-30 top-24 right-10 cursor-pointer max-[600px]:w-[50px] bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                    CSV
                    <span className="max-[600px]:hidden min-[600px]:inline-block">
                        ファイルのダウンロード
                    </span>
                </div>
            </CSVLink>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                align="center"
                                sx={{ width: "60px" }}
                            >
                                No
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                氏名
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                ユーザーID
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                新規登録日時
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                つながり人数
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                前月増加分
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                購入者数
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                販売代金合計
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                代理店登録
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                推定収益合計
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                振込結果
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {contactUsers.map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {row.id}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.full_name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.username}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.registerDate}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.count_introduced}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.count_contact_increase_this_month}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.totalUser}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row.amount}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {!row.isAgency && (
                                        <Button
                                            onClick={() =>
                                                handleCreateAgency(row)
                                            }
                                            className="!rounded-lg !text-white cursor-pointer !bg-gray-400 hover:bg-gray-500 active:bg-gray-600 !text-sm px-1 md:px-4 !py-0 "
                                        >
                                            登録する
                                        </Button>
                                    )}

                                    {row.isAgency && (
                                        <Button className="!rounded-lg !text-white !bg-red-400 !text-sm px-1 md:px-4 !py-0 !cursor-default !min-w-28">
                                            {row.agencyDate}稼働
                                        </Button>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {!!row.last_payment_time && (
                                        <span className="text-red-500">
                                            {row.total_payment + "/"}
                                        </span>
                                    )}
                                    {row.estimatedAmount}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    className="!pr-0"
                                >
                                    <div className="flex justify-end items-center">
                                        {!!row.agency &&
                                            !row.isPaid &&
                                            !row.user_bank && (
                                                <Tooltip title="このアカウントにはまだ銀行情報が登録されていません">
                                                    <Badge
                                                        variant="dot"
                                                        color="error"
                                                    >
                                                        <Button className="!rounded-lg !text-white !cursor-not-allowed !bg-yellow-400 hover:bg-yellow-500 !text-sm px-1 md:px-4 !py-0 !min-w-24">
                                                            未振込
                                                        </Button>
                                                    </Badge>
                                                </Tooltip>
                                            )}
                                        {!!row.agency &&
                                            !row.isPaid &&
                                            !!row.user_bank && (
                                                <Button
                                                    onClick={() =>
                                                        handleOpenModalPaidConfirm(
                                                            row
                                                        )
                                                    }
                                                    disabled={
                                                        !row.needPayAmount
                                                    }
                                                    className={`!rounded-lg !text-white ${
                                                        row.needPayAmount
                                                            ? "cursor-pointer"
                                                            : "!cursor-not-allowed opacity-60"
                                                    } !bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600 !text-sm px-1 md:px-4 !py-0 !min-w-24`}
                                                >
                                                    未振込
                                                </Button>
                                            )}
                                        {!!row.agency && row.isPaid && (
                                            <Button className="flex flex-col !rounded-lg !text-white !bg-blue-700 !text-sm px-1 md:px-4 !py-0 !cursor-default !min-w-24">
                                                <div>振込済み</div>
                                                <div>
                                                    {row.last_payment_time}
                                                </div>
                                            </Button>
                                        )}
                                        <DotsThreeVertical
                                            className="cursor-pointer"
                                            aria-describedby={id}
                                            onClick={handleClick(row)}
                                            size={25}
                                        />
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* Render the pagination controls here */}
            <div className="w-full  min-w-[900px] mt-1 p-3 flex justify-around items-center !text-slate-400">
                <div className=" w-24">{`${page * per_page - (per_page - 1)}-${
                    page * per_page > total ? total : page * per_page
                } of ${total}`}</div>
                <div className="w-full justify-center flex gap-2">
                    <div>
                        <span className=" text-slate-400 hidden md:inline-block">
                            ページあたりの行数：
                        </span>
                        <select
                            className="border-none bg-gray-100"
                            value={per_page}
                            onChange={handleSelectChange}
                        >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => {
                                setPage(page - 1);
                            }}
                            disabled={page === 1}
                            className="mr-2 max-[400px]:ml-2 ml-10"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13 22L1 11.5L13 1"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <span className="max-[500px]:text-sm max-w-[70px] !text-black">
                            {page} /{" "}
                            {Math.ceil(
                                (total || contactUsers?.length) / per_page
                            )}
                        </span>
                        <button
                            onClick={() => setPage(page + 1)}
                            disabled={
                                page ===
                                    Math.ceil(
                                        (total || contactUsers?.length) /
                                            per_page
                                    ) ||
                                !contactUsers ||
                                contactUsers?.length === 0
                            }
                            className="mx-2"
                        >
                            <svg
                                width="14"
                                height="23"
                                viewBox="0 0 14 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L13 11.5L1 22"
                                    stroke="#868FA0"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* Dialog . . . */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                disableScrollLock={true}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="p-2 w-[150px]">
                    <div className="text-center">
                        <Button className="w-full " onClick={handleFamilyTree}>
                            系譜図
                        </Button>
                    </div>
                    <Divider />
                    <div className="text-center">
                        <Button
                            className="w-full"
                            onClick={handleOpenModalSendMail}
                        >
                            メール送信
                        </Button>
                    </div>
                    <Divider />
                    <div className="text-center">
                        <Button
                            className="w-full "
                            disabled={!info || !info.url_name}
                            onClick={handleOpenDetail}
                        >
                            詳細
                        </Button>
                    </div>
                    <Divider />
                    <div className="text-center">
                        <Button
                            className="w-full "
                            onClick={handleClickOpenBanking}
                        >
                            口座番号
                        </Button>
                    </div>
                    <Divider />
                    <div className="text-center">
                        <Button
                            className="w-full "
                            disabled={!info || !info.agency}
                            onClick={handleOpenFeeHistory}
                        >
                            過去の手数料履歴
                        </Button>
                    </div>
                    <Divider />
                    <div className="text-center">
                        <Button
                            className="w-full "
                            onClick={handleDeleteConfirm}
                        >
                            削除
                        </Button>
                    </div>
                    <Divider />
                </div>
            </Popover>
            {/* Dialog Bank Information */}
            <Dialog open={openBacking} onClose={handleCloseBanking}>
                <DialogContent>
                    <div className="px-10 pt-10">
                        <div className="w-full pl-20 flex gap-4 items-center mb-2">
                            <div className="text-2xl font-bold">
                                銀行口座入力フォーム
                            </div>
                            <HelpOutlineIcon />
                        </div>
                        <div
                            className="ml-20 w-full break-normal mb-4"
                            style={{ maxWidth: "400px" }}
                        >
                            登録者名と口座名義が同一であることをご確認の
                            うえ、指定の口座を入力してください
                        </div>
                        <div className="w-full break-normal mb-1">
                            金融機関名を1文字以上入力してください。
                            (候補が表示されます)、銀行番号4ケタでもOK
                        </div>
                        <Autocomplete
                            getOptionLabel={(option) => option.label}
                            options={banks}
                            loading={loadingBank}
                            value={bankData?.bankLabel}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            onInputChange={(event, value) => {
                                fetchDataBank(value);
                            }}
                            onChange={(event, value) => {
                                handleSelectBank(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    className="no-padding"
                                    placeholder="例:三菱UFJ銀行 、0812"
                                    {...params}
                                />
                            )}
                        />
                        <div className="w-full mt-6 mb-1">
                            支店名を1文字以上入力してください。
                            (候補が表示されます)、支店番号3ケタでもOK
                        </div>
                        <Autocomplete
                            getOptionLabel={(option) => option.label}
                            options={branches}
                            value={bankData?.branchLabel}
                            onInputChange={(event, value) => {
                                getBankBranches(bankData?.bank_code, value);
                            }}
                            onChange={(event, value) => {
                                handleSelectBranch(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    className="no-padding"
                                    placeholder="例:本店 、001"
                                    {...params}
                                />
                            )}
                        />
                        <RadioGroup
                            row
                            value={bankData?.card_type}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="mb-6 justify-between"
                            onChange={handleChargeCardType}
                        >
                            <FormControlLabel
                                value="NOR"
                                control={<Radio />}
                                label="普通"
                            />
                            <FormControlLabel
                                value="ACC"
                                control={<Radio />}
                                label="当座"
                            />
                            <FormControlLabel
                                value="SAV"
                                control={<Radio />}
                                label="貯蓄"
                            />
                            <FormControlLabel
                                value="OTH"
                                control={<Radio />}
                                label="その他"
                            />
                        </RadioGroup>
                        <div className="w-full break-normal">口座番号</div>
                        <input
                            className="border border-gray-300 hover:border-black rounded px-[9px] py-[7px] mb-6 block w-full"
                            value={bankData?.bank_number}
                            onChange={(event) => {
                                setBankData({
                                    ...bankData,
                                    bank_number: event.target.value,
                                });
                            }}
                        ></input>
                        <div className="w-full break-normal">
                            口座名義人(カタカナ入力)
                        </div>
                        <input
                            className="border border-gray-300 hover:border-black rounded px-[9px] py-[7px] mb-6 block w-full"
                            value={bankData?.name}
                            onChange={(event) => {
                                setBankData({
                                    ...bankData,
                                    name: event.target.value,
                                });
                            }}
                        ></input>
                    </div>
                    <div className="px-10 pb-10 flex flex-row-reverse">
                        <Button
                            variant="contained"
                            onClick={handleSaveBankInfo}
                            disabled={isDisableSaveBank}
                        >
                            保存する
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
            {/* Dialog Fee History */}
            <DialogFeeHistory
                open={openFeeHistory}
                handleClose={handleCloseFeeHistory}
                data={feeHistory}
            />
            {/* Modal confirm delete user */}
            <Modal
                open={openDeleteConfirm}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style }} className="min-w-[600px]">
                    <h2
                        className="text-zinc-600 text-center font-bold text-[24px] mt-2"
                        id="modal-title"
                    >
                        確認
                    </h2>
                    <p id="modal-description">本当に削除しますか？</p>
                    <div className="flex justify-between max-[530px]:mt-[20px] mt-[70px] font-light px-6">
                        <button
                            className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                            onClick={handleDelete}
                        >
                            はい
                        </button>
                        <button
                            className="text-white font-bold border py-1 px-6 rounded-xl bg-danger-600 hover:bg-danger-700"
                            onClick={handleCloseModalDeleteConfirm}
                        >
                            いいえ
                        </button>
                    </div>
                </Box>
            </Modal>
            {/* Modal confirm paid */}
            <Modal
                open={openPaidConfirm}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ ...style }} className="min-w-[600px]">
                    <h2
                        className="text-zinc-600 text-center font-bold text-[24px] mt-2"
                        id="modal-title"
                    >
                        確認
                    </h2>
                    <p id="modal-description">振込済みにしてよろしいですか？</p>
                    <div className="flex justify-between max-[530px]:mt-[20px] mt-[70px] font-light px-6">
                        <button
                            className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                            onClick={handlePaid}
                        >
                            はい
                        </button>
                        <button
                            className="text-white font-bold border py-1 px-6 rounded-xl bg-danger-600 hover:bg-danger-700"
                            onClick={handleCloseModalPaidConfirm}
                        >
                            いいえ
                        </button>
                    </div>
                    {loading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50">
                            <CircularProgress />
                        </div>
                    )}
                </Box>
            </Modal>
            {/* Modal Send Mail */}
            <Modal
                open={openModalSendMail}
                onClose={handleCloseModalSendMail}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{ ...style }}
                    className="min-w-[900px] !h-screen overflow-auto"
                >
                    <Emailmarketing
                        emails={[info?.email]}
                        onSend={handleCloseModalSendMail}
                    />
                </Box>
            </Modal>
        </div>
    );
}
