import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import moment from "moment";
import { Apis } from "../../../api";
import { toast } from "react-toastify";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import CustomEditor from "../../../components/Editor/CustomEditor";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function AskList() {
    const [asks, setAsks] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [replyingId, setReplyingId] = useState("");
    const [replyingEmail, setReplyingEmail] = useState("");
    const [contentReply, setContentReply] = useState("");
    const [isSubmitReply, setIsSubmitReply] = useState(false);
    const [search, setSearch] = useState("");
    const [emails, setEmails] = useState([]);
    const [template, setTemplate] = useState([]);
    const handleClearDialogData = () => {
        setIsModalOpen(false);
        setContentReply("");
        setReplyingId("");
        setReplyingEmail("");
        setTemplate([]);
    };

    const GetEmails = async () => {
        const res = await Apis.myGet(
            `settingEmail/settingEmail?mailType=other`
        );
        if (res && res.success) {
            setEmails(res.data.items);
        }
    };

    useEffect(() => {
        GetEmails();
        getAsk(search);
    }, [search]);

    useEffect(() => {
        if (
            contentReply &&
            contentReply.length &&
            contentReply !== "<p><br></p>"
        ) {
            setIsSubmitReply(false);
        } else {
            setIsSubmitReply(true);
        }
    }, [contentReply]);

    const getAsk = async (keySearch) => {
        const res = await Apis.myGet(`ask/ask?key_search=${keySearch ?? ""}`);
        if (res && res.success) {
            setAsks(res.data.items);
        }
    };

    const handleReplyClick = async (id, email) => {
        if (!asks.find((item) => item.id === id).is_reply) {
            setReplyingId(id);
            setReplyingEmail(email);
            setIsModalOpen(true);
            return;
        }
        try {
            const res = await Apis.myGet(`ask/reply?ask_id=${id}`);
            if (res && res.success) {
                setReplyingId(id);
                setContentReply(res.data.data.body);
                setIsModalOpen(true);
                return;
            }
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
    };

    const sentReply = async () => {
        const payload = {
            object: "reply",
            id: replyingId,
            email: replyingEmail,
            body: contentReply,
        };

        const res = await Apis.myPost("ask/ask", payload);
        if (res && res.success) {
            window.location.href = "./emails?tab=3";
            return;
        }
        if (res && res.data.error) {
            toast.error(res.data.error);
            handleClearDialogData();
        } else {
            toast.error(`失敗しました! もう一度お試しください!`);
        }
    };
    const handleStorageChange = (event) => {
        setSearch(localStorage.getItem("search"));
    };
    useEffect(() => {
        window.addEventListener("localStorageChange", handleStorageChange);
        // Cleanup event listener khi component unmount
        return () => {
            window.removeEventListener(
                "localStorageChange",
                handleStorageChange
            );
            const event = new Event("localStorageClear");
            window.dispatchEvent(event);
        };
    }, []);

    return (
        <div className="mb-3">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 1500 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">
                                日時
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                ユーザー名
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                メールアドレス
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                タイトル
                            </StyledTableCell>
                            <StyledTableCell>内容</StyledTableCell>
                            <StyledTableCell align="center">
                                返信
                            </StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {asks.map((p) => (
                            <StyledTableRow key={p.id}>
                                <StyledTableCell align="center">
                                    {moment(p.date).format("YYYY/MM/DD")}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.user?.username}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.mail_address}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.title}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {p.content
                                        .split("\n")
                                        .map((item, index) => (
                                            <p key={index}>{item}</p>
                                        ))}
                                </StyledTableCell>
                                <StyledTableCell
                                    onClick={(e) =>
                                        handleReplyClick(p.id, p.mail_address)
                                    }
                                    className="cursor-pointer"
                                >
                                    {p.is_reply ? "返信済み" : ""}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <div className=" shrink-0 px-2 py-5 flex flex-col text-[16px]">
                                        <button
                                            onClick={(e) =>
                                                handleReplyClick(
                                                    p.id,
                                                    p.mail_address
                                                )
                                            }
                                            className=" mt-3 p-2 rounded-lg cursor-pointer bg-lime-400 hover:bg-lime-500 active:bg-lime-600 text-white max-[680px]:text-sm max-[680px]:p-1 whitespace-nowrap"
                                        >
                                            回答・返信メール送信
                                        </button>
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {isModalOpen && (
                <>
                    <div
                        className="fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50  max-[530px]:w-[500px] w-[500px] h-[720px] p-6 bg-white rounded-lg"
                        style={{ border: "solid 1px black" }}
                    >
                        <h2 className="text-zinc-600 font-bold text-[18px] mt-2">
                            返信
                        </h2>

                        <div className="border-[1px] w-full mx-auto my-4" />

                        <label htmlFor="product-code" className="text-md">
                            メールテンプレート
                        </label>
                        <div className="px-2">
                            <Select
                                labelId="demo-select-small-label"
                                className="!bg-white px-2 py-1 w-full no-padding"
                                value={template}
                                input={<OutlinedInput />}
                                MenuProps={{ disableScrollLock: true }}
                                onChange={(e) => {
                                    setContentReply(
                                        emails.find(
                                            (item) => item.id === e.target.value
                                        ).content
                                    );
                                    setTemplate(e.target.value);
                                }}
                            >
                                {emails.map((row) => (
                                    <MenuItem value={row.id}>
                                        {row.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <label className="mt-2" htmlFor="content-test-mail">
                            本文:
                        </label>
                        <div>
                            <CustomEditor
                                // className="h-[400px] w-full p-2 text-lg  rounded-md"
                                disabled={Boolean(
                                    asks.find((item) => item.id === replyingId)
                                        ?.is_reply
                                )}
                                value={contentReply}
                                onChange={(e) => {
                                    setContentReply(e);
                                }}
                            />
                            {/* <ReactQuill
                                className="h-[400px] w-full p-2 text-lg  rounded-md"
                                value={contentReply}
                                readOnly={Boolean(
                                    asks.find((item) => item.id === replyingId)
                                        ?.is_reply
                                )}
                                onChange={(e) => {
                                    setContentReply(e);
                                }}
                                modules={modules}
                            /> */}
                        </div>
                        <div className="flex justify-between mt-3 font-light px-6">
                            {!asks.find((item) => item.id === replyingId)
                                .is_reply && (
                                <button
                                    className="text-zinc-600 font-bold border py-1 px-6 rounded-xl"
                                    disabled={isSubmitReply}
                                    onClick={sentReply}
                                >
                                    送信
                                </button>
                            )}
                            <button
                                className="text-white font-bold border py-1 px-6 rounded-xl bg-orange-400 hover:bg-orange-500"
                                onClick={handleClearDialogData}
                            >
                                閉じる
                            </button>
                        </div>
                    </div>
                    <div className="fixed w-full h-full right-0 top-0 bg-black opacity-10 z-30"></div>
                </>
            )}
        </div>
    );
}
