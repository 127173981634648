import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
} from "tw-elements-react";
import { useEffect, useState } from "react";

export default function PolicyDetailModal({ open, onClose }) {
    const [show, setShow] = useState(open);
    const handleCloseModal = () => {
        setShow(false);
        onClose();
    };
    useEffect(() => {
        setShow(open);
        if (open) {
            const modal = document.querySelector(".temodal-body");
            modal.scrollTop = 1;
        }
    }, [open]);
    // モーダルが表示されるたびにスクロールをリセット

    return (
        //  返品ポリシーモーダル
        <TEModal show={show} setShow={setShow}>
            <TEModalDialog
                className="temodal-body"
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    maxWidth: "700px",
                    maxHeight: "80vh",
                    overflowY: "auto",
                }}
            >
                <TEModalContent style={{ padding: "30px" }}>
                    <TEModalBody>
                        <div className="flex flex-col items-center">
                            <h2 className="text-lg font-bold">
                                返品特約に関して
                            </h2>
                            <div className="text-left w-full">
                                <h3 className="font-semibold">
                                    返品の条件について
                                </h3>
                                <p>不良品・破損品・商品相違の交換について</p>
                                <ul className="list-disc list-inside">
                                    <li>
                                        不良品・破損品については責任を持ってお取り換えいたします。
                                    </li>
                                    <li>
                                        商品に異常があった場合には、商品到着後
                                        7日以内に下記に記載のメールアドレスに、件名には「返品」と明記のうえ、本文に不良状況・破損状況を詳しくお書き頂き、商品返送先のご住所、連絡先をお忘れなく明記の上、お早めにご連絡ください。早急に良品と交換させていただく手配をいたします。
                                    </li>
                                    <li>
                                        商品相違については、商品到着後
                                        7日以内に下記に記載のメールアドレスに件名には「商品相違」と明記のうえ、本文にご注文いただいた商品名及び注文番号をお書きの上、商品返送先のご住所、連絡先をお忘れなく明記のうえ、お早めにご連絡ください。商品の返送方法についてご案内いたします。
                                    </li>
                                    <li>
                                        商品相違・破損・不良品などの場合の送料は当社が負担します。
                                    </li>
                                    <li>
                                        なお、運送中の破損事故の場合は、運送会社が引き上げに伺いますので、そのままの状態で下記に記載のメールアドレスに、件名に「破損」と明記のうえ、本文に状況を詳しくお書きのうえ、商品返送先のご住所、ご連絡先をお忘れなく明記のうえ、早急にご連絡ください。
                                    </li>
                                </ul>
                                <h3 className="font-semibold">
                                    お客様のご都合による返品・交換について
                                </h3>
                                <p>
                                    お客様のご都合で返品される場合については返品には応じておりませんので予めご了承ください。
                                </p>
                                <ul className="list-disc list-inside">
                                    <li>
                                        自己都合で交換をご希望されるときは、商品到着後
                                        7日以内に下記に記載のメールアドレスへ件名に「自己都合で交換」と明記のうえ、本文に理由や交換をご希望される製品名、商品コードを詳しくお書き頂き、お早めにご連絡ください。
                                    </li>
                                    <li>
                                        差額が生じる場合、追加料金はお振込みいただきます。
                                    </li>
                                    <li>
                                        交換をご希望の場合、発送料をご負担いただきます。
                                    </li>
                                    <li>
                                        発送時の送料・追加料金・手数料等は返金致しかねますので、ご了承ください。
                                    </li>
                                </ul>
                                <h3 className="font-semibold">
                                    返品・交換できない商品について
                                </h3>
                                <ul className="list-disc list-inside">
                                    <li>ご使用になられた商品</li>
                                    <li>お取り寄せ商品</li>
                                    <li>他者から購入された商品</li>
                                    <li>
                                        商品本来の使用目的以外のご使用により生じた不具合によるもの
                                    </li>
                                </ul>
                                <h3 className="font-semibold">
                                    返品のご連絡先について
                                </h3>
                                <p>株式会社シンカリンク</p>
                                <p>郵便番号: 007-0837</p>
                                <p>住所: 北海道札幌市東区北三十七条東27丁目</p>
                                <p>Tel: 011-500-9188</p>
                                <p>
                                    お問い合わせの為のメールアドレス:
                                    support@degime.net
                                </p>
                            </div>
                            <button
                                className="mt-5 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                                onClick={handleCloseModal}
                            >
                                閉じる
                            </button>
                        </div>
                    </TEModalBody>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
}
