import Modal from "@mui/material/Modal";
import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate } from "react-router-dom";
import { Apis } from "../api";

import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
} from "tw-elements-react";

import { toast } from "react-toastify";

import AvatarUploader from "../components/Circleimage";
import Inputlinkitem from "../components/Inputlinkitem";
import ImageSlider from "../components/Squareimage";

import DraggableList from "../components/DraggableForm";
import Fourimagelink from "../components/Fourimagelink";
import Listcomponent from "../components/Listcomponent";
import Map from "../components/Map";
import Oneimagelink from "../components/Oneimagelink";
import PdfReader from "../components/Pdfreader";
import Selfprofile from "../components/Selfprofile";
import SettingImageDialog from "../components/SettingImageDialog";
import Spaceitem from "../components/Spaceitem";
import TextEditor from "../components/Textedit";
import Threeimagelink from "../components/Threeimagelink";
import Twoimagelink from "../components/Twoimagelink";
import VideoLinkItem from "../components/VideoLinkItem.jsx";

import debounce from "lodash/debounce";

import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ColorBgPicker from "../components/ColorBgPicker.js";
import PublicView from "../components/PublicView";
import PublishCompleteModal from "../components/PublishCompleteModal";
import { AppContext } from "../App.js";

const EditOnline = ({ profileButton }) => {
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [open, setOpen] = useState(false);
    const [arrangementOpen, setArrangementOpen] = useState(false);
    const [createType, setCreateType] = useState("");
    const [iconLink, setIconLink] = useState([]);
    const [realName, setRealName] = useState("");
    const changeRef = useRef(false);
    const [isClear, setIsClear] = useState(false);
    const [showPublishModal, setShowPublishModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [showPreviewModal, setShowPreviewModal] = useState(false);

    const [backgroundColor, setBackgroundColor] = useState("#f3f4f6");
    const [openColorDialog, setOpenColorDialog] = useState(false);

    const [mail, setMail] = useState("");
    const [userName, setUserName] = useState();

    const [isDirty, setIsDirty] = useState(false);

    const [isShowConfirmDisableDownload, setIsShowConfirmDisableDownload] =
        useState(false);
    const [isConfirmDownload, setIsConfirmDownload] = useState(false);

    const [publishData, setPublishData] = useState({
        bgColor: "",
        bgURL: "",
        wordColor: "",
        cardColor: "",
        cardURL: "",
        url_name: "",
        faceImg: "",
        faceImg_style: [],
        realName: "",
        company_url: "",
        companyName: "",
        position: "",
        phoneNumber: "",
        mobilePhoneNumber: "",
        mailAddress: "",
        address: "",
        idCard: {
            idCard: [],
        },
        socialLink: {},
        onlineCard_Data: {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [],
            slideLink: [],
            spaceAdd: [],
        },
    });

    const [backgroundImage, setBackgroundImage] = useState(
        publishData.bgURL || ""
    );

    const [isEditWidget, setIsEditWidget] = useState(false);
    const [editWidgetData, setEditWidgetData] = useState(null);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("userInfo"));
        if (storedUser) {
            setUserName(storedUser.username);
        }
    }, []);

    const getPublish = useCallback(async () => {
        let config = { username: userName, url_name: userName };
        const storedUser = JSON.parse(localStorage.getItem("userInfo"));

        try {
            const response = await Apis.myGet(
                `social/private/online/draft`,
                config
            );

            if (response && response.data && response.data.length > 0) {
                setMail(response.data[0].mailAddress);
                setRealName(response.data[0].realName || storedUser.full_name);
                setPublishData(response.data[0]);
                setIconLink(response.data[0].socialLink.socialLink);

                const resultArray = Object.entries(
                    response.data[0].onlineCard_Data
                )
                    .flatMap(([key, value]) => {
                        // if (
                        //     key === "selfProfile" ||
                        //     key === "oneImage" ||
                        //     key === "imgLink1"
                        // ) {
                        return value
                            .filter((item) => Object.keys(item).length > 0)
                            .map((item) => ({
                                type: key,
                                data: item,
                            }));
                        // } else if (value.length > 0) {
                        //     return [
                        //         {
                        //             type: key,
                        //             data: value[0],
                        //         },
                        //     ];
                        // }
                        // return [];
                    })
                    .filter((item) => item.type !== undefined)
                    .toSorted((a, b) => a.data.order - b.data.order);
                setProfileData(resultArray);
                if (response.data[0].bgColor) {
                    setBackgroundColor(response.data[0].bgColor);
                }
            } else {
                setRealName(storedUser.full_name);
                setPublishData({
                    ...publishData,
                    realName: storedUser.full_name,
                });
            }
        } catch (error) {
            console.error(error);
        }
    }, [userName]);

    async function reservationImage(
        imageUrl,
        start_date,
        end_date,
        usage_id,
        link
    ) {
        const data = {
            imageUrl: imageUrl,
            start_date: start_date,
            end_date: end_date,
            usage_id: usage_id,
            link: link,
        };

        if (data) {
            try {
                await Apis.myPut("settingImage/", data);
                toast.success("成功！");
                setArrangementOpen(false);
            } catch (error) {
                toast.error(error.message);
            }
        }
    }
    //Update all publish data
    const updateAllForPublish = () => {
        let newOnlineCardData = {
            imgLink1: [],
            imgLink2: [],
            imgLink3: [],
            imgLink4: [],
            videoLink: [],
            textLink: [],
            mapLink: [],
            selfProfile: [[]],
            slideLink: [],
            spaceAdd: [],
        };
        for (const item in profileData) {
            switch (profileData[item].type) {
                case "oneImage":
                    newOnlineCardData.imgLink1.push({
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        url: profileData[item].data?.imageLink,
                        link: profileData[item].data.url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "imgLink1":
                    newOnlineCardData.imgLink1.push({
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        url: profileData[item].data.url,
                        link: profileData[item].data.link,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "twoImage":
                    newOnlineCardData.imgLink2.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "imgLink2":
                    newOnlineCardData.imgLink2.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "imgLink3":
                    newOnlineCardData.imgLink3.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        title3: profileData[item].data.title3,
                        text3: profileData[item].data.text3,
                        url3: profileData[item].data.url3,
                        link3: profileData[item].data.link3,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "threeImage":
                    newOnlineCardData.imgLink3.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        title3: profileData[item].data[2].title,
                        text3: profileData[item].data[2].text,
                        url3: profileData[item].data[2]?.imageLink,
                        link3: profileData[item].data[2].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;

                case "fourImage":
                    newOnlineCardData.imgLink4.push({
                        title1: profileData[item].data[0].title,
                        text1: profileData[item].data[0].text,
                        url1: profileData[item].data[0]?.imageLink,
                        link1: profileData[item].data[0].url,
                        title2: profileData[item].data[1].title,
                        text2: profileData[item].data[1].text,
                        url2: profileData[item].data[1]?.imageLink,
                        link2: profileData[item].data[1].url,
                        title3: profileData[item].data[2].title,
                        text3: profileData[item].data[2].text,
                        url3: profileData[item].data[2]?.imageLink,
                        link3: profileData[item].data[2].url,
                        title4: profileData[item].data[3].title,
                        text4: profileData[item].data[3].text,
                        url4: profileData[item].data[3]?.imageLink,
                        link4: profileData[item].data[3].url,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "imgLink4":
                    newOnlineCardData.imgLink4.push({
                        title1: profileData[item].data.title1,
                        text1: profileData[item].data.text1,
                        url1: profileData[item].data.url1,
                        link1: profileData[item].data.link1,
                        title2: profileData[item].data.title2,
                        text2: profileData[item].data.text2,
                        url2: profileData[item].data.url2,
                        link2: profileData[item].data.link2,
                        title3: profileData[item].data.title3,
                        text3: profileData[item].data.text3,
                        url3: profileData[item].data.url3,
                        link3: profileData[item].data.link3,
                        title4: profileData[item].data.title4,
                        text4: profileData[item].data.text4,
                        url4: profileData[item].data.url4,
                        link4: profileData[item].data.link4,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].startTime ||
                            profileData[item].data.startTime ||
                            "",
                        endTime:
                            profileData[item].endTime ||
                            profileData[item].data.endTime ||
                            "",
                    });
                    break;
                case "videoLink":
                    newOnlineCardData.videoLink.push({
                        url:
                            profileData[item].data.url ||
                            profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "text":
                case "textLink":
                    newOnlineCardData.textLink.push({
                        text:
                            profileData[item].data.text ||
                            profileData[item].data,
                        bgColor:
                            profileData[item].data.bgColor || "transparent",
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "map":
                    newOnlineCardData.mapLink.push({
                        imgLink: "",
                        mapLink: profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "mapLink":
                    newOnlineCardData.mapLink.push({
                        imgLink: "",
                        mapLink: profileData[item].data.mapLink,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "selfProfile":
                    newOnlineCardData.selfProfile.push({
                        title: profileData[item].data.title,
                        content:
                            profileData[item].data.text ||
                            profileData[item].data.content,
                        color: profileData[item].data.color,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;

                case "slideImage":
                    newOnlineCardData.slideLink.push({
                        url: profileData[item].data.file,
                        title: profileData[item].data.title,
                        text: profileData[item].data.text,
                        order: profileData.indexOf(profileData[item]),
                        enableDownload: profileData[item].data?.enableDownload,
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "slideLink":
                    newOnlineCardData.slideLink.push({
                        ...profileData[item].data,
                        order: profileData.indexOf(profileData[item]),
                        enableDownload: profileData[item].data?.enableDownload,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                case "space":
                case "spaceAdd":
                    newOnlineCardData.spaceAdd.push({
                        space:
                            profileData[item].data?.space ||
                            profileData[item].data ||
                            0,
                        order: profileData.indexOf(profileData[item]),
                        size: 1,
                        startTime:
                            profileData[item].data.startTime ||
                            profileData[item].startTime,
                        endTime:
                            profileData[item].data.endTime ||
                            profileData[item].endTime,
                    });
                    break;
                default:
                    break;
            }
        }
        setPublishData((pre) => {
            return { ...pre, onlineCard_Data: newOnlineCardData };
        });

        return { ...publishData, onlineCard_Data: newOnlineCardData };
    };

    const handleDraggableList = (data) => {
        let newPublishData = { ...publishData };
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                newPublishData[key] = data[key];
            }
        }
        setPublishData(newPublishData);
        changeRef.current = true;
    };

    const moveItem = (fromIndex, toIndex) => {
        const updatedItems = [...profileData];
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setProfileData(updatedItems);
        changeRef.current = true;
    };

    const handleiconLink = (newData) => {
        newData = { ...newData };
        const updatedNewDataArray = iconLink ? [...iconLink] : [];
        const index = updatedNewDataArray.findIndex(
            (data) => data.id === newData.id
        );
        if (index !== -1) {
            updatedNewDataArray[index] = newData;
        } else {
            updatedNewDataArray.push(newData);
        }

        setIconLink(updatedNewDataArray);
        // let newSocialLink = [];
        // for (const value in updatedNewDataArray) {
        //     newSocialLink.push({
        //         title: updatedNewDataArray[value].title,
        //         icon_link: updatedNewDataArray[value].iconLink,
        //         social_link: updatedNewDataArray[value].urlLink,
        //     });
        // }
        setPublishData((pre) => ({
            ...pre,
            socialLink: { socialLink: /* newSocialLink */ updatedNewDataArray },
        }));
        changeRef.current = true;
    };

    //Cloudinary upload
    const FileUpload = async (file) => {
        const cloud_name = "dz6r3o4w0";
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", "dfuqz9xv");
        formData.append("cloud_name", cloud_name);

        try {
            const res = await fetch(
                "https://api.cloudinary.com/v1_1/dz6r3o4w0/auto/upload",
                {
                    method: "POST",
                    body: formData,
                }
            );
            const data = await res.json();
            return data.secure_url;
        } catch (err) {
            toast.error(`${err.message}ファイルアップロードに失敗しました`);
        }
    };
    const handleProfileData = (data) => {
        if (isDirty) {
            if (
                ((data.type === "oneImage" || data.type === "imgLink1") &&
                    data.data) ||
                ((data.type === "twoImage" || data.type === "imgLink2") &&
                    (data.data.length === 2 || data.data)) ||
                ((data.type === "threeImage" || data.type === "imgLink3") &&
                    (data.data.length === 3 || data.data)) ||
                ((data.type === "fourImage" || data.type === "imgLink4") &&
                    (data.data.length === 4 || data.data)) ||
                (data.type === "videoLink" && data.data) ||
                ((data.type === "text" || data.type === "textLink") &&
                    data.data) ||
                ((data.type === "map" || data.type === "mapLink") &&
                    data.data) ||
                (["space", "spaceAdd"].includes(data.type) && data.data) ||
                (data.type === "selfProfile" && data.data) ||
                (["slideLink", "slideImage"].includes(data.type) && data.data)
            ) {
                if (
                    ["slideLink", "slideImage"].includes(data.type) &&
                    !isChecked &&
                    !isConfirmDownload
                ) {
                    setIsShowConfirmDisableDownload(true);
                    return;
                }
                if (data.startTime && data.endTime) {
                    if (data.startTime > data.endTime) {
                        toast.error(
                            "開始時間は終了時間より前に設定してください"
                        );
                        return;
                    }
                }
                const temp = [...profileData];
                if (isEditWidget) temp[editWidgetData?.index] = data;
                const newData = isEditWidget ? temp : [...profileData, data];

                setProfileData(newData.toSorted((a, b) => a.order - b.order));
                changeRef.current = true;
                handleClose();
            } else {
                console.log("Just fill in all fields.");
            }
        } else {
            handleClose();
        }
    };

    const deleteProfileData = (id) => {
        const updatedData = profileData.filter(
            (element) => profileData.indexOf(element) !== id
        );
        handleClear();
        setProfileData(updatedData);
        changeRef.current = true;
    };

    //Current Editing Data
    const handleCurrentData = useCallback((data) => {
        setIsDirty(true);
        setCurrentData((prev) => ({ ...prev, ...data }));
    }, []);

    //Current data clear
    const handleClear = () => {
        setIsDirty(false);
        setIsClear(true);
        setIsChecked(false);
        setCurrentData({});
        setArrangementOpen(false);
        setOpen(false);
        setTimeout(() => {
            setIsClear(false);
        }, 100);
    };

    const handleClose = () => {
        setIsEditWidget(false);
        setEditWidgetData(null);
        setOpen(false);
        handleClear();
        setIsChecked(false);
        setIsConfirmDownload(false);
    };

    const handleModal = (e) => {
        setCreateType(e.target.getAttribute("type"));
        setOpen(true);
    };

    const handlePublish = async () => {
        const data = updateAllForPublish();
        let completepublishData = { ...data };
        completepublishData = { ...completepublishData, url_name: userName };
        try {
            const response = await Apis.myPut(
                `social/private/online`,
                completepublishData
            );
            // TODO cần trả lại response để check lỗi (avatar,....)
            if (response && response.success) {
                setPublishData((pre) => ({ ...pre, url_name: userName }));
                const isShowModal = !JSON.parse(
                    localStorage.getItem(
                        `Public_PublishCompleteModalNotShowAgain`
                    )
                );
                isShowModal
                    ? setShowPublishModal(true)
                    : toast.success("公開されました！");
                return;
            }
            throw new Error("編集に失敗しました");
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleAutoSave = async () => {
        const data = updateAllForPublish();
        const completeDraftData = { ...data, url_name: userName };
        try {
            await Apis.myPut(`social/private/online/draft`, completeDraftData);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleColorChange = (color) => {
        setBackgroundColor(color.hex);
        setPublishData((pre) => ({ ...pre, bgColor: color.hex }));
        changeRef.current = true;
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const uploaded_url = await FileUpload(file);
            setBackgroundImage(uploaded_url);
            setPublishData((pre) => ({ ...pre, bgURL: uploaded_url }));
            changeRef.current = true;
        }
    };

    const deleteImageUpload = async () => {
        setBackgroundImage("");
        setPublishData((pre) => ({ ...pre, bgURL: "" }));
        changeRef.current = true;
    };

    const handleAvatarUpload = (data) => {
        setPublishData((pre) => ({ ...pre, faceImg: data ?? "" }));
        changeRef.current = true;
    };

    const handleEditWidget = (item, index) => () => {
        setOpen(true);
        setCreateType(item.type);
        setIsEditWidget(true);
        setIsChecked(!!item?.data?.enableDownload);
        setCurrentData(item);
        setEditWidgetData({ data: item.data, index });
    };
    useEffect(() => {
        if (userName) {
            getPublish();
        }
    }, [getPublish, userName]);

    useEffect(() => {
        updateAllForPublish();
    }, [profileData]);

    useEffect(() => {
        publishData && setBackgroundImage(publishData.bgURL);
        const autoSave = debounce(async () => {
            await handleAutoSave();
            changeRef.current = false;
        }, 500);
        if (changeRef.current) {
            autoSave();
        }
        return () => {
            autoSave.cancel();
        };
    }, [publishData]);

    const handleDeleteIconLink = (index) => {
        const updatedNewDataArray = iconLink
            ? [...iconLink].filter((item, i) => item.id !== index)
            : //   .map((item, i) => ({ ...item, id: i }))
              [];

        setIconLink(updatedNewDataArray);

        setPublishData((pre) => ({
            ...pre,
            socialLink: { socialLink: updatedNewDataArray },
        }));
        changeRef.current = true;
    };

    const handleCloseModalDisableDownload = () => {
        setIsShowConfirmDisableDownload(false);
    };
    const handleConfirmDisableDownload = () => {
        setIsConfirmDownload(true);
        handleCloseModalDisableDownload();
    };
    useEffect(() => {
        if (isConfirmDownload) {
            handleProfileData(currentData);
        }
    }, [isConfirmDownload]);

    const { previousPage } = useContext(AppContext);

    return (
        <div className="editOnline">
            <div className="pc:w-[960px] w-full fixed top-0 flex h-24 items-center justify-around bg-gray-100 z-[19] shadow-sm">
                <Link to={previousPage ? -1 : "/d-dashboard"}>
                    <img
                        src="/image/turn-left.png"
                        alt="edit"
                        className="w-[40px] h-[40px] cursor-pointer"
                    ></img>
                </Link>
                <Link
                    onClick={handlePublish}
                    className="px-4 py-1 rounded-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 focus:bg-orange-700 h-[37px] text-white text-[18px]"
                >
                    公開する
                </Link>
                <Link
                    onClick={() => {
                        setShowPreviewModal(true);
                    }}
                    className="px-4 py-1 rounded-lg bg-purple-500 hover:bg-purple-600 active:bg-purple-700 focus:bg-purple-800 h-[37px] text-white text-[18px]"
                >
                    プレビュー
                </Link>
                <Link
                    to="/d-editsocial"
                    className="px-4 py-1 rounded-lg bg-orange-400 hover:bg-orange-500 active:bg-orange-600 focus:bg-orange-700 h-[37px] text-white text-[18px]"
                >
                    SNS向け
                </Link>
            </div>
            <div
                className="mt-24 pb-4"
                style={{
                    backgroundColor: backgroundColor,
                    backgroundImage:
                        backgroundImage !== "" ? `url(${backgroundImage})` : "",
                    backgroundSize: "contain",
                    backgroundPosition: "top center",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <div className="min-w-[320px] w-[50%] mx-auto">
                    <ColorBgPicker
                        isOpen={openColorDialog}
                        onClose={() => setOpenColorDialog(false)}
                        onChangeColor={handleColorChange}
                        color={backgroundColor}
                        enableBgImage
                        backgroundImage={backgroundImage}
                        onUpload={handleImageUpload}
                        onDeleteImageUpload={deleteImageUpload}
                    >
                        <div className="p-[10px] flex justify-end">
                            <button
                                onClick={() => {
                                    setOpenColorDialog(true);
                                }}
                                className="bg-red-400 inline-block cursor-pointer hover:bg-red-500 text-white mr-3 px-5 py-1 rounded-lg"
                            >
                                背景
                            </button>
                        </div>
                    </ColorBgPicker>
                    <div className="border border-white">
                        <ImageSlider
                            key="imageSlider"
                            onChangeData={(data) => {
                                setPublishData((pre) => ({
                                    ...pre,
                                    idCard: { idCard: data },
                                }));

                                changeRef.current = true;
                            }}
                            name="imageSlider"
                            initialData={publishData?.idCard?.idCard ?? []}
                            backgroundColor={"bg-transparent"}
                        />
                    </div>

                    <div className="flex justify-between mt-[60px] mb-[40px] items-center relative">
                        <AvatarUploader
                            key="avatarUploader"
                            onChangeData={handleAvatarUpload}
                            initialData={publishData.faceImg}
                            initialStyle={publishData.faceImg_style}
                            FileUpload={FileUpload}
                        />
                        <div className="w-[60%]">
                            <input
                                onChange={(e) => {
                                    setRealName(e.target.value);
                                    setPublishData((pre) => ({
                                        ...pre,
                                        realName: e.target.value,
                                    }));
                                    changeRef.current = true;
                                }}
                                type="text"
                                name="online_name"
                                id="online_name"
                                className="pl-[5px] w-full border-2 h-10 rounded-lg focus:border-red-500 focus:ring-1 focus:border-2 focus:rounded-lg placeholder:text-bold"
                                placeholder="氏名"
                                value={realName || ""}
                            ></input>
                            <div className="flex w-full justify-between mt-8">
                                <div className="pc:relative pt-1 pr-2">
                                    {Array.isArray(iconLink) &&
                                        iconLink.find(
                                            (item) => item.id === 0
                                        ) && (
                                            <div
                                                className="w-[20px] h-[20px] absolute top-0 right-0 z-[15] bg-slate-300 p-2 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                                                style={{
                                                    backgroundImage:
                                                        'url("image/close.svg")',
                                                }}
                                                onClick={() =>
                                                    handleDeleteIconLink(0)
                                                }
                                            ></div>
                                        )}
                                    <Inputlinkitem
                                        id={0}
                                        onChangeData={handleiconLink}
                                        initialData={
                                            Array.isArray(iconLink)
                                                ? iconLink.find(
                                                      (item) => item.id === 0
                                                  )
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="pc:relative pt-1 pr-2">
                                    {Array.isArray(iconLink) &&
                                        iconLink.find(
                                            (item) => item.id === 1
                                        ) && (
                                            <div
                                                className="w-[20px] h-[20px] absolute top-0 right-0 z-[15] bg-slate-300 p-2 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                                                style={{
                                                    backgroundImage:
                                                        'url("image/close.svg")',
                                                }}
                                                onClick={() =>
                                                    handleDeleteIconLink(1)
                                                }
                                            ></div>
                                        )}
                                    <Inputlinkitem
                                        id={1}
                                        onChangeData={handleiconLink}
                                        initialData={
                                            Array.isArray(iconLink)
                                                ? iconLink.find(
                                                      (item) => item.id === 1
                                                  )
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="pc:relative pt-1 pr-2">
                                    {Array.isArray(iconLink) &&
                                        iconLink.find(
                                            (item) => item.id === 2
                                        ) && (
                                            <div
                                                className="w-[20px] h-[20px] absolute top-0 right-0 z-[15] bg-slate-300 p-2 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                                                style={{
                                                    backgroundImage:
                                                        'url("image/close.svg")',
                                                }}
                                                onClick={() =>
                                                    handleDeleteIconLink(2)
                                                }
                                            ></div>
                                        )}
                                    <Inputlinkitem
                                        id={2}
                                        onChangeData={handleiconLink}
                                        initialData={
                                            Array.isArray(iconLink)
                                                ? iconLink.find(
                                                      (item) => item.id === 2
                                                  )
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="pc:relative pt-1 pr-2">
                                    {Array.isArray(iconLink) &&
                                        iconLink.find(
                                            (item) => item.id === 3
                                        ) && (
                                            <div
                                                className="w-[20px] h-[20px] absolute top-0 right-0 z-[15] bg-slate-300 p-2 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                                                style={{
                                                    backgroundImage:
                                                        'url("image/close.svg")',
                                                }}
                                                onClick={() =>
                                                    handleDeleteIconLink(3)
                                                }
                                            ></div>
                                        )}
                                    <Inputlinkitem
                                        id={3}
                                        onChangeData={handleiconLink}
                                        initialData={
                                            Array.isArray(iconLink) &&
                                            iconLink.find(
                                                (item) => item.id === 3
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <DraggableList
                        onChangeData={handleDraggableList}
                        publishData={publishData}
                        mail={mail}
                    />
                    {/* <DndProvider backend={HTML5Backend}> */}
                    {Array.isArray(profileData) &&
                        profileData.map((item, index) => (
                            <Listcomponent
                                key={index}
                                id={index}
                                index={index}
                                profileListData={item}
                                moveItem={moveItem}
                                isDelete={deleteProfileData}
                                onClickEdit={handleEditWidget(item, index)}
                                length={profileData.length}
                            />
                        ))}
                    {/* </DndProvider> */}
                    <div className="mt-[40px]">
                        {profileButton.map(
                            (item, key) =>
                                item.type !== "link" && (
                                    <div
                                        key={key}
                                        type={item.type}
                                        className="p-3 px-5 border rounded-md my-3 cursor-pointer border-black text-black/30 hover:text-black bg-white"
                                        onClick={handleModal}
                                    >
                                        {item.placeholder}
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>
            <div
                style={{ backgroundColor: backgroundColor ?? "#F3F4F6 " }}
                className="h-16 md:h-24 flex justify-center items-center  w-full"
            >
                <Link to={`/d-dashboard`}>
                    <div className="">
                        <img
                            className="mx-auto w-[100px] h-auto"
                            alt="logo"
                            src="/image/logo.png"
                        />
                    </div>
                </Link>
            </div>
            <Modal
                open={open}
                onClose={() => {
                    handleProfileData(currentData);
                }}
                keepMounted
                className="w-full flex justify-center items-center"
            >
                <div className="relative w-[40%] tablet:w-[50%] mobile:w-[320px] rounded-lg p-[20px] bg-white items-center max-h-screen overflow-auto">
                    {isLoading && (
                        <div className="absolute inset-0 flex justify-center items-center z-[10] bg-zinc-500/30">
                            <CircularProgress />
                        </div>
                    )}
                    <div className="h-[calc(100%-40px)]">
                        {["fourImage", "imgLink4"].includes(createType) && (
                            <Fourimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["threeImage", "imgLink3"].includes(createType) && (
                            <Threeimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["twoImage", "imgLink2"].includes(createType) && (
                            <Twoimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {["oneImage", "imgLink1"].includes(createType) && (
                            <Oneimagelink
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                                setIsLoading={setIsLoading}
                            />
                        )}
                        {createType === "videoLink" && (
                            <VideoLinkItem
                                FileUpload={FileUpload}
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["textLink", "text"].includes(createType) && (
                            <TextEditor
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                enableChangeBgColor
                                data={editWidgetData?.data}
                            />
                        )}
                        {["map", "mapLink"].includes(createType) && (
                            <Map
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["spaceAdd", "space"].includes(createType) && (
                            <Spaceitem
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {createType === "selfProfile" && (
                            <Selfprofile
                                isClear={isClear}
                                onChangeData={handleCurrentData}
                                data={editWidgetData?.data}
                            />
                        )}
                        {["slideImage", "slideLink"].includes(createType) && (
                            <div className="w-full">
                                <PdfReader
                                    FileUpload={FileUpload}
                                    isClear={isClear}
                                    onChangeData={handleCurrentData}
                                    data={editWidgetData?.data}
                                />
                                <label htmlFor="download">
                                    <input
                                        type="checkbox"
                                        id="download"
                                        name="download"
                                        disabled={!currentData?.data}
                                        checked={isChecked}
                                        onChange={(e) => {
                                            setIsChecked(e.target.checked);
                                            const newData = Object.assign(
                                                currentData.data,
                                                {
                                                    enableDownload:
                                                        e.target.checked,
                                                }
                                            );
                                            handleCurrentData({
                                                data: newData,
                                                type: currentData.type,
                                            });
                                        }}
                                    />
                                    ダウンロードを許可する
                                </label>
                            </div>
                        )}
                        <SettingImageDialog
                            open={arrangementOpen}
                            onclose={() => setArrangementOpen(false)}
                            onPublish={(data) => {
                                setIsDirty(true);
                                editWidgetData?.data
                                    ? setCurrentData((prev) => ({
                                          ...prev,
                                          endTime: data.endTime
                                              ? new Date(data.endTime).getTime()
                                              : "",
                                      }))
                                    : setCurrentData((prev) => ({
                                          ...prev,
                                          endTime: data.endTime
                                              ? new Date(data.endTime).getTime()
                                              : "",
                                      }));
                            }}
                            onReservation={(data) => {
                                setIsDirty(true);
                                editWidgetData?.data
                                    ? setCurrentData((prev) => ({
                                          ...prev,
                                          startTime: data.startTime
                                              ? new Date(
                                                    data.startTime
                                                ).getTime()
                                              : "",
                                          endTime: data.endTime
                                              ? new Date(data.endTime).getTime()
                                              : "",
                                      }))
                                    : setCurrentData((prev) => ({
                                          ...prev,
                                          startTime: data.startTime
                                              ? new Date(
                                                    data.startTime
                                                ).getTime()
                                              : "",
                                          endTime: data.endTime
                                              ? new Date(data.endTime).getTime()
                                              : "",
                                      }));
                            }}
                            isClear={isClear}
                            data={currentData}
                        />
                    </div>
                    <div className="w-full flex justify-end gap-x-4 mt-2">
                        <img
                            alt="trash"
                            src="/image/trash.svg"
                            width="30"
                            className="cursor-pointer hover:opacity-70"
                            onClick={handleClear}
                        />
                        <div
                            className="rounded-md py-2 px-4 bg-sky-400 text-white cursor-pointer hover:bg-sky-500"
                            onClick={() => {
                                handleProfileData(currentData);
                            }}
                        >
                            保存
                        </div>
                        <div
                            className="rounded-md py-2 px-4 bg-red-400 text-white cursor-pointer hover:bg-red-500"
                            onClick={() => {
                                setArrangementOpen(true);
                            }}
                        >
                            予約
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalConfirmDownload
                open={isShowConfirmDisableDownload}
                onOk={handleConfirmDisableDownload}
                onClose={handleCloseModalDisableDownload}
            />
            <PublishCompleteModal
                type={"Public"}
                showPublishModal={showPublishModal}
                setShowPublishModal={setShowPublishModal}
                link={userName}
            />
            <TEModal show={showPreviewModal} setShow={setShowPreviewModal}>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "700px",
                        height: "90%",
                        maxHeight: "1000px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader
                            style={{
                                backgroundColor: "#38bdf8",
                                height: "50px",
                            }}
                        >
                            <h1 className="text-2xl p-3 text-white">
                                プレビュー
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowPreviewModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        <TEModalBody
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                paddingRight: 0,
                                paddingLeft: 0,
                            }}
                        >
                            {showPreviewModal && (
                                <div style={{ width: "100%" }}>
                                    <PublicView
                                        notShowBack
                                        publishData={publishData}
                                        isUpdated={showPreviewModal}
                                        username={userName}
                                    />
                                </div>
                            )}
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    );
};

export default EditOnline;

export const ModalConfirmDownload = ({ open, onClose, onOk }) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    bgcolor: "background.paper",
                    padding: "24px",
                    borderRadius: "8px",
                }}
            >
                <h1 className="p-6 text-xl font-semibold">
                    ダウンロードを許可していませんがよろしいですか？
                </h1>
                <div className="w-full p-4 text-lg flex justify-between">
                    <Button variant="contained" color="inherit" onClick={onOk}>
                        はい
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: "#FB923C",
                        }}
                        onClick={onClose}
                    >
                        いいえ
                    </Button>
                </div>
                <Close
                    sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        color: "black",
                        cursor: "pointer",
                    }}
                    onClick={onClose}
                />
            </Box>
        </Modal>
    );
};
