import React, { useState, useEffect, useRef } from "react";

export default function CustomTypography({
    html,
    text,
    expandable,
    maxLines,
    ...props
}) {
    const textRef = useRef(null);
    const [showMore, setShowMore] = useState(expandable ? false : true);
    const [showBtn, setShowBtn] = useState(false);

    const handleToggle = () => {
        setShowMore((prev) => !prev);
    };

    useEffect(() => {
        expandable && setShowMore(false);
    }, [expandable]);

    const handleShowBtn = () => {
        console.log("abc");
        
        textRef.current &&
            setShowBtn(
                textRef.current.scrollHeight > textRef.current.clientHeight
            );
    };

    useEffect(() => {
        window.addEventListener("resize", handleShowBtn);

        handleShowBtn();

        return () => {
            window.removeEventListener("resize", handleShowBtn);
        };
    }, [text, html]);

    const ReadMoreBtn = () => (
        <span
            className="hover:underline cursor-pointer text-blue-500"
            onClick={handleToggle}
        >
            {showMore ? "閉じる" : "続きを読む"}
        </span>
    );

    return (
        <div {...props}>
            <span
                ref={textRef}
                variant="default"
                dangerouslySetInnerHTML={html ? { __html: html } : undefined}
                style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: !expandable
                        ? "unset"
                        : showMore
                        ? "unset"
                        : maxLines,
                    whiteSpace: "normal",
                }}
            >
                {html ? undefined : text}
            </span>
            {showBtn && <ReadMoreBtn />}
        </div>
    );
}
