import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import { Apis, urlAPI } from "../../api";
import { useAuthContext } from "../../auth/context";
import ChartTreeOder from "../../components/ChartTreeOder";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel";
import OrderListTable from "../../components/orderlistTable";

export default function Gennealogylist() {
    const [value, setValue] = useState(0);
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [per_page, setPer_page] = useState(100);
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [chartData, setChartData] = useState();
    const [zoom, setZoom] = useState(0.7);
    const [userSearch, setUserSearch] = useState();
    const [selectUser, setSelectUser] = useState();
    const { user, isAdmin } = useAuthContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get("userId");
    const tab = searchParams.get("tab");

    const handleChange = (_, newValue) => {
        localStorage.removeItem("search-end-date");
        localStorage.removeItem("search-start-date");
        localStorage.removeItem("search-end-date");
        localStorage.removeItem("search");
        localStorage.removeItem("select-user");
        localStorage.setItem("remove-search", true);
        const event = new Event("localStorageSupport");
        window.dispatchEvent(event);
        setValue(newValue);
    };

    const headers = [
        { label: "No", key: "id" },
        { label: "氏名", key: "fullName" },
        { label: "ユーザーID", key: "username" },
        { label: "紹介した人数", key: "countIntroduced" },
        { label: "新規登録日時", key: "registerDate" },
        { label: "購入日時", key: "date" },
        { label: "購入した製品/個数", key: "order" },
        { label: "合計代金", key: "total" },
    ];

    const handelGetListOrder = async () => {
        if (!isAdmin) return;
        const listOrders = (
            await Apis.myGet(
                `${
                    urlAPI.orders
                }?page=${page}&per_page=${per_page}&keySearch=${search}&purchaseStartTime=${
                    startDate || ""
                }&purchaseEndTime=${endDate || ""}`
            )
        )?.data;
        setOrders(
            listOrders?.data.map((item, index) => ({
                ...item,
                id: listOrders?.totalItem - ((page - 1) * per_page + index),
                fullName: `${item?.order_id?.customer_id?.full_name || ""}`,
                username: item?.order_id?.customer_id?.username,
                countIntroduced: item?.order_id?.customer_id?.count_introduced,
                order: `${item?.product_id?.title || ""}/${item?.number || 0}`,
                total:
                    (item?.product_price_without_fee || 0) *
                    (item?.number || 0),
                registerDate: item?.order_id?.customer_id?.date_joined
                    ? new Date(
                          item?.order_id?.customer_id?.date_joined
                      ).toISOString()
                    : "",
                date: item.order_id?.date
                    ? new Date(item.order_id?.date).toISOString()
                    : "",
            }))
        );
        setTotal(listOrders?.totalItem);
        setIsLoaded(true);
    };

    const handelChangePaging = (per_pageValue, pageValue) => {
        setPage(pageValue);
        setPer_page(per_pageValue);
    };

    const getDataSelect = (data) => {
        try {
            return JSON.parse(data);
        } catch {
            return null;
        }
    };

    const handleSearchSupport = async () => {
        const request = await Apis.myGet(
            `${urlAPI.userSearch}?keySearch=${search}`
        );
        const { data } = request.data;
        if (data) {
            localStorage.setItem("list-user-search", JSON.stringify(data));
            const event = new Event("localStorageSupport");
            window.dispatchEvent(event);
        }
    };

    const handleStorageChange = (event) => {
        setSearch(localStorage.getItem("search"));
        setStartDate(localStorage.getItem("search-start-date"));
        setEndDate(localStorage.getItem("search-end-date"));
        const selectUserData = getDataSelect(
            localStorage.getItem("select-user")
        );
        if (selectUserData) setUserSearch(selectUserData);
        localStorage.removeItem("select-user");
    };

    useEffect(() => {
        window.addEventListener("localStorageChange", handleStorageChange);
        // Cleanup event listener khi component unmount
        return () => {
            window.removeEventListener(
                "localStorageChange",
                handleStorageChange
            );
            const event = new Event("localStorageClear");
            window.dispatchEvent(event);
        };
    }, []);
    const [csv, setCSV] = useState({ data: null, fileName: "" });
    const csvLink = useRef(null);

    const exportToCsv = async () => {
        const response = await Apis.myGet(
            `${urlAPI.ordersNotPaging}?purchaseStartTime=${
                startDate ?? ""
            }&purchaseEndTime=${endDate ?? ""}&keySearch=${search ?? ""}`
        );
        const data = response.data;
        const fileName = "購入リスト" + Date.now() + ".csv";
        setCSV({
            data: data?.data?.map((item, index) => ({
                ...item,
                id: index,
                fullName: `${item?.order_id?.customer_id?.full_name || ""}`,
                username: item?.order_id?.customer_id?.username,
                countIntroduced: item?.order_id?.customer_id?.countIntroduced,
                order: `${item?.product_id?.title || ""}/${item?.number || 0}`,
                total: (item?.product_id?.price || 0) * (item?.number || 0),
                registerDate: item?.order_id?.customer_id?.date_joined
                    ? new Date(
                          item?.order_id?.customer_id?.date_joined
                      ).toISOString()
                    : "",
                date: item.order_id?.date
                    ? new Date(item.order_id?.date).toISOString()
                    : "",
            })),
            fileName: fileName,
        });
        csvLink.current?.link?.click();
    };

    const handleGetDataChart = async () => {
        try {
            const request = await Apis.myGet(
                `${urlAPI.chartContact}?userId=${
                    selectUser?.id || userId || user?.id
                }&purchaseStartTime=${startDate || ""}&purchaseEndTime=${
                    endDate || ""
                }`
            );
            const data = request?.data;
            const line1 = { ...data.userData, amount: data.amount };
            const line2 = data.firstClass;
            const line3 = data.secondClass;
            const line4 = data.thirdClass;
            const genData = {
                name: `${line1.full_name || line1.username}`,
                attributes: {
                    ...line1,
                    amount: data.amount,
                    parent: true,
                },
                children: line2?.map((item) => ({
                    name: item.full_name || item.username,
                    attributes: {
                        ...item,
                        avatar: item.avatar,
                    },
                    children: line3
                        ?.filter((l3) => l3.refer_id === item.id)
                        .map((l3) => ({
                            name: l3.full_name || l3.username,
                            attributes: {
                                ...l3,
                                avatar: l3.avatar,
                            },
                            children: line4
                                ?.filter((l4) => l4.refer_id === l3.id)
                                .map((l4) => ({
                                    name: l4.full_name || l4.username,
                                    attributes: {
                                        ...l4,
                                        avatar: l4.avatar,
                                    },
                                })),
                        })),
                })),
            };
            setChartData(genData);
        } catch {
            setChartData({
                ...chartData,
                attributes: {
                    ...chartData?.attributes,
                    amount: 0,
                    parent: true,
                },
            });
        }
    };

    const handleZoomOut = () => {
        setZoom(zoom - 0.05 <= 0 ? 0 : zoom - 0.05);
    };

    const handleZoomIn = () => {
        setZoom(zoom + 0.05 >= 1 ? 1 : zoom + 0.05);
    };

    const handleOpenInfoProfile = async (item) => {
        setSelectUser(item?.order_id?.customer_id);
        handleChange(null, 1);
    };

    useEffect(() => {
        if (isLoaded && isAdmin) handelGetListOrder();
    }, [page, per_page, search]);

    useEffect(() => {
        if (isAdmin) handelGetListOrder();
    }, [isAdmin]);

    useEffect(() => {
        if (value === 0 && isAdmin) {
            setPage(1);
            handelGetListOrder();
        }
    }, [search, startDate, endDate]);

    useEffect(() => {
        if (value === 0 && isAdmin) handleSearchSupport();
    }, [search]);

    useEffect(() => {
        if (userId || selectUser?.id || user?.id) handleGetDataChart();
    }, [user, userId, selectUser, startDate, endDate]);

    const [isCSV, setIsCSV] = useState(true);
    useEffect(() => {
        if (value) {
            setIsCSV(false);
        } else if (!value && !isAdmin) {
            setIsCSV(false);
        } else {
            setIsCSV(true);
        }
    }, [value]);

    useEffect(() => {
        if (tab && isAdmin) {
            handleChange(null, 1);
        } else if (tab) {
            handleChange(null, 0);
        }
    }, [isAdmin]);

    return (
        <div>
            <button onClick={exportToCsv}>
                {isCSV && (
                    <div className="csvDownload absolute z-25 top-36 right-10 cursor-pointer max-[600px]:w-[50px] bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                        CSV
                        <span className="max-[600px]:hidden min-[600px]:inline-block">
                            ファイルのダウンロード
                        </span>
                    </div>
                )}
            </button>
            {csv.data && isCSV && (
                <CSVLink
                    CSVLink
                    ref={csvLink}
                    data={csv?.data || []}
                    filename={csv.fileName}
                    target="_blank"
                    headers={headers}
                ></CSVLink>
            )}
            <Box sx={{ width: "100%" }} className="px-10">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                    >
                        {isAdmin && (
                            <Tab
                                label="購入者リスト"
                                {...a11yProps(0)}
                                style={{
                                    color: "white",
                                    backgroundColor: "#FF8F61",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                        )}
                        <Tab
                            onClick={handleGetDataChart}
                            label="系譜図"
                            {...a11yProps(isAdmin ? 1 : 0)}
                            style={{
                                color: "white",
                                backgroundColor: "#2AC3FF",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                            }}
                        />
                    </Tabs>
                </Box>
                {isAdmin && (
                    <CustomTabPanel value={value} index={0}>
                        <OrderListTable
                            data={orders}
                            changePaging={handelChangePaging}
                            handleOpenInfoProfile={handleOpenInfoProfile}
                            total={total}
                        />
                    </CustomTabPanel>
                )}
                <CustomTabPanel value={value} index={isAdmin ? 1 : 0}>
                    <div className=" relative w-full h-[720px] bg-white shadow-xl rounded-lg">
                        {chartData && (
                            <ChartTreeOder
                                data={chartData}
                                zoom={zoom}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        )}
                        <div className=" absolute top-7 right-7 flex justify-center items-center gap-3">
                            <button>
                                <ZoomOutIcon
                                    className=" hover:text-blue-400 !w-12 !h-12"
                                    fontSize={"large"}
                                    onClick={handleZoomOut}
                                />
                            </button>
                            <button>
                                <ZoomInIcon
                                    className=" hover:text-blue-400 !w-12 !h-12"
                                    fontSize={"large"}
                                    onClick={handleZoomIn}
                                />
                            </button>
                        </div>
                    </div>
                </CustomTabPanel>
            </Box>
        </div>
    );
}
