import {
    AttachFileOutlined,
    Cancel,
    InsertPhotoOutlined,
    TextSnippet,
} from "@mui/icons-material";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { formatBytes } from "../../../utilities/helper";
import { FileUpload } from "../../../utilities/upload";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import { toast } from "react-toastify";

const WrapForm = styled("form")(({ theme }) => ({
    display: "flex",
    alignItems: "end",
    gap: "10px",
    width: "90%",
    margin: `${theme.spacing(2)} auto`,
}));

const WrapText = styled(TextField)(({ theme }) => ({
    width: "100%",
    marginRight: "10px !important",
}));

const Footer = ({ sendMessage }) => {
    const [text, setText] = useState("");
    const [itemUploads, setItemUploads] = useState([]);
    const [itemUploadsWithLoading, setItemUploadsWithLoading] = useState([]);

    const dropdownRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const handleSend = (e) => {
        e.preventDefault();
        if (text.trim() || itemUploads.length > 0) {
            sendMessage([
                {
                    type: "text",
                    content: text,
                },
                ...itemUploads,
            ]);
            setText("");
            setItemUploads([]);
        } else {
            toast.warning("入力フィールドに入力してください");
        }
    };

    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const handlePaste = async (event) => {
        const clipboardItems = event.clipboardData.items;
        for (let i = 0; i < clipboardItems.length; i++) {
            const item = clipboardItems[i];
            if (item.type.indexOf("image") !== -1) {
                const file = item.getAsFile();

                if (file.size > 20971520) {
                    return toast.error("写真もしくは動画を選択してください");
                }

                try {
                    const loadImages = [
                        ...itemUploads,
                        {
                            name: "",
                            size: 0,
                            type: "",
                            content: "",
                        },
                    ];
                    setItemUploadsWithLoading(loadImages);

                    const uploadedContent = await FileUpload(file);
                    if (!uploadedContent) {
                        throw new Error("failed to upload");
                    }

                    setItemUploads((prevState) => [
                        ...prevState,
                        {
                            name: file.name,
                            size: file.size,
                            type: file.type,
                            content: uploadedContent,
                        },
                    ]);
                } catch (err) {
                    console.error("Error upload image:", err);
                } finally {
                    setItemUploadsWithLoading([]);
                }
            }
        }
    };

    const handleEmojiSelect = (emoji) => {
        setText(text + emoji.native);
    };
    const handleImageUpload = async (event) => {
        event.preventDefault();
        const uploadedImages = Array.from(event.target.files);
        if (
            uploadedImages.some(
                (image) =>
                    !image.type.includes("video/") &&
                    !image.type.includes("image/")
            )
        ) {
            toast.error("写真もしくは動画を選択してください");
            return;
        }
        if (uploadedImages.some((image) => image.size > 20971520)) {
            toast.error("ファイルサイズは20MBを超えないようにしてください");
            return;
        }
        if (uploadedImages.length > 0) {
            try {
                const fakeImages = [...itemUploads];
                uploadedImages.forEach((image, index) => {
                    fakeImages.push({
                        name: "",
                        size: 0,
                        type: "",
                        content: "",
                    });
                });
                setItemUploadsWithLoading(fakeImages);

                const uploadPromise = uploadedImages.map((image) => {
                    return FileUpload(image);
                });
                const urls = await Promise.all(uploadPromise);
                if (urls.length !== uploadedImages.length) {
                    throw new Error("Some images failed to upload");
                }
                const newUploads = urls.map((content, index) => {
                    const { name, size, type } = uploadedImages[index];
                    return { name, size, type, content };
                });
                setItemUploads((prevState) => [...prevState, ...newUploads]);
                event.target.value = null;
            } catch (error) {
                console.error("Error creating object URL:", error);
            } finally {
                setItemUploadsWithLoading([]);
            }
        } else {
            console.error("No file selected");
        }
    };

    const handleFileUpload = async (event) => {
        event.preventDefault();
        const uploadedImages = Array.from(event.target.files);
        if (uploadedImages.some((image) => image.size > 20971520)) {
            toast.error("ファイルサイズは20MBを超えないようにしてください");
            return;
        }
        if (uploadedImages.length > 0) {
            try {
                const fakeImages = [...itemUploads];
                uploadedImages.forEach((image, index) => {
                    fakeImages.push({
                        name: "",
                        size: 0,
                        type: "",
                        content: "",
                    });
                });
                setItemUploadsWithLoading(fakeImages);

                const uploadPromise = uploadedImages.map((image) => {
                    return FileUpload(image);
                });
                const urls = await Promise.all(uploadPromise);
                if (urls.length !== uploadedImages.length) {
                    throw new Error("Some images failed to upload");
                }
                const newUploads = urls.map((content, index) => {
                    const { name, size, type } = uploadedImages[index];
                    return { name, size, type, content };
                });
                setItemUploads((prevState) => [...prevState, ...newUploads]);
                event.target.value = null;
            } catch (error) {
                console.error("Error creating object URL:", error);
            } finally {
                setItemUploadsWithLoading([]);
            }
        } else {
            console.error("No file selected");
        }
    };

    const handleRemoveItem = (index) => {
        setItemUploads((prevState) =>
            prevState.filter((item, idx) => idx !== index)
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setShowEmojiPicker(false); // Hide the dropdown
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <WrapForm noValidate autoComplete="off">
                <div className="w-full h-full border border-[#BBBCBD] rounded-[14px] ">
                    {itemUploadsWithLoading.length > 0 ? (
                        <div className="h-full w-full flex gap-2 px-[14px] py-2">
                            {itemUploadsWithLoading.map((item, index) => (
                                <ItemUpload
                                    key={index}
                                    data={item}
                                    index={index}
                                    onRemove={handleRemoveItem}
                                />
                            ))}
                        </div>
                    ) : (
                        itemUploads.length > 0 && (
                            <div className="h-full w-full flex gap-2 px-[14px] py-2">
                                {itemUploads.map((item, index) => (
                                    <ItemUpload
                                        key={index}
                                        data={item}
                                        index={index}
                                        onRemove={handleRemoveItem}
                                    />
                                ))}
                            </div>
                        )
                    )}
                    <WrapText
                        inputRef={inputRef}
                        onPaste={handlePaste}
                        id="standard-text"
                        value={text}
                        multiline
                        maxRows={4}
                        spellCheck="false"
                        sx={{
                            "& .MuiInputBase-root": {
                                borderRadius: "10px",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "none",
                                },
                            },
                        }}
                        onKeyDown={(event) => {
                            const keyCode = event.which || event.keyCode;

                            if (keyCode === 13 && !event.shiftKey) {
                                handleSend(event);
                                return;
                            }
                        }}
                        size="small"
                        onChange={(e) =>
                            text.length <= 1000 &&
                            e.target.value.length <= 1000 &&
                            setText(e.target.value)
                        }
                        InputProps={{
                            endAdornment: (
                                <div className="flex items-end gap-2 h-full">
                                    <input
                                        type="file"
                                        accept="image/*,video/*"
                                        hidden
                                        onChange={handleImageUpload}
                                        id="image-upload"
                                    />
                                    <label
                                        htmlFor="image-upload"
                                        className="cursor-pointer"
                                    >
                                        <InsertPhotoOutlined
                                            sx={{
                                                color: "#BBBCBD",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </label>
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileUpload}
                                        id="files-upload"
                                    />
                                    <label
                                        htmlFor="files-upload"
                                        className="cursor-pointer"
                                    >
                                        <AttachFileOutlined
                                            sx={{
                                                color: "#BBBCBD",
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </label>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setShowEmojiPicker(
                                                !showEmojiPicker
                                            );
                                        }}
                                    >
                                        😀
                                    </button>
                                    {showEmojiPicker && (
                                        <div
                                            ref={dropdownRef}
                                            style={{
                                                position: "absolute",
                                                bottom: "50px",
                                                right: "0",
                                            }}
                                        >
                                            <Picker
                                                data={data}
                                                onEmojiSelect={
                                                    handleEmojiSelect
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ),
                        }}
                    />
                </div>
                <div className="h-full flex items-end">
                    <button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleSend}
                        className="bg-[#837DFF] h-10 w-10 rounded-xl flex items-center justify-center"
                    >
                        <img
                            src="/image/sendIcon.svg"
                            alt="send message"
                            className="h-[18px] w-4"
                        />
                    </button>
                </div>
            </WrapForm>
        </>
    );
};

export default Footer;

export const ItemUpload = ({ data, onRemove, index }) => {
    if (!data) return null;
    return (
        <div className="relative  w-fit ">
            {data.type && (
                <div className="absolute -top-2 -right-1 z-10">
                    <Cancel
                        sx={{
                            color: "black",
                            width: "20px",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            cursor: "pointer",
                        }}
                        onClick={() => onRemove(index)}
                    />
                </div>
            )}
            {!data.type && (
                <div className="flex items-center justify-center h-10 w-10  p-1 rounded-lg relative shadow-md">
                    <CircularProgress size={20} />
                </div>
            )}
            {data.type.includes("image/") && (
                <div className="flex items-center w-fit p-1 rounded-lg">
                    <img
                        src={data.content}
                        alt={data.name}
                        className="w-auto h-10 rounded-lg object-contain"
                    />
                </div>
            )}
            {(data.type.includes("application/") ||
                data.type.includes("text/")) && (
                <div className="flex items-center gap-2 bg-zinc-500 w-fit p-2 rounded-lg">
                    <TextSnippet
                        sx={{
                            color: "#BBBCBD",
                            width: "20px",
                            height: "20px",
                        }}
                    />
                    <div className="flex flex-col text-white">
                        <p className="">{data.name}</p>
                        <p className="text-sm leading-4">
                            {formatBytes(data.size)}
                        </p>
                    </div>
                </div>
            )}
            {data.type.includes("video/") && (
                <div className="preview-player">
                    <ReactPlayer
                        url={data.content}
                        playing={false}
                        height={"40px"}
                        width={"auto"}
                    />
                </div>
            )}
            {data.type.includes("audio/") && (
                <div className="preview-player-audio">
                    <ReactPlayer
                        url={data.content}
                        playing={false}
                        height={40}
                        width={100}
                        controls
                    />
                </div>
            )}
        </div>
    );
};
