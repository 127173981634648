import { Button } from "@mui/material";
import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { Apis } from "../../../api";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const CheckoutForm = ({ clientSecret, totalPrice, totalPriceWithTax }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentMethod, setPaymentMethod] = useState("card");
    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!elements || !stripe) {
            return;
        }
        setLoading(true);
        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            // Show error to your customer
            setErrorMessage(submitError.message);
            return;
        }
        const { paymentIntent, error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret,
            confirmParams: {
                return_url: `${window.location.origin}/d-shop/contract/3`,
            },
            redirect: "if_required",
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            try {
                const response = await Apis.myPost("order/order", {
                    paymentIntentId: paymentIntent.id,
                });
                if (response && response.success) {
                    navigate("/d-shop/contract/3", {
                        state: { totalPrice, totalPriceWithTax },
                    });
                    return;
                }
                throw new Error("問題が発生しました");
            } catch (error) {
                setErrorMessage("問題が発生しました");
                try {
                    await Apis.myPost("payment/refund", {
                        paymentIntentId: paymentIntent.id,
                    });
                } catch (error) {
                    setErrorMessage("問題が発生しました");
                }
            }
        }
        setLoading(false);
    };

    return (
        <form id="payment-form" className="grid gap-2" onSubmit={handleSubmit}>
            <PaymentElement
                id="payment-element"
                onChange={(event) => {
                    setPaymentMethod(event.value.type);
                    setErrorMessage(null);
                }}
            />
            <LoadingButton
                variant="contained"
                color="primary"
                className="w-full"
                type="submit"
                loading={loading}
                disabled={!stripe || !elements}
            >
                決済する￥{totalPriceWithTax}
            </LoadingButton>
            {/* Show error message to your customers */}
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
        </form>
    );
};
export default CheckoutForm;
