import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { ChromePicker } from "react-color";

export default function ColorBgPicker({
    onChangeColor,
    children,
    isOpen,
    onClose,
    color,
    enableBgImage,
    backgroundImage,
    onUpload,
    onDeleteImageUpload,
    title,
}) {
    return (
        <>
            {children}
            {/* Color Dialog */}
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>{title ?? "背景を選択"}</DialogTitle>
                <DialogContent>
                    <ChromePicker
                        color={color}
                        onChange={onChangeColor}
                        disableAlpha={true}
                    />
                </DialogContent>
                <DialogActions>
                    {enableBgImage && (
                        <div className="absolute left-4 bottom-3">
                            <label htmlFor="background-upload">
                                <div className="w-[40px] h-[40px] rounded-full bg-gray-200 overflow-hidden relative cursor-pointer">
                                    {backgroundImage ? (
                                        <img
                                            src={backgroundImage}
                                            alt="Avatar"
                                            className="w-full h-full object-cover"
                                        />
                                    ) : (
                                        <svg
                                            className="w-full h-full text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="0.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <line
                                                x1="12"
                                                y1="10"
                                                x2="12"
                                                y2="14"
                                            />
                                            <line
                                                x1="10"
                                                y1="12"
                                                x2="14"
                                                y2="12"
                                            />
                                        </svg>
                                    )}
                                </div>
                            </label>
                            <input
                                type="file"
                                id="background-upload"
                                accept="image/*"
                                className="hidden"
                                onChange={onUpload}
                            />
                            {backgroundImage !== "" && (
                                <div
                                    className="absolute top-2 -right-6 cursor-pointer"
                                    onClick={onDeleteImageUpload}
                                >
                                    <svg
                                        width="23"
                                        height="23"
                                        viewBox="0 0 23 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M4.91498 15.4949L9.03117 11.3182L4.7256 7.07504L7.01035 4.75666L11.3159 8.99978L15.4321 4.823L17.8609 7.21656L13.7447 11.3933L18.0503 15.6365L15.7655 17.9548L11.46 13.7117L7.34376 17.8885L4.91498 15.4949Z"
                                            fill="black"
                                            fillOpacity="0.3"
                                        />
                                    </svg>
                                </div>
                            )}
                        </div>
                    )}
                    <Button onClick={onClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
