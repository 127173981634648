import React, { useState, useEffect, useRef } from "react";

import Footer from "./Chat/Footer";
import Header from "./Chat/Header";

import { toast } from "react-toastify";
import SentChat from "./Chat/SentChat";
import ReceivedChat from "./Chat/ReceivedChat";
import { Apis } from "../../api";
import { websocketurl } from "../../api";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../auth/context";

export default function Chatpane() {
    const [params] = useSearchParams();
    const room = params.get("room");
    const storedUser = JSON.parse(localStorage.getItem("userInfo"));
    const username = storedUser.username;

    const token = localStorage.getItem("access_token");

    const [memberAvatars, setMemberAvatars] = useState([]);

    const messagesEndRef = useRef(null);

    const hasMounted = useRef(false);

    const [members, setMembers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [toastState, setToastState] = useState(false);

    const [socket, setSocket] = useState(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const { reAuth } = useAuthContext();

    useEffect(() => {
        async function dispatch() {
            let newMembers = await Apis.myGet(
                `chat/private/chatroom?room_name=${room}`
            );

            if (newMembers && newMembers.data[0]) {
                const { data } = newMembers;
                let newAvatars = [];
                data[0].member.map((item, index) =>
                    newAvatars.push({
                        user: item.username,
                        avatar: item.avatar,
                        company_name: item.company_name,
                        position: item.position,
                        full_name: item.full_name,
                    })
                );
                setMemberAvatars(newAvatars);
                if (!toastState) {
                    toast.success(`${data[0].chat_group}が開かれます。`);
                    setToastState(true);
                }
                setMembers(data[0]);
            } else return;
        }
        const getAllMessages = async () => {
            try {
                const res = await Apis.myGet(
                    `chat/private/chatroom?room_name=${room}&message=true`
                );
                if (res && res.success) {
                    const oldMessages = [];
                    res.data.map((item) => {
                        oldMessages.push({
                            sender: item.sender,
                            text: item.message,
                            name: item.name,
                            size: item.size,
                            type: item.type,
                        });
                    });

                    setMessages(oldMessages);
                }
            } catch (error) {}
        };
        if (!hasMounted.current) {
            dispatch();
            getAllMessages();
            reAuth();
            hasMounted.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const client = new WebSocket(
            `${
                process.env.REACT_APP_WEBSOCKET_PROTOCOL || "wss"
            }://${websocketurl}/ws/chat/${room}/${token}/${username}`
        );
        setSocket(client);
        client.onopen = () => {
            console.log("Websocket successfully connected");
        };
        client.onmessage = (message) => {
            let dataFromServer = JSON.parse(message.data);
            if (dataFromServer.message) {
                setMessages((prevState) => [
                    ...prevState,
                    {
                        sender: dataFromServer.user,
                        text: dataFromServer.message,
                        name: dataFromServer.name,
                        size: dataFromServer.size,
                        type: dataFromServer.message_type,
                    },
                ]);

                client &&
                    dataFromServer.msg_id &&
                    client.send(
                        JSON.stringify({
                            type: "message",
                            msg_type: "MESSAGE_READ",
                            msg_id: dataFromServer.msg_id,
                            user: username,
                        })
                    );
                scrollToBottom();
            }
        };
        client.onclose = () => {
            console.log("Websocked is already closed");
        };
        return () => {
            client.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendMessage = (data) => {
        data.forEach((message) => {
            if (!message.content) return;
            socket.send(
                JSON.stringify({
                    type: "message",
                    msg_type: "TEXT_MESSAGE",
                    message: message.content,
                    type: message.type,
                    name: message?.name ?? "",
                    size: message?.size ?? 0,
                    user: username,
                })
            );
        });
    };

    useEffect(() => {
        const scrollableDiv = document.getElementById("scrollableDiv");
        const scrollToBottom = () => {
            scrollableDiv.scrollTo({
                top: scrollableDiv.scrollHeight,
                behavior: "smooth",
            });
        };
        scrollToBottom();
    }, [messages]);

    const senderMessage = (senderItem) => {
        const sender = memberAvatars.find((obj) => obj.user === senderItem);
        // Object.keys(sender)[0];
        return sender
            ? sender
            : { full_name: "", company_name: "", position: "" };
    };

    return (
        <div className="relative h-[calc(100vh-70px)] md:h-screen">
            <div className="Chatheader">
                <Header members={members} />
            </div>
            <div
                id="scrollableDiv"
                className="Chatbody h-[calc(84vh-70px)] md:h-[84vh] mx-auto w-full p-10 space-y-4 overflow-auto"
            >
                {messages &&
                    messages.map((item, index) =>
                        item.sender === username ? (
                            <SentChat
                                avatar={
                                    memberAvatars.find(
                                        (obj) =>
                                            obj.user === item.sender &&
                                            obj.avatar
                                    )?.avatar
                                }
                                key={index}
                                user={item.sender}
                                text={item.text}
                                name={item.name}
                                size={item.size}
                                type={item.type}
                                username={item.sender}
                            />
                        ) : (
                            <ReceivedChat
                                avatar={senderMessage(item.sender)?.avatar}
                                key={index}
                                user={item.sender}
                                text={item.text}
                                name={item.name}
                                size={item.size}
                                type={item.type}
                                username={item.sender}
                                sender={senderMessage(item.sender)}
                            />
                        )
                    )}

                <div ref={messagesEndRef} />
            </div>
            <div className="mobile:fixed pc:absolute mobile:bottom-[70px] pc:bottom-0 w-full bg-[#F3F4F6]">
                <Footer sendMessage={sendMessage} />
            </div>
        </div>
    );
}
