import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { a11yProps, CustomTabPanel } from "../../components/TabPanel";
import EstimatedEarnings from "../../components/EstimatedEarnings";
import { DotsThreeVertical } from "phosphor-react";
import { Apis, urlAPI } from "../../api";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

export default function AgencyManagement() {
    const [value, setValue] = useState(0);

    const [listContacts, setListContacts] = useState([]);
    const [agencyPurchase, setAgencyPurChase] = useState({
        thisWeek: 0,
        lastWeek: 0,
        thisMonth: 0,
        lastMonth: 0,
        last30Days: 0,
        last7Days: 0,
        today: 0,
        yesterday: 0,
        total: 0,
        totalPayment: 0,
        startAgencyDate: "",
        updateDate: "",
    });

    const [bankInfo, setBankInfo] = useState(null);

    const getPaymentHistory = async () => {
        const listContactUsers = (
            await Apis.myGet(
                `${urlAPI.agencyPaymentHistory}?page=1&per_page=100`
            )
        )?.data;

        return listContactUsers;
    };

    const getAgencyInfo = async (startDate, endDate, compareByTime) => {
        const agencyInfo = (
            await Apis.myGet(
                `${
                    urlAPI.agencyManagement
                }?purchaseStartTime=${startDate}&purchaseEndTime=${endDate}${
                    compareByTime ? `&compareByTime=${compareByTime}` : ""
                }`
            )
        )?.data?.data;

        let isPaid = false;
        if (
            agencyInfo.count_user_buy_card &&
            agencyInfo.total_payment !== null &&
            agencyInfo.last_payment_time !== null &&
            agencyInfo.total_payment >= agencyInfo.count_user_buy_card.amount
        )
            isPaid = true;

        const needPayAmount =
            agencyInfo.count_user_buy_card?.amount ??
            0 - (agencyInfo.total_payment ?? 0);

        return {
            ...agencyInfo,
            userId: agencyInfo.id,
            amount: agencyInfo.count_user_buy_card?.amount ?? 0,
            totalUser: agencyInfo.count_user_buy_card?.totalUser ?? 0,
            revenue: agencyInfo.count_user_buy_card?.amountProfit ?? 0,
            registerDate: dayjs(new Date(agencyInfo.date_joined)).format(
                "YYYY-MM-DD"
            ),
            last_payment_time: agencyInfo.last_payment_time
                ? dayjs(agencyInfo.last_payment_time).format("YY/MM/DD")
                : null,
            estimatedAmount:
                agencyInfo.count_user_buy_card?.amountProfit ?? 0
                    ? Math.round(
                          agencyInfo.count_user_buy_card?.amountProfit ?? 0
                      )
                    : 0,
            agencyDate: dayjs(agencyInfo.agency.date).format("YY/MM/DD"),
            isPaid: isPaid,
            needPayAmount: needPayAmount,
        };
    };

    const fetchData = async () => {
        const currentDate = dayjs().format("YYYY-MM-DD");
        const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        const last7Date = dayjs().subtract(7, "day").format("YYYY-MM-DD");
        const last30Date = dayjs().subtract(30, "day").format("YYYY-MM-DD");
        const startOfCurrentWeek = dayjs()
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
        const startOfLastWeek = dayjs()
            .startOf("isoWeek")
            .subtract(1, "week")
            .format("YYYY-MM-DD");
        const endOfLastWeek = dayjs()
            .endOf("isoWeek")
            .subtract(1, "week")
            .format("YYYY-MM-DD");
        const startOfThisMonth = dayjs().startOf("month").format("YYYY-MM-DD");
        const startOfLastMonth = dayjs()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
        const endOfLastMonth = dayjs()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");

        const contacts = await getPaymentHistory();
        const agencyInfo = await getAgencyInfo(
            contacts.data[0]?.date
                ? dayjs(contacts.data[0]?.date).utc().format("YYYY-MM-DD HH:mm:ss")
                : "",
            "",
            true
        );
        const totalToday = await getAgencyInfo(currentDate, currentDate);
        const totalYesterday = await getAgencyInfo(yesterday, yesterday);
        const totalLast7Day = await getAgencyInfo(last7Date, currentDate);
        const totalLast30Day = await getAgencyInfo(last30Date, currentDate);
        const totalCurrentWeek = await getAgencyInfo(
            startOfCurrentWeek,
            currentDate
        );
        const totalLastWeek = await getAgencyInfo(
            startOfLastWeek,
            endOfLastWeek
        );
        const totalThisMonth = await getAgencyInfo(
            startOfThisMonth,
            currentDate
        );
        const totalLastMonth = await getAgencyInfo(
            startOfLastMonth,
            endOfLastMonth
        );
        const created = dayjs(agencyInfo.agency.date);
        let nextRenewalDate = created;
        while (nextRenewalDate <= new Date()) {
            nextRenewalDate = nextRenewalDate.add(1, "year");
        }

        nextRenewalDate = dayjs(nextRenewalDate).format("YY/MM/DD");

        setBankInfo(agencyInfo);

        setAgencyPurChase({
            ...agencyPurchase,
            thisWeek: totalCurrentWeek.revenue,
            lastWeek: totalLastWeek.revenue,
            last30Days: totalLast30Day.revenue,
            last7Days: totalLast7Day.revenue,
            today: totalToday.revenue,
            yesterday: totalYesterday.revenue,
            thisMonth: totalThisMonth.revenue,
            lastMonth: totalLastMonth.revenue,
            total: agencyInfo.count_user_buy_card?.amountProfit ?? 0,
            totalPayment: agencyInfo.total_payment,
            startAgencyDate: agencyInfo.agencyDate,
            nextRenewalDate: nextRenewalDate,
        });

        if (contacts.data) {
            const lisContractsData = !(
                agencyInfo.count_user_buy_card?.amountProfit ?? 0
            )
                ? [
                      ...contacts.data.map((item, index) => {
                          return {
                              ...item,
                              id: index + 1,
                              lastDate: dayjs(
                                  contacts.data[index]?.date ?? item.agency.date
                              ).format("YYYY/MM/DD"),
                              saleIncrease:
                                  item.total_sale -
                                  (contacts.data[index + 1]?.total_sale ?? 0),
                              isPaid: true,
                              date: dayjs(item.date).format("YYYY/MM/DD"),
                          };
                      }),
                  ]
                : [
                      {
                          id: 1,
                          lastDate: dayjs(
                              contacts.data[0]?.date ?? new Date()
                          ).format("YYYY/MM/DD"),
                          date: "現在",
                          total_buyer: agencyInfo.totalUser,
                          count_contact_increase_this_month:
                              agencyInfo.count_contact_increase_this_month,
                          saleIncrease: Math.max(
                              agencyInfo.amount -
                                  (contacts.data[0]?.total_sale ?? 0),
                              0
                          ),
                          total_sale: agencyInfo.amount,
                          isPaid: false,
                          total_user: agencyInfo.count_introduced,
                          amount: Math.max(
                              agencyInfo.revenue -
                                  (contacts.data[0]?.total_revenue ?? 0),
                              0
                          ),
                          total_revenue: agencyInfo.revenue,
                      },
                      ...contacts.data.map((item, index) => {
                          return {
                              ...item,
                              id: index + 2,
                              lastDate: dayjs(
                                  contacts.data[index + 1]?.date ??
                                      item.agency.date
                              ).format("YYYY/MM/DD"),
                              saleIncrease:
                                  item.total_sale -
                                  (contacts.data[index + 1]?.total_sale ?? 0),
                              isPaid: true,
                              date: dayjs(item.date).format("YYYY/MM/DD"),
                          };
                      }),
                  ];
            setListContacts(lisContractsData);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="mx-10">
            <div className="flex flex-col md:flex-row justify-around p-2 mt-5 mb-3 gap-4">
                <div className="border bg-white shadow-lg rounded-md w-full py-4 sm:py-8 flex-grow px-8 sm:px-16">
                    <h1 className="text-2xl text-blue-500">推定収益額</h1>
                    <div className="flex items-center justify-between px-10 text-green-500 mt-4">
                        <div>
                            <div>本日（現時点）</div>
                            <h4 className="text-2xl text-center my-2 text-blue-500">
                                ¥
                                <span className="text-blue-600">
                                    {agencyPurchase.today}
                                </span>
                            </h4>
                        </div>
                        <div>
                            <div>昨日</div>
                            <h4 className="text-2xl text-center my-2 text-blue-500">
                                ¥
                                <span className="text-blue-600">
                                    {agencyPurchase.yesterday}
                                </span>
                            </h4>
                        </div>
                        <div>
                            <div>過去7日間</div>
                            <h4 className="text-2xl text-center my-2 text-blue-500">
                                ¥
                                <span className="text-blue-600">
                                    {agencyPurchase.last7Days}
                                </span>
                            </h4>
                        </div>
                        <div>
                            <div>過去30日間</div>
                            <h4 className="text-2xl text-center my-2 text-blue-500">
                                ¥
                                <span className="text-blue-600">
                                    {agencyPurchase.last30Days}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div className="flex items-center justify-between text-blue-500 mt-4">
                        <div className="">
                            <div>先週の同じ曜日との比較</div>
                            <h4
                                className={`text-2xl ${
                                    agencyPurchase.thisWeek <
                                    agencyPurchase.lastWeek
                                        ? "text-red-500"
                                        : ""
                                }`}
                            >
                                {agencyPurchase.thisWeek <
                                agencyPurchase.lastWeek
                                    ? "-"
                                    : "+"}{" "}
                                ¥
                                <span className="">
                                    {Math.abs(
                                        agencyPurchase.thisWeek -
                                            agencyPurchase.lastWeek
                                    )}
                                </span>
                            </h4>
                        </div>
                        <div className="">
                            <div>先月の同じ日との比較</div>
                            <h4
                                className={`text-2xl ${
                                    agencyPurchase.thisMonth <=
                                    agencyPurchase.lastMonth
                                        ? "text-red-500"
                                        : ""
                                }`}
                            >
                                {agencyPurchase.thisMonth <=
                                agencyPurchase.lastMonth
                                    ? "-"
                                    : "+"}{" "}
                                ¥
                                <span className="">
                                    {Math.abs(
                                        agencyPurchase.thisMonth -
                                            agencyPurchase.lastMonth
                                    )}
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="border bg-white shadow-lg rounded-md py-4 sm:py-8 px-8 sm:px-16 w-[40%] ">
                    <div className="flex justify-between items-center">
                        <h1 className="text-2xl text-blue-500">残高</h1>
                        {/* <DotsThreeVertical
                            className="cursor-pointer opacity-50"
                            onClick={() => {}}
                            size={40}
                        /> */}
                    </div>
                    <h4 className="text-2xl text-center my-9 text-blue-500">
                        ¥<span className="">{agencyPurchase.total}</span>
                    </h4>
                    <p className="text-end mr-10 text-green-500">
                        前回の支払い：
                        <span className="text-blue-500">
                            ¥{agencyPurchase.totalPayment}
                        </span>
                    </p>
                </div>
            </div>
            <Box sx={{ width: "100%" }} className="p-2 my-4 relative">
                <div className="absolute left-0 right-0 top-5 flex items-center justify-center text-center gap-8 text-lg font-bold">
                    <div className="border-[3px] border-[#FF0000] px-4 py-2 rounded-xl leading-none min-w-36">
                        <div className="opacity-45">代理店登録日</div>
                        <div className="opacity-45">
                            {agencyPurchase.startAgencyDate}
                        </div>
                    </div>
                    <div className="border-[3px] border-[#9747FF] px-4 py-2 rounded-xl leading-none min-w-36">
                        <div className="opacity-45">更新日</div>
                        <div className="opacity-45">
                            {agencyPurchase.nextRenewalDate}
                        </div>
                    </div>
                </div>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="推定収益"
                            {...a11yProps(0)}
                            style={{
                                height: "80px",
                                width: "220px",
                                color: "white",
                                backgroundColor: "#FF8F61",
                                borderTopLeftRadius: "10px",
                                borderTopRightRadius: "10px",
                                padding: "20px 32px",
                                fontSize: "20px",
                                cursor: "none",
                            }}
                        />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <EstimatedEarnings
                        data={listContacts}
                        info={bankInfo}
                        fetchData={fetchData}
                    />
                </CustomTabPanel>
            </Box>
        </div>
    );
}
