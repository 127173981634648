function SpecifiedCommercialTransactions() {
    return (
        <div className="absolute max-w-screen top-0 left-0 min-h-screen bg-white p-4">
            <h1>会社情報・特定商取引法に基づく表示</h1>
            <br />
            <br />
            <p>店舗名　Degime用NFC内蔵製品販売</p>
            <br />
            <p>
                店舗URL　
                <a href="https://degime.net" className="underline">
                    https://degime.net
                </a>
            </p>
            <br />
            <p>
                販売事業者名　株式会社シンカリンク
                <br />
                <p className="pl-6">カブシキガイシャシンカリンク</p>
            </p>
            <br />
            <p>
                販売責任者　田中 順明
                <br />
                <p className="pl-6">タナカヨシアキ</p>
            </p>
            <br />
            <p>
                所在地　〒007-0837
                <br />
                札幌市東区北三⼗七条東27丁目
                <br />
                011-500-9188
            </p>
            <br />
            <p>
                メールアドレス　
                <a href="mailto:support@degime.net">support@degime.net</a>
            </p>
            <br />
            <h2>■商品の販売について</h2>
            <ul className="list-disc pl-5">
                <li>
                    販売数量・条件等
                    <br />
                    数量制限などはございません。
                </li>
                <li>
                    お支払方法
                    <br />
                    支払方法についてはクレジットカードのみに対応しています。
                </li>
                <li>
                    商品代⾦以外の必要料⾦
                    <br />
                    支払方法や配送方法によって、所定の⼿数料や送料が必要になる場合があります。
                </li>
                <li>
                    申し込みの有効期限
                    <br />
                    支払期限までにご⼊⾦の確認ができない場合は、キャンセルとさせていただきます。
                </li>
                <li>
                    商品引渡し時期
                    <br />
                    ご⼊⾦確認⽇から通常5営業⽇以内に発送いたします。
                    <br />
                    「オーダー品(特注品を含む)」については2ヶ月以内の発送となります。
                    <br />
                    商品お届け先が⽇本国外の場合はご利⽤いただけません。
                    <br />
                    ※納期の厳守には細心の注意を払っておりますが、生産・在庫状況、交通状況、システムトラブル等、予期せぬ事故・都合により納期の変動が起こり得る場合もあります。それによりお客様または第3者に損害が発生したとしても当店は⼀切責任を負わないものとします。また、お客様の都合による納期の遅れにつきましても、これと同じものとします。予めご了承くださいませ。
                </li>
                <li>
                    不良品について
                    <br />
                    不良品・破損品については責任を持ってお取り換え致します。
                    <br />
                    条件などの詳細は下部に記載のお問い合わせまでメールにて返品についてのご連絡をお願いします。１～２営業日中には返信させて頂きます。
                </li>
                <li>
                    契約の締結について
                    <br />
                    ・お客様のご注⽂は、本サイトにおいてエラーが報告されることなく、ご注⽂⼿続きが⾏われた場合においてのみ処理されます。
                    <br />
                    ・当ショップは、理由の如何を問わず、⾃⼰の裁量にて、お客様に対して責任を追うことなく、ご注⽂を拒否することができるものとします。例えば、以下のような状況において、当ショップはご注⽂を承諾しないことがあります。
                    <ul className="list-decimal">
                        <li>
                            ご注⽂に含まれる全部又は⼀部商品の在庫が確保できない場合
                        </li>
                        <li>
                            過去のご注⽂に関連して当ショップとお客様の間に紛争がある場合
                        </li>
                        <li>
                            お客様が、過去にご注⽂された代⾦支払いを⾏わなかった場合
                        </li>
                        <li>
                            お客様が、違法又は不正な⾏為に関与している、又は関与している疑いがある場合
                        </li>
                        <li>
                            お客様が提供したデータが不正確又は虚偽であると疑われる理由がある場合
                        </li>
                        <li>
                            注⽂商品や送料、その他の表示価格に誤りがあった場合
                        </li>
                        <li>お客様の支払方法が拒否された場合</li>
                        <li>
                            当ショップがお客様のご注⽂を処理することができない場合
                        </li>
                    </ul>
                    当ショップは、ご注⽂を承諾しない場合、ご注⽂の受付⽇から5営業⽇以内にお客様に通知します。当ショップがご注⽂を承諾しない場合、お客様と当ショップの間で契約は締結されません。
                    <br />
                    当ショップは、ご注⽂の拒否について理由を提供する義務を負いません。
                </li>
                <li>
                    定期購入について
                    <br />
                    ・定期購⼊契約は所定の期間満了またはお客様がキャンセルするまで、所定の頻度またはお客様が指定した頻度に従い⾃動的に注⽂を成⽴させるものとします。
                    <br />
                    ・お客様が定期購⼊契約をキャンセルをするには、当サイトからキャンセル希望⽇の「10⽇前」までに告知することにより以降の定期購⼊契約をキャンセルすることができるものとする。
                    <br />
                    ・定期購入契約のキャンセル告知は当サイトの「設定」の「定期購⼊の管理」から⾏えます。
                    <br />
                    ・当サイトは、独⾃の裁量により、お客様の定期購⼊契約を通知なくいつでも解除することができるものとします。この場合、解除した当日までの支払いとなります。
                </li>
            </ul>
        </div>
    );
}
export default SpecifiedCommercialTransactions;
