import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Apis } from "../../../api";
import { Checkbox, NativeSelect } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: 14,
}));

const StyledBlueHeaderCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}));

const StyledGrayHeaderCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const PERIOD_TYPE_YEAR = 0;
const PERIOD_TYPE_MONTH = 1;
const PERIOD_TYPE_DAY = 2;
const FORMATS = ["YYYY", "YYYY/MM", "YYYY/MM/DD"];

function formatDate(dateTime, periodType) {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    switch (periodType) {
        case PERIOD_TYPE_DAY:
            return `${year}-${month.toString().padStart(2, "0")}-${day
                .toString()
                .padStart(2, "0")}`;
        case PERIOD_TYPE_MONTH:
            return `${year}-${month.toString().padStart(2, "0")}`;
        case PERIOD_TYPE_YEAR:
            return `${year}`;
    }
}

export default function SalesList() {
    const [sales, setSales] = useState([]);
    const [total, setTotal] = useState({});
    const [periodType, setPeriodType] = useState(2);
    const [startDate, setStartDate] = useState(new Date().setDate(1));
    const [endDate, setEndDate] = useState(Date.now());

    useEffect(() => {
        loadSales().then();
    }, []);

    const loadSales = async () => {
        GetSales();
    };

    useEffect(() => {
        const now = new Date();
        let start = new Date();
        let end = new Date();
        switch (periodType) {
            case PERIOD_TYPE_YEAR:
                end = now.setMonth(0, 1);
                start = new Date().setFullYear(now.getFullYear() - 1, 0, 1);
                break;
            case PERIOD_TYPE_MONTH:
                end = now.setDate(1);
                let lastMonth = now.getMonth() - 1;
                lastMonth = lastMonth < 0 ? 12 + lastMonth : lastMonth;
                start = new Date().setMonth(lastMonth, 1);
                break;
            case PERIOD_TYPE_DAY:
                end = now.getTime();
                start = new Date().setDate(1);
                break;
        }
        setEndDate(end);
        setStartDate(start);
    }, [periodType]);

    const handleStartChange = (newDateTime) => {
        setStartDate(newDateTime);
    };

    const handleEndChange = (newDateTime) => {
        setEndDate(newDateTime);
    };

    useEffect(() => {
        if (startDate) {
            GetSales();
        }
    }, [startDate]);

    useEffect(() => {
        if (endDate) {
            GetSales();
        }
    }, [endDate]);

    const GetSales = async () => {
        const res = await Apis.myGet(
            `sales/sales?salesStartDate=${formatDate(
                startDate,
                periodType
            )}&salesEndDate=${formatDate(
                endDate,
                periodType
            )}&periodType=${periodType}`
        );
        if (res && res.success) {
            setSales(res.data.items);
            setTotal(res.data.total_amount);
        }
    };

    const csvData = [
        ["計", "売上件数", "注文金額", "送料", "決済手数料", "消費税", "合計"],
    ];

    csvData.push([
        "",
        total.order_number,
        total.total_price_without_fee,
        total.shipping_fee,
        total.closing_fee,
        total.total_price - total.total_price_without_fee,
        total.total_price + total.shipping_fee + total.closing_fee,
    ]);

    csvData.push([
        "日付",
        "売上件数",
        "注文金額",
        "送料",
        "決済手数料",
        "消費税",
        "合計",
    ]);
    for (const p of sales) {
        csvData.push([
            moment(p.date).format(FORMATS[periodType]),
            p.order_number,
            p.total_price,
            p.shipping_fee,
            p.closing_fee,
            p.total_price - p.total_price_without_fee,
            p.total_price + p.shipping_fee + p.closing_fee,
        ]);
    }

    return (
        <div className="px-10 mb-3">
            <div className="flex flex-row-reverse mb-2">
                <CSVLink data={csvData} filename={`売上一覧.csv`}>
                    <div className="csvDownload cursor-pointer w-[280px] max-[600px]:w-[50px] bg-indigo-700 opacity-80 hover:opacity-90 active:opacity-50 text-white py-2 max-md:px-1 md:px-5 rounded-full text-center">
                        CSV
                        <span className="max-[600px]:hidden min-[600px]:inline-block">
                            ファイルのダウンロード
                        </span>
                    </div>
                </CSVLink>
            </div>

            <div className="flex justify-end items-center mb-2 gap-4">
                <NativeSelect
                    value={periodType}
                    onChange={(e) => setPeriodType(Number(e.target.value))}
                    inputProps={{
                        name: "periodType",
                        id: "period-type",
                    }}
                >
                    <option value={PERIOD_TYPE_YEAR}>年次</option>
                    <option value={PERIOD_TYPE_MONTH}>月次</option>
                    <option value={PERIOD_TYPE_DAY}>日次</option>
                </NativeSelect>
                <div className="flex justify-end items-center gap-2">
                    <label className="">期間指定: </label>
                    <Datetime
                        dateFormat={FORMATS[periodType]}
                        timeFormat={false}
                        inputProps={{
                            className: "py-1 px-2",
                            placeholder: "YYYY/MM/DD",
                        }}
                        value={startDate}
                        onChange={handleStartChange}
                    />
                    <span> ~ </span>
                    <Datetime
                        dateFormat={FORMATS[periodType]}
                        timeFormat={false}
                        inputProps={{
                            className: "py-1 px-2",
                            placeholder: "YYYY/MM/DD",
                        }}
                        value={endDate}
                        onChange={handleEndChange}
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledBlueHeaderCell
                                backgroundColor="blue"
                                align="center"
                            >
                                計
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                売上件数
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                注文金額
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                送料
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                決済手数料
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                消費税
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                合計
                            </StyledBlueHeaderCell>
                            <StyledBlueHeaderCell align="center">
                                -
                            </StyledBlueHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell
                                component="th"
                                scope="row"
                                align="center"
                            ></StyledTableCell>
                            <StyledTableCell align="center">
                                {total.order_number}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {total.price_without_fee}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {total.shipping_fee}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {total.closing_fee}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {total.total_price -
                                    total.total_price_without_fee}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                {total.total_price +
                                    total.shipping_fee +
                                    total.closing_fee}
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <StyledGrayHeaderCell align="center">
                                日付
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                売上件数
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                注文金額
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                送料
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                決済手数料
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                消費税
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center">
                                合計
                            </StyledGrayHeaderCell>
                            <StyledGrayHeaderCell align="center"></StyledGrayHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sales.map((p, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                >
                                    {moment(p.date).format(FORMATS[periodType])}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.order_number}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.total_price_without_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.shipping_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.closing_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.total_price - p.total_price_without_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {p.total_price +
                                        p.shipping_fee +
                                        p.closing_fee}
                                </StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
