import {
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
} from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import fileDownload from "js-file-download";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import ReactPlayer from "react-player";
import "react-quill/dist/quill.snow.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageSlider from "../components/Squareimage";
import { generateEmbedUrl, getEmbedUrl } from "../utilities/helper";
import CustomTypography from "./CustomTypography";

export default function Listcomponent({
    index,
    moveItem,
    profileListData,
    id,
    isDelete,
    onClickEdit,
    linkColor,
    length,
}) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(0);
    const [pdfContainerHeight, setPdfContainerHeight] = useState(0);
    const ref = useRef(null);
    const pdfPageRef = useRef(null);
    // const [isDraggable, setIsDraggable] = useState(false);

    const docContainerRef = useRef(null);
    const deleteList = (e) => {
        e.stopPropagation();
        isDelete(id);
    };
    const handlePlaying = () => {
        setIsPlaying((pre) => !pre);
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    // const [{ isDragging }, drag] = useDrag({
    //     type: "WIDGET",
    //     item: { id, index },
    //     collect: (monitor) => ({
    //         isDragging: monitor.isDragging(),
    //     }),
    //     canDrag: () => isDraggable,
    // });
    // const opacity = isDragging ? 0 : 1;
    // const [{ handlerId }, drop] = useDrop({
    //     accept: "WIDGET",
    //     collect(monitor) {
    //         return {
    //             handlerId: monitor.getHandlerId(),
    //         };
    //     },
    //     hover(draggedItem, monitor) {
    //         if (!ref.current) {
    //             return;
    //         }
    //         setIsDraggable(false);

    //         const dragIndex = draggedItem.index;
    //         const hoverIndex = index;

    //         // Don't replace items with themselves
    //         if (dragIndex === hoverIndex) {
    //             return;
    //         }

    //         // Determine rectangle on screen
    //         const hoverBoundingRect = ref.current?.getBoundingClientRect();

    //         // Get vertical middle
    //         const hoverMiddleY =
    //             (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    //         // Determine mouse position
    //         const clientOffset = monitor.getClientOffset();

    //         // Get pixels to the top
    //         const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    //         // Dragging downwards
    //         if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //             return;
    //         }

    //         // Dragging upwards
    //         if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //             return;
    //         }

    //         // Time to actually perform the action
    //         moveItem(draggedItem.index, index);
    //         draggedItem.index = index;
    //     },
    // });
    // drag(drop(ref));

    const handleDownload = (url, filename) => {
        axios
            .get(url, {
                responseType: "blob",
            })
            .then((res) => {
                fileDownload(res.data, `${filename}.pdf`);
            });
    };

    const updatePdfWidth = () => {
        if (docContainerRef.current) {
            const { width } = docContainerRef.current.getBoundingClientRect();
            setPdfWidth(width);
        }

        if (pdfPageRef.current) {
            const { width, height } =
                pdfPageRef.current.getBoundingClientRect();
            setPdfContainerHeight(
                (width > height ? height * (numPages >= 2 ? 2 : 1) : height) +
                    85
            );
        }
    };

    // const handleIconMouseDown = (e) => {
    //     setIsDraggable(true);
    // };

    // const handleIconMouseUp = (e) => {
    //     setIsDraggable(false);
    // };

    useEffect(() => {
        window.addEventListener("resize", updatePdfWidth);
        updatePdfWidth(); // Initial pdf width
        return () => window.removeEventListener("resize", updatePdfWidth); // Cleanup listener
    });

    const LoadingComponent = (
        <div className="absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex items-center justify-center">
            <CircularProgress />
        </div>
    );
    const deltaText = profileListData.data.text ?? profileListData.data;
    const quillConverter = new QuillDeltaToHtmlConverter(deltaText.ops, {});

    const ScheduledText = () => {
        const formatDate = "YYYY/MM/DD HH:mm";
        if ("startTime" in profileListData || "endTime" in profileListData) {
            if (profileListData.startTime && !profileListData.endTime) {
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF]  text-white">
                        {`公開予定:${dayjs(profileListData.startTime).format(
                            formatDate
                        )}`}
                    </div>
                );
            }
            if (!profileListData.startTime && profileListData.endTime) {
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF] text-white">
                        {`公開予定:${dayjs(profileListData.endTime).format(
                            formatDate
                        )}まで`}
                    </div>
                );
            }
            if (profileListData.startTime && profileListData.endTime)
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF] text-white">
                        {`公開予定:${dayjs(profileListData.startTime).format(
                            formatDate
                        )}～${dayjs(profileListData.endTime).format(
                            formatDate
                        )}`}
                    </div>
                );
            return null;
        }
        if (profileListData.data.startTime || profileListData.data.endTime) {
            if (
                profileListData.data.startTime &&
                !profileListData.data.endTime
            ) {
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF]  text-white">
                        {`公開予定:${dayjs(
                            profileListData.data.startTime
                        ).format(formatDate)}`}
                    </div>
                );
            }
            if (
                !profileListData.data.startTime &&
                profileListData.data.endTime
            ) {
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF] text-white">
                        {`公開予定:${dayjs(profileListData.data.endTime).format(
                            formatDate
                        )}まで`}
                    </div>
                );
            }
            if (profileListData.data.startTime && profileListData.data.endTime)
                return (
                    <div className="w-fit text-[13px] md:text-sm text-center rounded-xl px-3 py-[3px] break-words bg-[#2AC3FF] text-white">
                        {`公開予定:${dayjs(
                            profileListData.data.startTime
                        ).format(formatDate)}～${dayjs(
                            profileListData.data.endTime
                        ).format(formatDate)}`}
                    </div>
                );
        }
        return null;
    };

    return (
        <div
            className={
                "py-3 relative cursor-pointer border border-gray-600 rounded-lg mb-3"
            }
            style={{
                // opacity,
                height:
                    ["slideImage", "slideLink"].includes(
                        profileListData.type
                    ) && pdfContainerHeight
                        ? `${pdfContainerHeight}px`
                        : "auto",
            }}
            ref={ref}
            // data-handler-id={handlerId}
        >
            <div className="flex gap-2 justify-center items-center pb-2">
                {id !== 0 && (
                    <Button
                        sx={{
                            borderRadius: "50%",
                            border: "1px solid",
                            padding: "0",
                            width: "24px !important",
                            minWidth: "24px !important",
                            height: "24px !important",
                            minHeight: "24px !important",
                            color: "#7F7F7F",
                        }}
                        onClick={() => moveItem(id, id - 1)}
                    >
                        <ArrowDropUpOutlined />
                    </Button>
                )}
                {id !== length - 1 && (
                    <Button
                        sx={{
                            borderRadius: "50%",
                            border: "1px solid",
                            padding: "0",
                            width: "24px !important",
                            minWidth: "24px !important",
                            height: "24px !important",
                            minHeight: "24px !important",
                            color: "#7F7F7F",
                        }}
                        onClick={() => moveItem(id, id + 1)}
                    >
                        <ArrowDropDownOutlined />
                    </Button>
                )}
            </div>
            <div className="h-[calc(100%-50px)]">
                <div
                    className="flex top-2 items-center justify-between z-10"
                    // onMouseDown={handleIconMouseDown}
                    // onMouseUp={handleIconMouseUp}
                >
                    <div className="w-12"></div>

                    {/* <div className="flex flex-col items-center">
                        <svg
                            width="18"
                            height="7"
                            viewBox="0 0 18 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9 0L17.6603 6.75H0.339746L9 0Z"
                                fill="#A9A9A9"
                            />
                        </svg>
                        <svg
                            width="18"
                            height="7"
                            viewBox="0 0 18 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9 7L0.339745 0.25L17.6603 0.25L9 7Z"
                                fill="#A9A9A9"
                            />
                        </svg>
                    </div> */}

                    <div className="flex items-center">
                        <div
                            className="w-[24px] h-[24px] z-[15] bg-slate-300 p-2 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                            style={{
                                backgroundImage: 'url("image/close.svg")',
                            }}
                            onClick={deleteList}
                        ></div>
                        <div
                            className="w-[24px] h-auto z-[15] p-1 bg-slate-300 rounded-full bg-no-repeat bg-center hover:bg-slate-400 cursor-pointer"
                            onClick={() => onClickEdit(true)}
                        >
                            <img src="image/edit_icon.svg" alt="edit" />
                        </div>
                    </div>
                </div>

                {(profileListData.type === "oneImage" ||
                    profileListData.type === "imgLink1") && (
                    <div>
                        <div className="relative w-full  overflow-hidden mx-auto flex items-center justify-center">
                            {/* <img src={profileListData.data?.imageLink || profileListData.data.url} alt="" className="w-full h-full object-contain bg-auto bg-white" /> */}
                            <ImageSlider
                                key={`oneLink-${index}`}
                                name={`oneLink-${index}`}
                                initialData={
                                    profileListData.data?.imageLink ||
                                    profileListData.data.url
                                }
                            />
                        </div>
                        <div className="w-full text-sm text-center px-3 break-words">
                            {profileListData.data.title ?? ""}
                        </div>
                        <CustomTypography
                            className="w-full text-sm text-center px-3 break-words text-gray-500"
                            text={profileListData.data.text ?? ""}
                            maxLines={3}
                            expandable
                        />
                    </div>
                )}
                {profileListData.type === "twoImage" && (
                    <div className="flex justify-between">
                        <div className="w-full">
                            <div className="w-full aspect-square overflow-hidden mx-auto items-center justify-center">
                                <img
                                    src={
                                        profileListData.data[0]?.imageLink ?? ""
                                    }
                                    alt=""
                                    className="w-full h-full object-contain bg-auto"
                                />
                            </div>
                            <div className="w-full text-sm text-center px-3 break-words">
                                {profileListData.data[0].title ?? ""}
                            </div>
                            <CustomTypography
                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                text={profileListData.data[0].text ?? ""}
                                maxLines={3}
                                expandable
                            />
                        </div>
                        <div className="w-full">
                            <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                <img
                                    src={
                                        profileListData.data[1]?.imageLink ?? ""
                                    }
                                    alt=""
                                    className="w-full h-full object-contain bg-auto"
                                />
                            </div>
                            <div className="w-full text-sm text-center px-3 break-words">
                                {profileListData.data[1].title ?? ""}
                            </div>
                            <CustomTypography
                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                text={profileListData.data[1].text ?? ""}
                                maxLines={3}
                                expandable
                            />
                        </div>
                    </div>
                )}
                {profileListData.type === "imgLink2" && (
                    <div className="grid grid-cols-2 justify-between">
                        <div className="w-full">
                            <div className="w-full aspect-square overflow-hidden mx-auto">
                                <img
                                    src={profileListData.data.url1 ?? ""}
                                    alt=""
                                    className="w-full h-full object-contain bg-auto"
                                />
                            </div>
                            <div className="w-full text-sm text-center px-3 break-words">
                                {profileListData.data.title1 ?? ""}
                            </div>
                            <CustomTypography
                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                text={profileListData.data.text1 ?? ""}
                                maxLines={3}
                                expandable
                            />
                        </div>
                        <div className="w-full">
                            <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                <img
                                    src={profileListData.data.url2 ?? ""}
                                    alt=""
                                    className="w-full h-full object-contain bg-auto"
                                />
                            </div>
                            <div className="w-full text-sm text-center px-3 break-words">
                                {profileListData.data.title2 ?? ""}
                            </div>
                            <CustomTypography
                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                text={profileListData.data.text2 ?? ""}
                                maxLines={3}
                                expandable
                            />
                        </div>
                    </div>
                )}
                {profileListData.type === "threeImage" && (
                    <>
                        {/* パソコン画面の大きさの時 */}
                        <div className="hidden lg:grid grid-cols-3 justify-between">
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[0]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[0].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[0].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[1]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[1].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[1].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[2]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[2].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[2].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                        </div>

                        {/* それより小さい画面の時 */}
                        <div className="lg:hidden">
                            <Swiper spaceBetween={16} slidesPerView={1}>
                                {profileListData.data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="w-full">
                                            <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                                <img
                                                    src={item?.imageLink ?? ""}
                                                    alt=""
                                                    className="w-full h-full object-contain bg-auto"
                                                />
                                            </div>
                                            <div className="w-full text-sm text-center px-3 break-words">
                                                {item.title ?? ""}
                                            </div>
                                            <CustomTypography
                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                text={item.text ?? ""}
                                                maxLines={3}
                                                expandable
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                )}
                {profileListData.type === "imgLink3" && (
                    <>
                        {/* パソコン画面の大きさの時 */}
                        <div className="hidden lg:grid lg:grid-cols-3 justify-between">
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url1}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title1}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.text1 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url2}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title2}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.text2 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-square overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url3}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title3}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.text3 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                        </div>

                        {/* それより小さい画面の時 */}
                        <div className="lg:hidden">
                            <Swiper spaceBetween={16} slidesPerView={1}>
                                {[
                                    {
                                        url: profileListData.data.url1,
                                        title: profileListData.data.title1,
                                        text: profileListData.data.text1,
                                        link: profileListData.data.link1,
                                    },
                                    {
                                        url: profileListData.data.url2,
                                        title: profileListData.data.title2,
                                        text: profileListData.data.text2,
                                        link: profileListData.data.link2,
                                    },
                                    {
                                        url: profileListData.data.url3,
                                        title: profileListData.data.title3,
                                        text: profileListData.data.text3,
                                        link: profileListData.data.link3,
                                    },
                                ].map((image, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="w-full">
                                            <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                                <img
                                                    src={image.url ?? ""}
                                                    alt=""
                                                    className="w-full h-full object-contain bg-auto"
                                                />
                                            </div>
                                            <div className="w-full text-sm text-center px-3 break-words">
                                                {image.title ?? ""}
                                            </div>
                                            <CustomTypography
                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                text={image.text ?? ""}
                                                maxLines={3}
                                                expandable
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                )}
                {profileListData.type === "fourImage" && (
                    <>
                        {/* パソコン画面の大きさの時 */}
                        <div className="hidden lg:grid lg:grid-cols-4 justify-between">
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[0]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[0].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[0].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[1]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[1].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[1].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[2]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[2].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[2].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={
                                            profileListData.data[3]
                                                ?.imageLink ?? ""
                                        }
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data[3].title ?? ""}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data[3].text ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                        </div>

                        {/* それより小さい画面の時 */}
                        <div className="lg:hidden">
                            <Swiper spaceBetween={16} slidesPerView={1}>
                                {profileListData.data.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="w-full">
                                            <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                                <img
                                                    src={item?.imageLink}
                                                    alt=""
                                                    className="w-full h-full object-contain bg-auto"
                                                />
                                            </div>
                                            <div className="w-full text-sm text-center px-3 break-words">
                                                {item.title}
                                            </div>
                                            <CustomTypography
                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                text={item.text ?? ""}
                                                maxLines={3}
                                                expandable
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                )}
                {profileListData.type === "imgLink4" && (
                    <>
                        {/* パソコン画面の大きさの時 */}
                        <div className="hidden lg:grid lg:grid-cols-4 justify-between">
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url1}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title1}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.title1 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url2}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title2}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.title2 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url3}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title3}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.title3 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                            <div className="w-full">
                                <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                    <img
                                        src={profileListData.data.url4}
                                        alt=""
                                        className="w-full h-full object-contain bg-auto"
                                    />
                                </div>
                                <div className="w-full text-sm text-center px-3 break-words">
                                    {profileListData.data.title4}
                                </div>
                                <CustomTypography
                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                    text={profileListData.data.title4 ?? ""}
                                    maxLines={3}
                                    expandable
                                />
                            </div>
                        </div>

                        {/* それより小さい画面の時 */}
                        <div className="lg:hidden">
                            <Swiper spaceBetween={16} slidesPerView={1}>
                                {[
                                    {
                                        url: profileListData.data.url1,
                                        title: profileListData.data.title1,
                                        text: profileListData.data.text1,
                                    },
                                    {
                                        url: profileListData.data.url2,
                                        title: profileListData.data.title2,
                                        text: profileListData.data.text2,
                                    },
                                    {
                                        url: profileListData.data.url3,
                                        title: profileListData.data.title3,
                                        text: profileListData.data.text3,
                                    },
                                    {
                                        url: profileListData.data.url4,
                                        title: profileListData.data.title4,
                                        text: profileListData.data.text4,
                                    },
                                ].map((image, i) => (
                                    <SwiperSlide key={i}>
                                        <div className="w-full">
                                            <div className="w-full aspect-[1/1] overflow-hidden mx-auto flex items-center justify-center">
                                                <img
                                                    src={image.url}
                                                    alt=""
                                                    className="w-full h-full object-contain bg-auto"
                                                />
                                            </div>
                                            <div className="w-full text-sm text-center px-3 break-words">
                                                {image.title}
                                            </div>
                                            <CustomTypography
                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                text={image.text ?? ""}
                                                maxLines={3}
                                                expandable
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </>
                )}

                {profileListData.type === "videoLink" && (
                    <div className="relative w-full h-full aspect-video rounded-lg border-black border-2 overflow-hidden mx-auto">
                        {profileListData.data && (
                            <ReactPlayer
                                url={
                                    profileListData.data.url ||
                                    profileListData.data
                                }
                                playing={isPlaying}
                                controls={true}
                                playsinline
                                width="100%"
                                height="100%"
                                onClick={() => {
                                    handlePlaying();
                                }}
                            />
                        )}
                    </div>
                )}
                {["textLink", "text"].includes(profileListData.type) && (
                    <div className="relative">
                        <div
                            style={{
                                backgroundColor:
                                    profileListData.data?.bgColor ||
                                    "transparent",
                            }}
                            className="w-full h-auto text-wrap"
                        >
                            <div className="p-4 text-wrap break-words ql-editor custom-quilljs">
                                <CustomTypography
                                    html={quillConverter.convert()}
                                    maxLines={5}
                                    expandable
                                />
                            </div>
                        </div>
                    </div>
                )}
                {profileListData.type === "map" && (
                    <div style={{ width: "100%" }}>
                        {profileListData.data && (
                            <div
                                style={{ width: "100%" }}
                                className="aspect-video"
                            >
                                <iframe
                                    title="Google Map"
                                    width="100%"
                                    style={{ border: "0" }}
                                    src={
                                        profileListData.data.startsWith(
                                            "https://www.google.com/maps/embed"
                                        )
                                            ? profileListData.data
                                            : profileListData.data.startsWith(
                                                  "https://www.google.com/maps/"
                                              )
                                            ? generateEmbedUrl(
                                                  profileListData.data
                                              )
                                            : getEmbedUrl(profileListData.data)
                                    }
                                    className="aspect-video"
                                    allowFullScreen
                                />
                            </div>
                        )}
                    </div>
                )}
                {profileListData.type === "mapLink" && (
                    <div style={{ width: "100%" }}>
                        {profileListData.data && (
                            <div
                                style={{ width: "100%" }}
                                className="aspect-video"
                            >
                                <iframe
                                    title="Google Map"
                                    width="100%"
                                    style={{ border: "0" }}
                                    src={
                                        profileListData.data.mapLink.startsWith(
                                            "https://www.google.com/maps/embed"
                                        )
                                            ? profileListData.data.mapLink
                                            : profileListData.data.mapLink.startsWith(
                                                  "https://www.google.com/maps/"
                                              )
                                            ? generateEmbedUrl(
                                                  profileListData.data.mapLink
                                              )
                                            : getEmbedUrl(
                                                  profileListData.data.mapLink
                                              )
                                    }
                                    className="aspect-video"
                                    allowFullScreen
                                />
                            </div>
                        )}
                    </div>
                )}
                {["link", "dataLink"].includes(profileListData.type) && (
                    <div
                        style={{ backgroundColor: linkColor || "white" }}
                        className="flex p-3 gap-3 justify-start items-center border rounded-md"
                    >
                        <div className="w-[50px] aspect-square">
                            <img
                                className="w-auto h-auto cursor-pointer max-[800px]:w-[40px] max-[800px]:h-[40px]"
                                src={profileListData.data.iconLink ?? ""}
                                alt="edit"
                            />
                        </div>
                        <div className="w-[calc(100%-50px)]">
                            <div className="w-full text-lg font-bold rounded-md px-2 overflow-hidden break-all">
                                {profileListData.data.title ?? ""}
                            </div>
                            <div className="w-full text-sm rounded-md px-2 overflow-hidden break-all">
                                {profileListData.data.text ?? ""}
                            </div>
                        </div>
                    </div>
                )}
                {["spaceAdd", "space"].includes(profileListData.type) && (
                    <div
                        className={`w-full p-2 bg-slate-200 rounded-lg`}
                        style={{
                            height:
                                (profileListData.data.space ??
                                    profileListData.data) * 5,
                        }}
                    ></div>
                )}
                {profileListData.type === "selfProfile" && (
                    <div className={`w-full p-2 `}>
                        <div
                            style={{
                                backgroundColor:
                                    profileListData.data?.color || "white",
                            }}
                            className="py-2 px-4 text-white rounded-lg cursor-pointer hover:opacity-90 active:opacity-80 overflow-hidden break-all"
                        >
                            {profileListData.data.title ?? ""}
                        </div>
                        <div className="w-full text-sm rounded-md p-2 px-3 my-1 text-wrap overflow-hidden break-all">
                            {(
                                profileListData.data.text ??
                                profileListData.data.content ??
                                ""
                            )
                                .split("\n")
                                .map((text, index) => (
                                    <CustomTypography
                                        key={index}
                                        text={text}
                                        maxLines={3}
                                        expandable
                                    />
                                    // <p key={index}>{text}</p>
                                ))}
                        </div>
                    </div>
                )}
                {["slideImage", "slideLink"].includes(profileListData.type) && (
                    <div className="flex flex-col items-center h-[calc(100%-34px)]">
                        <div
                            className="relative overflow-y-auto w-full h-full bg-slate-300"
                            ref={docContainerRef}
                        >
                            <Document
                                className={"w-full h-full overflow-auto"}
                                file={
                                    profileListData.data.file ??
                                    profileListData.data.url ??
                                    ""
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                                loading={LoadingComponent}
                                onClick={(e) => e.stopPropagation()}
                                onMouseDown={(e) => e.stopPropagation()}
                                onMouseMove={(e) => e.stopPropagation()}
                                onTouchStart={(e) => e.stopPropagation()}
                                onTouchMove={(e) => e.stopPropagation()}
                            >
                                {Array.from(
                                    new Array(numPages),
                                    (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            width={pdfWidth}
                                            canvasRef={
                                                index === 0 ? pdfPageRef : null
                                            }
                                            onLoadSuccess={() =>
                                                updatePdfWidth()
                                            }
                                        />
                                    )
                                )}
                            </Document>
                        </div>
                        <div className="w-full text-sm text-center px-3 break-words ">
                            {profileListData.data.title ?? ""}
                        </div>
                        <div className="w-full text-sm text-center px-3 break-words text-gray-500">
                            <CustomTypography
                                text={profileListData.data.text}
                                maxLines={3}
                                expandable
                            />
                            {/* {profileListData.data.text ?? ""} */}
                        </div>
                        {profileListData.data.enableDownload && (
                            <button
                                className="border-2 border-[#FF8C05] bg-white p-2 px-7 mt-3 rounded-full text-red-600 flex drop-shadow-lg font-bold"
                                onClick={() =>
                                    handleDownload(
                                        profileListData.data.file,
                                        profileListData.data.title || "document"
                                    )
                                }
                            >
                                ダウンロード
                                <img
                                    className="shrink-0"
                                    alt="download"
                                    src="/image/ダウンロード.png"
                                    width="25"
                                />
                            </button>
                        )}
                    </div>
                )}
                {(profileListData.startTime ||
                    profileListData.data.startTime ||
                    profileListData.endTime ||
                    profileListData.data.endTime) && (
                    <div className="flex justify-end pt-2">
                        <ScheduledText />
                    </div>
                )}
            </div>
        </div>
    );
}
