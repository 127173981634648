import { CircularProgress, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalFooter,
    TERipple,
} from "tw-elements-react";

import { ShoppingCartOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import Badge from "@mui/material/Badge";
import { toast } from "react-toastify";
import { Apis, myConfig } from "../../api";
import { useAuthContext } from "../../auth/context";
import PolicyDetailModal from "../../components/Modal/PolicyDetailModal";
import HorizontalSlider from "../../components/Slidecomponent";
import { formatCurrency } from "../../utilities/helper";
import dayjs from "dayjs";

export default function Shopdetail() {
    const { code } = useParams();
    const navigate = useNavigate();
    const { user, setShowLoginModal } = useAuthContext();
    const [related_goods, setRelatedGoods] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showReturnPolicy, setShowReturnPolicy] = useState(false);
    const [showInquiryForm, setShowInquiryForm] = useState(false);
    const [showInquirySent, setShowInquirySent] = useState(false);
    const [productData, setProductData] = useState({});
    const [orderQuantity, setOrderQuantity] = useState(1);
    const [numberCartItem, setNumberCartItem] = useState(0);
    const isFirstRender = useRef(true);
    const [isLoading, setIsLoading] = useState(false);

    const getNumberCartItem = async () => {
        try {
            const response = await Apis.myGet("account/cart");
            if (response && response.success) {
                setNumberCartItem(response.data.data.length);
                return;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleFormatRelatedProduct = (relatedGoods) => {
        return relatedGoods.map((item, index) => ({
            img: item.image_urls[0],
            id: item.code,
            name: item.title,
            url: `${window.location.href}/d-shop/detail/${item.code}`,
            price: `${formatCurrency(item.price)}円`,
        }));
    };

    useEffect(() => {
        const getDetailProduct = async () => {
            try {
                const response = await Apis.myGet(`shop/product?code=${code}`);
                if (response && response.success) {
                    setProductData(response.data);
                    return;
                }
                throw new Error("製品データを取得できませんでした");
            } catch (error) {
                toast.error(error.message);
                console.error(error);
            }
        };
        const getRelatedGoods = async () => {
            try {
                const response = await Apis.myGet(
                    "shop/productList?related_product=true&code=" + code
                );
                if (response && response.success) {
                    setRelatedGoods(response.data.items);
                    return;
                }
                throw new Error("関連商品を取得できませんでした");
            } catch (error) {
                toast.error(error.message);
                console.error(error);
            }
        };
        if (isFirstRender.current) {
            getDetailProduct();
            getRelatedGoods();
            if (user) {
                getNumberCartItem();
            }
            isFirstRender.current = false;
        }
    }, [user]);
    const quantity = [1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000];
    const handleAddToCart = async (e) => {
        e.preventDefault();
        if (!user) {
            setShowLoginModal(true);
            return;
        }
        if (productData.stock === 0) {
            toast.error(
                <>
                    <p>大変申し訳ございません</p>
                    <p>入荷までしばらくお待ちください</p>
                </>
            );
            return;
        }
        try {
            const response = await Apis.myPut("account/cart", {
                productCode: productData.code,
                quantity: orderQuantity,
                type: "add",
            });
            if (response && response.success) {
                getNumberCartItem();
                setShowModal(true);
                return;
            }
            throw new Error(
                response ? response.data.error : "カートに追加できませんでした"
            );
        } catch (error) {
            toast.error(error.message || "カートに追加できませんでした");
            console.error(error);
        }
    };

    const handleInquirySubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        try {
            setIsLoading(true);
            const res = await Apis.myPost(`account/ask`, {
                title: ` [${productData?.title ?? ""}]についてのお問合せ`,
                ...data,
            });
            if (res && res.success) {
                setShowInquiryForm(false);
                setShowInquirySent(true);
                return;
            }
            throw new Error("お問い合わせを送信できませんでした");
        } catch (error) {
            toast.error(error.message);
            console.error("Error sending email:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleProcessContract = () => {
        navigate("/d-shop/contract/1");
    };

    return (
        <div>
            <div className="flex justify-between items-center">
                <Link to="/d-dashboard">
                    <img
                        alt="logo"
                        src="/image/logo.png"
                        width="150"
                        className="pt-3 pl-5"
                    />
                </Link>
                <div>
                    <Button
                        className="rounded-full"
                        onClick={handleProcessContract}
                    >
                        <Badge badgeContent={numberCartItem} color="warning">
                            <ShoppingCartOutlined sx={{ color: "#977AFF" }} />
                        </Badge>
                    </Button>
                </div>
            </div>
            <div className="mx-auto pt-10 w-full ">
                <Swiper spaceBetween={0} slidesPerView={1}>
                    {(productData?.image_urls ?? []).map((image, i) => (
                        <SwiperSlide key={i}>
                            <img
                                src={image}
                                alt="img"
                                className="aspect-video object-contain w-full"
                                style={{
                                    backgroundPosition: "center",
                                }}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <div className="px-10">
                <h1 className="text-2xl font-bold my-3">
                    {productData?.title}
                </h1>
                <Divider />
                <div className="my-2">商品コード {productData?.code}</div>
                <div className="my-2 text-xl">
                    価格{" "}
                    <span className="ml-2 text-red-500">
                        {formatCurrency(productData?.price)}円
                    </span>
                </div>
                <div className="my-2">送料 全国一律無料</div>
                <div className="my-2">
                    数量
                    <select
                        className="ml-3 border text-lg"
                        value={orderQuantity}
                        onChange={(e) => setOrderQuantity(e.target.value)}
                    >
                        {quantity.map((item, index) => (
                            <option
                                key={index}
                                disabled={item > productData.stock}
                                value={item}
                            >
                                {item}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="my-2 flex flex-col items-center justify-center">
                    <TERipple rippleColor="white" className="w-[50%]">
                        <button
                            className="flex items-center min-[400px]:px-10 max-[400px]:px-2 py-3 w-full bg-neutral-600 justify-center text-white cursor-pointer max-[400px]:text-sm"
                            onClick={handleAddToCart}
                        >
                            <img
                                alt="cart"
                                src="/image/cart.svg"
                                width={20}
                                height={20}
                                className="mr-3"
                            />
                            カートに入れる
                        </button>
                        <div className="absolute left-0 -bottom-10">
                            <button
                                className="text-blue-500 underline"
                                onClick={() => setShowReturnPolicy(true)}
                            >
                                返品について
                            </button>
                        </div>
                    </TERipple>
                    <TERipple rippleColor="blue" className="mt-16">
                        <button
                            className="flex justify-center items-center text-black rounded-full border-2 border-primary-100 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:border-primary-accent-100 hover:bg-neutral-500 hover:bg-opacity-10 focus:border-primary-accent-100 focus:outline-none focus:ring-0 active:border-primary-accent-200 dark:text-primary-100 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            onClick={() => {
                                if (!user) {
                                    setShowLoginModal(true);
                                    return;
                                }
                                setShowInquiryForm(true);
                            }}
                        >
                            <svg
                                width="24"
                                height="18"
                                className="mr-5"
                                viewBox="0 0 30 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.91667 23.3333C2.11459 23.3333 1.42771 23.0475 0.856044 22.4758C0.284377 21.9042 -0.000969746 21.2178 2.47595e-06 20.4167V2.91667C2.47595e-06 2.11459 0.285836 1.42771 0.857503 0.856044C1.42917 0.284377 2.11556 -0.000969746 2.91667 2.47595e-06H26.25C27.0521 2.47595e-06 27.739 0.285836 28.3106 0.857502C28.8823 1.42917 29.1676 2.11556 29.1667 2.91667V20.4167C29.1667 21.2188 28.8808 21.9056 28.3092 22.4773C27.7375 23.049 27.0511 23.3343 26.25 23.3333H2.91667ZM14.5833 13.125L26.25 5.83334V2.91667L14.5833 10.2083L2.91667 2.91667V5.83334L14.5833 13.125Z"
                                    fill="#545454"
                                />
                            </svg>
                            この商品について問い合わせる
                        </button>
                    </TERipple>
                </div>
                <div className="border-l-4 px-3 my-2 text-lg">商品説明</div>
                <div
                    className="px-4 text-wrap w-full"
                    dangerouslySetInnerHTML={{
                        __html: productData?.description,
                    }}
                ></div>
            </div>
            <div className="w-full mt-12 min-[400px]:p-[80px] max-[400px]:p-[30px] aspect-[4/1] bg-neutral-300 flex justify-center items-center">
                <div>
                    <div className="mb-5 flex">
                        <a
                            className="text-indigo-600 hover:underline hover:text-indigo-700 active:text-indigo-800"
                            href="https://degime.net/d-home/specified-commercial-transactions/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            特定商取引法に基づく表示
                        </a>
                        |
                        <a
                            className="text-indigo-600 hover:underline hover:text-indigo-700 active:text-indigo-800"
                            href="https://degime.net/d-home/c-policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            プライバシーポリシー
                        </a>
                    </div>

                    <div>Copyright ©degimeカード All Rights Reserved.</div>
                </div>
            </div>
            <TEModal show={showModal} setShow={setShowModal} staticBackdrop>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        minWidth: "300px",
                    }}
                    className="md:w-full !w-[90%]"
                >
                    <TEModalContent style={{ padding: "30px" }}>
                        {/* <!--Modal body--> */}
                        <TEModalBody>
                            <div className="flex flex-col items-center">
                                <div>ショッピングカートに登録しました。</div>
                                <div>
                                    <img
                                        alt="good"
                                        src={
                                            productData?.image_urls?.[0] ??
                                            "/image/nfc-card.png"
                                        }
                                        className="w-[200px] h-auto object-contain"
                                    />
                                    <div>
                                        <p>{productData?.code}</p>
                                        <p className="text-lg font-bold">
                                            {productData?.title}
                                        </p>
                                        <p>数量: {orderQuantity}</p>
                                        <p className="text-lg text-red-500">
                                            {formatCurrency(productData?.price)}
                                            円
                                        </p>
                                    </div>
                                </div>
                                <div className="border-l-4 border-t-2 p-4 mt-5">
                                    これを見た人はこれも見ています
                                </div>
                                <div className="flex gap-x-2 my-5 w-full">
                                    <HorizontalSlider
                                        data={handleFormatRelatedProduct(
                                            related_goods
                                        )}
                                    />
                                </div>
                            </div>
                        </TEModalBody>
                        <TEModalFooter>
                            {
                                <div className="w-full flex justify-around">
                                    <TERipple>
                                        <a
                                            // type="button"
                                            className="inline-block rounded-sm border-2 bg-secondary border-secondary pc:px-6 px-1 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:border-secondary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-secondary-600 focus:border-secondary-600 focus:text-secondary-600 focus:outline-none focus:ring-0 active:border-secondary-700 active:text-secondary-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                                            aria-label="c-cart"
                                            href="/d-shop/contract/1"
                                        >
                                            カートを見る
                                        </a>
                                    </TERipple>
                                    <TERipple>
                                        <button
                                            type="button"
                                            className="inline-block rounded-sm bg-secondary-200 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-secondary-700 transition duration-150 ease-in-out hover:bg-secondary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                            onClick={() => setShowModal(false)}
                                            aria-label="Close"
                                        >
                                            ショッピングを続ける
                                        </button>
                                    </TERipple>
                                </div>
                            }
                        </TEModalFooter>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            {/* 返品ポリシーモーダル */}
            <PolicyDetailModal
                open={showReturnPolicy}
                onClose={() => setShowReturnPolicy(false)}
            />
            <TEModal
                show={showInquiryForm}
                setShow={setShowInquiryForm}
                staticBackdrop
            >
                <div
                    className="fixed inset-0 bg-black bg-opacity-50"
                    onClick={() => setShowInquiryForm(false)}
                ></div>
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: "700px",
                        maxHeight: "80vh",
                        overflowY: "auto",
                    }}
                >
                    <TEModalContent
                        style={{ padding: "30px", position: "relative" }}
                    >
                        {isLoading && (
                            <div className=" absolute bg-zinc-400/50 h-full w-full inset-0 z-30 flex justify-center items-center">
                                <CircularProgress />
                            </div>
                        )}
                        <TEModalBody>
                            <div className="flex flex-col items-center">
                                <h2 className="text-lg font-bold">
                                    [{productData?.title ?? ""}
                                    ]についてのお問合せ
                                </h2>
                                <form
                                    onSubmit={handleInquirySubmit}
                                    className="w-full"
                                >
                                    <div className="my-3 w-full">
                                        <label className="block text-left text-sm">
                                            お名前{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="text"
                                            className="border rounded p-2 w-full"
                                            required
                                            name="name"
                                            style={{
                                                boxSizing: "border-box",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                padding: "8px",
                                                fontSize: "14px",
                                            }}
                                        />
                                    </div>
                                    <div className="my-3 w-full">
                                        <label className="block text-left text-sm">
                                            メールアドレス{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <input
                                            type="email"
                                            className="border rounded p-2 w-full"
                                            required
                                            name="email"
                                            style={{
                                                boxSizing: "border-box",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                padding: "8px",
                                                fontSize: "14px",
                                            }}
                                        />
                                    </div>
                                    <div className="my-3 w-full">
                                        <label className="block text-left text-sm">
                                            お問い合わせ内容{" "}
                                            <span className="text-red-500">
                                                *
                                            </span>
                                        </label>
                                        <textarea
                                            className="border rounded p-2 w-full"
                                            required
                                            name="content"
                                            style={{
                                                boxSizing: "border-box",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                padding: "8px",
                                                fontSize: "14px",
                                                height: "100px",
                                            }}
                                        ></textarea>
                                    </div>
                                    <div
                                        className="text-left w-full my-3"
                                        style={{ color: "black" }}
                                    >
                                        お問合せ内容を入力し「
                                        <a
                                            className="text-blue-500"
                                            href="/d-home/c-policy"
                                            target="_blank"
                                            style={{
                                                background: "none",
                                                border: "none",
                                                padding: 0,
                                                cursor: "pointer",
                                                fontSize: "inherit",
                                                color: "#007bff",
                                            }}
                                        >
                                            個人情報保護に関する基本方針
                                        </a>
                                        」に同意の上、送信してください。
                                    </div>
                                    <div className="my-3 w-full">
                                        <input
                                            type="hidden"
                                            name="itemCode"
                                            value={code}
                                        />
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white p-2 rounded w-full"
                                            style={{
                                                backgroundColor: "#007bff",
                                                border: "none",
                                                borderRadius: "4px",
                                                color: "white",
                                                padding: "10px",
                                                textAlign: "center",
                                                textDecoration: "none",
                                                display: "inline-block",
                                                fontSize: "16px",
                                            }}
                                        >
                                            同意して送信
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>

            {/* 問い合わせ送信完了モーダル */}
            <TEModal
                show={showInquirySent}
                setShow={setShowInquirySent}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90%",
                        maxWidth: "800px",
                        maxHeight: "80vh",
                        overflowY: "auto",
                    }}
                >
                    <TEModalContent style={{ padding: "30px" }}>
                        <TEModalBody>
                            <div className="flex flex-col items-center">
                                <h2 className="text-lg font-bold">
                                    お問い合わせ内容が送信されました
                                </h2>
                                <p>
                                    Degime製品にお問い合わせいただき、ありがとうございます。２営業日以内にメールにて返信させて頂きます。サポートからのメールをご確認ください。
                                </p>
                                <p>今後ともDegimeをよろしくお願い致します</p>
                                <p>
                                    送信日時：
                                    {dayjs().format("YYYY-MM-DD HH:mm")}
                                </p>
                                <button
                                    className="mt-5 px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                                    onClick={() => setShowInquirySent(false)}
                                >
                                    OK
                                </button>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
        </div>
    );
}
