import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
    TEModal,
    TEModalBody,
    TEModalContent,
    TEModalDialog,
    TEModalHeader,
} from "tw-elements-react";

import "swiper/css";
import "swiper/css/navigation";
import "./custom-swiper.css";

import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { googleLogout } from "@react-oauth/google";
import { Apis } from "../api";
import { useAuthContext } from "../auth/context";
import "../pages/Dashboard.css";
// import Sidebar from "../components/Sidebar";
import liff from "@line/liff";
import {
    Autocomplete,
    Avatar,
    Box,
    InputAdornment,
    TextField,
} from "@mui/material";
import AlarmPart from "../components/AlarmPart";
import debounce from "lodash/debounce";
import PersonDescription from "../components/PersonDescription";

const Dashboard = (props) => {
    const { user, reAuth, resetUserInfo, setShowLoginModal } = useAuthContext();

    const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [topImages, setTopImages] = useState([]);
    const [carouselItems, setCarouselItems] = useState(<></>);
    const [notification, setNotification] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [search, setSearch] = useState("");

    const handleChangeSearch = (text) => {
        setSearch(text);
    };

    const searchUser = async () => {
        try {
            const request = await Apis.myGet(
                `account/search?keySearch=${search}`
            );
            const { data } = request.data;
            if (data) {
                setListUser(data);
                return;
            }
            setListUser([]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        reAuth();
        GetTopImage();
    }, []);

    useEffect(() => {
        if (!search) {
            setListUser([]);
            return;
        }
        const handleSearchUser = debounce(async () => {
            await searchUser();
        }, 500);
        handleSearchUser();
        return () => {
            handleSearchUser.cancel();
        };
    }, [search]);

    //top画像の取得
    const GetTopImage = async () => {
        const res = await Apis.myGet(`settingImage/settingImage?imageUsage=1`);

        if (res && res.success && res.data.items.length != 0) {
            setTopImages(res.data.items);
        }
    };

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + "</span>";
        },
    };

    useEffect(() => {
        if (topImages.length !== 0) {
            const newCarouselItems = (
                <Swiper
                    pagination={pagination}
                    modules={[Pagination, Navigation]}
                    navigation={true}
                    loop={true}
                    className="custom-swiper "
                >
                    <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
                        {topImages.map((p, index) => (
                            <SwiperSlide key={index}>
                                {p.link ? (
                                    <Link to={p.link} target="_blank">
                                        {p.image_url.endsWith(".mp4") ? (
                                            <video
                                                className="slide-content"
                                                src={p.image_url}
                                                autoPlay
                                                muted
                                                loop
                                            />
                                        ) : (
                                            <img
                                                className="block slide-content"
                                                src={p.image_url}
                                                alt="..."
                                            />
                                        )}
                                    </Link>
                                ) : (
                                    <>
                                        {p.image_url.endsWith(".mp4") ? (
                                            <video
                                                className="slide-content"
                                                src={p.image_url}
                                                autoPlay
                                                muted
                                                loop
                                            />
                                        ) : (
                                            <img
                                                className="block slide-content"
                                                src={p.image_url}
                                                alt="..."
                                            />
                                        )}
                                    </>
                                )}
                            </SwiperSlide>
                        ))}
                    </div>
                </Swiper>
            );
            setCarouselItems(newCarouselItems);
        } else {
            setCarouselItems([]); // Clear carousel items if topImages is empty
        }
    }, [topImages]);

    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false); // Hide the dropdown
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Clean up the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const getNotification = async () => {
        try {
            const res = await Apis.myGet(`notification/online`);
            if (res?.data) {
                setNotification(res?.data);
                return;
            }
            setNotification([]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!user) {
            setNotification([]);
            return;
        }
        localStorage.setItem("userInfo", JSON.stringify(user));
        getNotification();
    }, [user]);

    const handleReadNotification = async (id) => {
        try {
            await Apis.myPut(`notification/online`, {
                notification_id: id,
                isRead: true,
            });
            await getNotification();
        } catch (error) {
            console.error(error);
        }
    };

    const onLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        liff.logout();

        resetUserInfo();
        googleLogout();
        localStorage.removeItem("userInfo");
        localStorage.removeItem("Publish_cache");
        localStorage.removeItem("Publish_Social_cache");
        localStorage.removeItem("Public_PublishCompleteModalNotShowAgain");
        localStorage.removeItem("SNS_PublishCompleteModalNotShowAgain");
        localStorage.removeItem("shippingInfo");
        setIsOpen(false);
        toast.success(`ログアウトしました`);
    };

    return (
        <>
            <div className="relative">
                {carouselItems}

                <div className="alarmPart">
                    <AlarmPart
                        data={notification}
                        onChange={handleReadNotification}
                    />
                    <div className="downBar my-auto" ref={dropdownRef}>
                        <img
                            alt="contactLists"
                            src="/image/contactLists.png"
                            id="contactLists"
                            onClick={toggleDropdown}
                        ></img>
                        {isOpen && (
                            <div className="downList mt-[10px]">
                                <div className="unannouncedList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/nocontactlist"
                                    >
                                        非通知一覧
                                    </Link>
                                </div>
                                <div className="blockList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/blocklist"
                                    >
                                        ブロック一覧
                                    </Link>
                                </div>
                                <div className="deleteList list">
                                    <Link
                                        className="listLink"
                                        to="/d-layout/list/deletedlist"
                                    >
                                        削除一覧
                                    </Link>
                                </div>
                                {user && (
                                    <div className="deleteList list">
                                        <Link
                                            className="listLink"
                                            to=""
                                            onClick={onLogout}
                                        >
                                            ログアウト
                                        </Link>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="mainButtons">
                    <span className="searchButton button">
                        <div className="relative w-full ">
                            <form
                                onSubmit={(e) => e.preventDefault()}
                                autoComplete="off"
                            >
                                <Autocomplete
                                    freeSolo
                                    disableClearable
                                    options={listUser}
                                    // onChange={handleSelectUser}
                                    inputValue={search}
                                    autoComplete={false}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option) => option.username}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className="!z-10 no-border top-search"
                                            placeholder="検索"
                                            onChange={(e) => {
                                                handleChangeSearch(
                                                    e.target.value
                                                );
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                onKeyDown: (e) => {
                                                    if (e.key === "Enter") {
                                                        e.stopPropagation();
                                                    }
                                                },
                                                autoComplete: "new-password",
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start"
                                                        className="!h-8 flex justify-center items-center"
                                                    >
                                                        <div
                                                            id="searchIcon"
                                                            className="flex justify-center !px-1 md:!px-4"
                                                        >
                                                            <svg
                                                                width="26"
                                                                height="26"
                                                                viewBox="0 0 26 26"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M17.0635 18.9371C14.9388 20.5885 12.2648 21.3676 9.58605 21.1157C6.90731 20.8638 4.42524 19.5999 2.64524 17.5813C0.865234 15.5627 -0.0788693 12.9412 0.00516452 10.2506C0.0891983 7.55999 1.19505 5.0026 3.09755 3.09913C5.00005 1.19566 7.55615 0.0892437 10.2454 0.00516714C12.9346 -0.0789094 15.5548 0.865674 17.5723 2.64658C19.5899 4.4275 20.8532 6.91082 21.105 9.59092C21.3567 12.271 20.5781 14.9464 18.9275 17.0722L26 24.1219L24.1228 26L17.0768 18.9371H17.0635ZM10.5859 18.5139C11.6276 18.5139 12.659 18.3086 13.6213 17.9098C14.5836 17.511 15.458 16.9265 16.1945 16.1896C16.9311 15.4526 17.5153 14.5778 17.9139 13.615C18.3125 12.6522 18.5177 11.6202 18.5177 10.5781C18.5177 9.53595 18.3125 8.50401 17.9139 7.5412C17.5153 6.57838 16.9311 5.70355 16.1945 4.96664C15.458 4.22973 14.5836 3.64518 13.6213 3.24637C12.659 2.84756 11.6276 2.6423 10.5859 2.6423C8.4823 2.6423 6.46483 3.47839 4.97733 4.96664C3.48984 6.45489 2.65417 8.47339 2.65417 10.5781C2.65417 12.6828 3.48984 14.7013 4.97733 16.1896C6.46483 17.6778 8.4823 18.5139 10.5859 18.5139Z"
                                                                    fill="white"
                                                                />
                                                            </svg>
                                                        </div>
                                                        <div id="stick"></div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <Box
                                                key={key}
                                                component="li"
                                                {...optionProps}
                                            >
                                                <SearchResultItem
                                                    data={option}
                                                />
                                            </Box>
                                        );
                                    }}
                                />
                            </form>
                        </div>
                    </span>
                    <div
                        className={`${
                            user && user.onlineCardUrl && "grid grid-cols-12"
                        } gap-3 mx-auto mb-8 onlinePattern`}
                    >
                        <Link
                            className="col-span-9 md:col-span-10 flex justify-center items-center bg-[#2ACEFF] button "
                            to={user ? "/d-online" : ""}
                            onClick={() => {
                                if (!user) {
                                    setShowLoginModal(true);
                                }
                            }}
                        >
                            <div className="space"></div>
                            <div className="buttonName">オンライン名刺編集</div>
                            <div className="plusIcon">
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.3329 3.77817C15.0315 -0.523156 8.07784 -0.523156 3.77651 3.77817C-0.524818 8.07951 -0.524818 15.0332 3.77651 19.3345C8.07784 23.6359 15.0315 23.6359 19.3329 19.3345C23.6342 15.0332 23.6342 8.07951 19.3329 3.77817ZM12.6514 18.2378H10.458V12.6531L4.87324 12.6531L4.87324 10.4596H10.458L10.458 4.8749H12.6514L12.6514 10.4596H18.2361L18.2361 12.6531L12.6514 12.6531V18.2378Z"
                                        fill="#F8F8F8"
                                    />
                                </svg>
                            </div>
                        </Link>
                        {user && user.onlineCardUrl && (
                            <Link
                                className="col-span-3 md:col-span-2 bg-[#FFCC4D] button p-6 flex justify-center items-center"
                                to={`/${user.onlineCardUrl}`}
                                target="_blank"
                            >
                                <p className="whitespace-nowrap font-semibold text-white">
                                    公開中
                                </p>
                            </Link>
                        )}
                    </div>
                    <div
                        className={`${
                            user && user.snsTree && "grid grid-cols-12"
                        } gap-3 mx-auto mb-8 snsPattern`}
                    >
                        <Link
                            className="col-span-9 md:col-span-10 flex justify-center items-center bg-[#2ACEFF] button "
                            to={user ? "/d-editsocial" : ""}
                            onClick={() => {
                                if (!user) {
                                    setShowLoginModal(true);
                                }
                            }}
                        >
                            <div className="space"></div>
                            <div className="buttonName">
                                SNS向けリンクツリー
                            </div>
                            <div className="plusIcon">
                                <svg
                                    width="23"
                                    height="23"
                                    viewBox="0 0 23 23"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.3329 3.77817C15.0315 -0.523156 8.07784 -0.523156 3.77651 3.77817C-0.524818 8.07951 -0.524818 15.0332 3.77651 19.3345C8.07784 23.6359 15.0315 23.6359 19.3329 19.3345C23.6342 15.0332 23.6342 8.07951 19.3329 3.77817ZM12.6514 18.2378H10.458V12.6531L4.87324 12.6531L4.87324 10.4596H10.458L10.458 4.8749H12.6514L12.6514 10.4596H18.2361L18.2361 12.6531L12.6514 12.6531V18.2378Z"
                                        fill="#F8F8F8"
                                    />
                                </svg>
                            </div>
                        </Link>
                        {user && user.snsTree && (
                            <Link
                                className="col-span-3 md:col-span-2 bg-[#FFCC4D] button p-6 flex justify-center items-center"
                                to={`/${user.snsTree}`}
                                target="_blank"
                            >
                                <p className="whitespace-nowrap font-semibold text-white">
                                    公開中
                                </p>
                            </Link>
                        )}
                    </div>
                    <Link
                        className="contactManage button small-btn"
                        to={user ? "/d-contact" : ""}
                        onClick={() => {
                            if (!user) {
                                setShowLoginModal(true);
                            }
                        }}
                    >
                        <div className="buttonName">名刺管理</div>
                        {/* <div className="dashBoardBadge mainContactBadge">
              {props.contact_badge}
            </div> */}
                    </Link>

                    <Link className="degimeWrite button small-btn">
                        <button
                            type="button"
                            className="buttonName"
                            onClick={() => setShowModal(true)}
                        >
                            degimeカードに書き込む
                        </button>
                    </Link>

                    <TEModal show={showModal} setShow={setShowModal}>
                        <TEModalDialog className="mobile:px-4">
                            <TEModalContent>
                                <TEModalHeader className="bg-custom-blue 500">
                                    {/* <!--Modal title--> */}
                                    <h5 className="text-xl font-medium leading-normal dark:text-neutral-200 text-white">
                                        この機能はアプリでのみ可能です。
                                    </h5>
                                    {/* <!--Close button--> */}
                                    <button
                                        type="button"
                                        className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                        aria-label="Close"
                                    ></button>
                                </TEModalHeader>
                                {/* <!--Modal body--> */}
                                <TEModalBody>
                                    <div
                                        className="qr-code-class"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src="/image/qr-code2.png"
                                            alt="qr-code1"
                                            className="mobile:h-[185px]"
                                        />
                                        <img
                                            src="/image/qr-code2.png"
                                            alt="qr-code2"
                                            className="mobile:h-[185px]"
                                        />
                                    </div>
                                    <img
                                        src="/image/Download-App.png"
                                        alt="download-icon"
                                    />
                                </TEModalBody>
                                {/* <TERipple rippleColor="light">
                <button
                  type="button"
                  className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </TERipple> */}
                            </TEModalContent>
                        </TEModalDialog>
                    </TEModal>
                    <Link className="degimeBuy button small-btn" to={"/d-shop"}>
                        <div className="buttonName">degimeカードを購入する</div>
                    </Link>
                </div>
                <div className="bottomSpace"></div>
            </div>
            {/* <TEModal
                show={showLoginModal}
                setShow={setShowLoginModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        display: "flex",
                        WebkitTransform: "translate(-50%, -50%)",
                        transform: "translate(-50%, -50%) translateZ(0)",
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        maxHeight: "100%",
                        overflowY: "auto", // 縦方向の余剰コンテンツをスクロールできるように設定
                        outline: 0, // フォーカス時の青い枠線を消す
                    }}
                >
                    <TEModalContent>
                        <TEModalBody
                            style={{
                                maxHeight: "calc(100% - 0px)",
                                overflowY: "auto",
                            }}
                        >
                            <div className="login mx-8">
                                <div className="flex justify-center relative">
                                    <Link
                                        alt="logo"
                                        className="logo"
                                        to=""
                                        onClick={() => setShowLoginModal(false)}
                                    ></Link>
                                    <button
                                        type="button"
                                        className="absolute -right-4 top-4 box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                        onClick={() => setShowLoginModal(false)}
                                        aria-label="Close"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="h-6 w-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="text-center text-[22px] font-medium">
                                    {isLogin ? "ログイン" : "新規登録"}
                                </div>
                                <div className="font-semibold mt-[20px]">
                                    {isLogin
                                        ? "アカウント登録がお済みでない方は、"
                                        : "すでに登録済みの方は、"}
                                    <Link
                                        className="text-blue-500 underline"
                                        to=""
                                        onClick={() =>
                                            setIsLogIn((pre) => !pre)
                                        }
                                    >
                                        こちら
                                    </Link>
                                    {isLogin
                                        ? "から新規登録できます"
                                        : "からログインできます"}
                                </div>
                                <form
                                    className="loginMainInfo mt-[30px]"
                                    action=""
                                >
                                    <label className="font-medium text-zinc-400">
                                        Eメール
                                    </label>
                                    <div className="flex items-center">
                                        <img
                                            alt="mail"
                                            src="/image/email.png"
                                            className="w-4 h-auto"
                                            style={{ maxHeight: "100%" }}
                                        ></img>
                                        <input
                                            value={loginData.username}
                                            onChange={(e) => {
                                                setLoginData((pre) => ({
                                                    ...pre,
                                                    username: e.target.value,
                                                }));
                                                setRegisterData((pre) => ({
                                                    ...pre,
                                                    email: e.target.value,
                                                }));
                                            }}
                                            type="email"
                                            id="mail"
                                            name="mail"
                                            className="w-full ml-3 focus:outline-none focus:border-none text-black peer-[1]: placeholder-slate-700 placeholder:font-medium bg-white"
                                            placeholder="メールアドレスを入力"
                                            autocomplete="off"
                                        ></input>
                                    </div>
                                    <div className="w-full h-[2px] bg-neutral-400 mt-1 peer-[1]-focused:bg-slate-900"></div>
                                    {!isLogin && (
                                        <div>
                                            <label className="mt-[60px] font-medium text-zinc-400">
                                                ユーザー名(半角英数字４文字以上)
                                            </label>
                                            <div className="flex items-center">
                                                <img
                                                    alt="mail"
                                                    src="/image/user.png"
                                                    className="w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                                <input
                                                    value={
                                                        registerData.username
                                                    }
                                                    onChange={(e) => {
                                                        setRegisterData(
                                                            (pre) => ({
                                                                ...pre,
                                                                username:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        );
                                                    }}
                                                    type="text"
                                                    className="w-full ml-3 focus:outline-none focus:border-none text-black peer-[1]: placeholder-slate-700 placeholder:font-medium bg-white"
                                                    placeholder="ユーザー名を入力"
                                                ></input>
                                            </div>
                                            <div className="w-full h-[2px] bg-neutral-400 mt-1 peer-[1]-focused:bg-slate-900"></div>
                                        </div>
                                    )}

                                    <label className="mt-[60px] font-medium text-zinc-400">
                                        パスワード
                                    </label>
                                    <div className="flex items-center">
                                        <img
                                            alt="password"
                                            src="/image/password.png"
                                            className="w-4 h-auto"
                                            style={{ maxHeight: "100%" }}
                                        ></img>
                                        <input
                                            value={loginData.password}
                                            onChange={handlePassword}
                                            type={isShow ? "text" : "password"}
                                            id="password"
                                            name="password"
                                            className="w-full ml-3 focus:outline-none focus:border-none text-black placeholder-slate-900 placeholder:font-medium bg-white"
                                            placeholder="パスワードを入力"
                                            autocomplete="off"
                                        ></input>
                                        <img
                                            alt="eye"
                                            src={
                                                isShow
                                                    ? "/image/eye-open.png"
                                                    : "/image/eye-close.png"
                                            }
                                            onClick={handleShowClick}
                                            className="cursor-pointer w-4 h-auto"
                                            style={{ maxHeight: "100%" }}
                                        ></img>
                                    </div>
                                    <div className="w-full h-[2px] bg-neutral-400 mt-1"></div>
                                    {!isLogin && (
                                        <>
                                            <label className="mt-[60px] font-medium text-zinc-400">
                                                パスワードを再入力してください
                                            </label>
                                            <div className="flex items-center">
                                                <img
                                                    alt="password"
                                                    src="/image/password.png"
                                                    className="w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                                <input
                                                    value={verifyPassword}
                                                    onChange={(e) =>
                                                        setVerifyPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                    type={
                                                        isShow
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    className="w-full ml-3 focus:outline-none focus:border-none text-black placeholder-slate-900 placeholder:font-medium bg-white"
                                                    placeholder="パスワード再入力"
                                                ></input>
                                                <img
                                                    alt="eye"
                                                    src={
                                                        isShow
                                                            ? "/image/eye-open.png"
                                                            : "/image/eye-close.png"
                                                    }
                                                    onClick={handleShowClick}
                                                    className="cursor-pointer w-4 h-auto"
                                                    style={{
                                                        maxHeight: "100%",
                                                    }}
                                                ></img>
                                            </div>
                                            <div className="w-full h-[2px] bg-neutral-400 mt-1"></div>
                                        </>
                                    )}
                                    {isLogin && (
                                        <Link
                                            className="fogetPassword float-right mr-2 mt-3"
                                            to=""
                                            onClick={() => {
                                                setShowLoginModal(false);
                                                setShowForgottenModal(true);
                                            }}
                                        >
                                            パスワードを忘れた方
                                        </Link>
                                    )}
                                    <br />
                                    <br />
                                    {!isLogin && (
                                        <div>
                                            <label className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                    className="form-checkbox h-5 w-5 text-indigo-600"
                                                />
                                                <span className="">
                                                    <Link
                                                        to="https://degime.net/d-home/terms_of_use/"
                                                        target="_blank"
                                                        className="text-indigo-600"
                                                    >
                                                        利用規約
                                                    </Link>
                                                    と
                                                    <Link
                                                        to="https://degime.net/d-home/c-policy/"
                                                        target="_blank"
                                                        className="text-indigo-600"
                                                    >
                                                        プライバシーポリシー
                                                    </Link>
                                                    に同意します
                                                </span>
                                            </label>
                                            {!isChecked && (
                                                <p className="text-red-500 text-sm mt-2">
                                                    チェックを入れてください
                                                </p>
                                            )}
                                        </div>
                                    )}

                                    <div
                                        className="w-full text-white text-center cursor-pointer bg-sky-400 hover:bg-sky-500 active:bg-sky-600 transition duration-75 ease-in-out py-3 rounded-full text-base mt-16"
                                        onClick={postRegister}
                                    >
                                        {isLogin ? "ログイン" : "新規登録"}
                                    </div>
                                </form>

                                <div className="socialLinks flex w-60 mt-[60px] mx-auto justify-between mb-[30px]">
                                    <div className="cursor-pointer hover:opacity-70 active:opacity-50">
                                        <Button
                                            sx={{ padding: 0 }}
                                            onClick={handleLoginByLine}
                                        >
                                            <img
                                                className="w-10 h-auto"
                                                src="/image/line.png"
                                                style={{
                                                    maxHeight: "100%",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Button>

                                    </div>

                                    <div className="cursor-pointer hover:opacity-70 active:opacity-50">
                                        <div>
                                            <img
                                                className="google w-10 h-auto"
                                                alt="google"
                                                src="/image/google.png"
                                                onClick={() => login()}
                                                style={{
                                                    maxHeight: "100%",
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>

            <TEModal
                show={showVerifyModal}
                setShow={setShowVerifyModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                送られてくるメールで、コードを確認して入力してください。
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowVerifyModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        <TEModalBody>
                            <NumberInputComponent
                                email={registerData.email}
                                isSuccessful={handleShowVerifyModal}
                            />
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            <TEModal
                show={showForgottenModal}
                setShow={setShowForgottenModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                {isEnteredEmail
                                    ? "メールコードを確認してください。"
                                    : "メールアドレスを入力"}
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowForgottenModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        <TEModalBody>
                            {isEnteredEmail ? (
                                <NumberInputComponent
                                    email={verifyEmail}
                                    isForgotten={showForgottenModal}
                                    isSuccessful={handleShowFogorttenModal}
                                />
                            ) : (
                                <>
                                    <div className="w-full flex justify-center flex-col">
                                        <label htmlFor="verifyEmail">
                                            メールアドレス
                                        </label>
                                        <input
                                            value={verifyEmail}
                                            onChange={(e) =>
                                                setVerifyEmail(e.target.value)
                                            }
                                            type="email"
                                            id="verifyEmail"
                                            className="border p-2"
                                        />
                                    </div>
                                    <div className="flex justify-center mt-10 mb-5">
                                        <TERipple>
                                            <button
                                                type="button"
                                                className="px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white"
                                                onClick={handleVerifyEmail}
                                            >
                                                確 認
                                            </button>
                                        </TERipple>
                                    </div>
                                </>
                            )}
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal>
            <TEModal
                show={showResetModal}
                setShow={setShowResetModal}
                staticBackdrop
            >
                <TEModalDialog
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}
                >
                    <TEModalContent>
                        <TEModalHeader style={{ backgroundColor: "#38bdf8" }}>
                            <h1 className="text-2xl p-3 text-white">
                                メールコードの確認してください。
                            </h1>
                            <button
                                type="button"
                                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                onClick={() => setShowResetModal(false)}
                                aria-label="Close"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="grey"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </TEModalHeader>
                        <TEModalBody>
                            <div className="w-full flex justify-center flex-col">
                                <label htmlFor="oldPassword">
                                    以前のパスワード
                                </label>
                                <input
                                    value={oldPassword}
                                    onChange={(e) =>
                                        setOldPassword(e.target.value)
                                    }
                                    type="password"
                                    id="oldPassword"
                                    className="border p-2"
                                />
                            </div>
                            <div className="w-full flex justify-center flex-col">
                                <label htmlFor="newPassword">
                                    新しいパスワード
                                </label>
                                <input
                                    value={newPassword}
                                    onChange={(e) =>
                                        setNewPassword(e.target.value)
                                    }
                                    type="password"
                                    id="newPassword"
                                    className="border p-2"
                                />
                            </div>
                            <div className="flex justify-center mt-10 mb-5">
                                <TERipple>
                                    <button
                                        type="button"
                                        className="px-5 py-2 rounded-full bg-green-500 cursor-pointer text-white"
                                        onClick={handleResetPassword}
                                    >
                                        確 認
                                    </button>
                                </TERipple>
                            </div>
                        </TEModalBody>
                    </TEModalContent>
                </TEModalDialog>
            </TEModal> */}
        </>
    );
};

export default Dashboard;

export const SearchResultItem = ({ data }) => {
    const handleRedirectDetailUser = () => {
        const a = document.createElement("a");
        a.href = `${window.location.origin}/${
            data.url_business || data.url_sns
        }`;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const person_data = {
        member_company: data.company,
        member_position: data.position,
    };

    const renderName = data.full_name || data.username;

    return (
        <li
            className="flex py-4 first:pt-0 last:pb-0 relative items-center w-full cursor-pointer"
            onClick={handleRedirectDetailUser}
        >
            <Avatar alt={`${renderName}-avatar`} src={data.avatar} />

            <div className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-slate-900">
                    {renderName}
                    {person_data?.contact_type === "SNS" && (
                        <span className="p-1 leading-8 border rounded-md border-[#2ACEFF] bg-[#2ACEFF] text-white ml-2">
                            サブページ
                        </span>
                    )}
                </p>

                <PersonDescription person={person_data} />
            </div>
        </li>
    );
};
