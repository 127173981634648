import React, { useState } from "react";
import { TextSnippet, FileDownloadOutlined } from "@mui/icons-material";
import { formatBytes, handleDownload } from "../../../utilities/helper";
import ReactPlayer from "react-player";
import PreviewModal from "../../../components/PreviewImageModal";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";

export default function ReceivedChat({
    avatar,
    text,
    name,
    size,
    type,
    username,
    sender,
}) {
    const [showModal, setShowModal] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const handleImageClick = (url) => {
        setImageUrl(url);
        setShowModal(true);
    };
    return (
        <div className="flex justify-start">
            <div className="flex w-11/12">
                {type === "text" && (
                    <div className="relative">
                        <div className="flex items-center justify-start gap-1 top-0 -left-[10px]">
                            <Link target="_blank" to={`/${username}`}>
                                <Avatar
                                    src={avatar ?? ""}
                                    alt="avatar"
                                    className="inline-block rounded-full bg-white"
                                    sx={{ height: "24px", width: "24px" }}
                                >
                                    {username[0]}
                                </Avatar>
                            </Link>

                            <div className="flex flex-col">
                                <p className="text-xs">{sender.full_name}</p>
                                <div className="flex items-center gap-1 text-xs text-secondary-700 pt-1 pb-2">
                                    <p>{sender.company_name}</p>
                                    {sender.company_name && sender.position && (
                                        <span>|</span>
                                    )}
                                    <p>{sender.position}</p>
                                </div>
                            </div>
                        </div>

                        <div className="relative max-w-[300px] overflow-hidden break-all rounded-[20px] bg-[#96DA45]  px-4 py-2 self-start shadow-lg ml-6">
                            {text &&
                                text.split("\n").map((item, key) => (
                                    <>
                                        <span
                                            key={key}
                                            className="text-sm font-medium text-heading"
                                        >
                                            {item}
                                        </span>
                                        <br />
                                    </>
                                ))}
                        </div>
                    </div>
                )}
                {type.includes("image/") && (
                    <div className="flex">
                        <img
                            src={text}
                            className="max-w-[200px] h-auto object-contain rounded-lg cursor-pointer"
                            alt={name}
                            onClick={() => handleImageClick(text)}
                        />
                        <div className="h-full flex pl-3">
                            <button onClick={() => handleDownload(text, name)}>
                                <FileDownloadOutlined className="my-auto cursor-pointer" />
                            </button>
                        </div>
                    </div>
                )}
                {(type.includes("application/") || type.includes("text/")) && (
                    <div className="flex">
                        <div className="flex items-center gap-2 bg-zinc-500 w-fit p-2 rounded-lg">
                            <TextSnippet
                                sx={{
                                    color: "#BBBCBD",
                                    width: "20px",
                                    height: "20px",
                                }}
                            />
                            <div className="flex flex-col text-white">
                                <p className="">{name}</p>
                                <p className="text-sm leading-4">
                                    {formatBytes(size)}
                                </p>
                            </div>
                        </div>
                        <div className="h-full flex pl-3">
                            <button onClick={() => handleDownload(text, name)}>
                                <FileDownloadOutlined className="my-auto cursor-pointer" />
                            </button>
                        </div>
                    </div>
                )}
                {type.includes("video/") && (
                    <div className="flex">
                        <div className="preview-player">
                            <ReactPlayer
                                url={text}
                                controls
                                height={150}
                                width={"auto"}
                            />
                        </div>
                        <div className="h-full flex pl-3">
                            <button onClick={() => handleDownload(text, name)}>
                                <FileDownloadOutlined className="my-auto cursor-pointer" />
                            </button>
                        </div>
                    </div>
                )}
                {type.includes("audio/") && (
                    <div className="flex">
                        <div className="preview-player-audio">
                            <ReactPlayer
                                url={text}
                                controls
                                height={50}
                                width={200}
                            />
                        </div>
                        <div className="h-full flex pl-3">
                            <button onClick={() => handleDownload(text, name)}>
                                <FileDownloadOutlined className="my-auto cursor-pointer" />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <PreviewModal
                open={showModal}
                onClose={() => setShowModal()}
                imageUrl={imageUrl}
            />
        </div>
    );
}
