import fileDownload from "js-file-download";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import ReactPlayer from "react-player";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Close, Link as LinkIcon } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { ReactComponent as CompanyNameIcon } from "../assets/icons/companyName.svg";
import { ReactComponent as HotlineIcon } from "../assets/icons/hotline.svg";
import { ReactComponent as LocationIcon } from "../assets/icons/location.svg";
import { ReactComponent as MailIcon } from "../assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "../assets/icons/phoneNumber.svg";
import { ReactComponent as PositionIcon } from "../assets/icons/position.svg";
import { generateEmbedUrl, getEmbedUrl } from "../utilities/helper";
import { isValidEmail, handleTextLink } from "../utilities/helper";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import CustomTypography from "./CustomTypography";
import { AppContext } from "../App";
import PdfLoader from "./PdfLoader";

export default function PublicView({
    publishData,
    isUpdated,
    username,
    notShowBack,
}) {
    // const [lastImage, setLastImages] = useState(
    //     publishData?.idCard && publishData?.idCard.idCard[0]
    // );
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [newProfileData, setNewProfileData] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [previewImageUrl, setPreviewImageUrl] = useState("");
    const [urlPreview, setUrlPreview] = useState("");
    const [isShowPreview, setIsShowPreview] = useState(false);
    const [faceImg, setFaceImg] = useState("");
    const [pdfWidth, setPdfWidth] = useState(0);
    const docContainerRef = useRef(null);
    const { previousPage } = useContext(AppContext);

    const updatePdfWidth = () => {
        if (docContainerRef.current) {
            const { width } = docContainerRef.current.getBoundingClientRect();
            setPdfWidth(width);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", updatePdfWidth);
        updatePdfWidth(); // Initial pdf width
        return () => window.removeEventListener("resize", updatePdfWidth); // Cleanup listener
    });

    const handlePlaying = () => {
        setIsPlaying((pre) => !pre);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleDownload = (url, filename) => {
        axios
            .get(url, {
                responseType: "blob",
            })
            .then((res) => {
                fileDownload(res.data, `${filename}.pdf`);
            });
    };

    const imageStyle = (url) => {
        return {
            backgroundImage: `url(${url})`,
            backgroundSize: "contain",
            objectFit: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "100%",
            // height: "100%",
        };
    };

    const getImageSrc = (linkType) => `/image/${linkType.toLowerCase()}.png`;

    const classNameImage =
        "aspect-[1/1] rounded-lg overflow-hidden mx-auto cursor-pointer";

    const handleDateExpire = (startDate, endDate) => {
        const currentTime = new Date().getTime();
        if (!startDate && !endDate) return true;
        if (
            startDate &&
            endDate &&
            startDate <= currentTime &&
            currentTime <= endDate
        )
            return true;
        if (!startDate && endDate && currentTime <= endDate) return true;
        if (startDate && !endDate && currentTime >= startDate) return true;
        return false;
    };
    useEffect(() => {
        const newData = {};
        const { faceImg, onlineCard_Data } = publishData;
        if (onlineCard_Data) {
            Object.keys(onlineCard_Data).forEach((key) => {
                newData[key] = onlineCard_Data[key]
                    .filter((obj) => {
                        if (key === "selfProfile") {
                            return obj && Object.keys(obj).length > 0;
                        }
                        return true;
                    })
                    .map((obj) => ({
                        ...obj,
                        type: key,
                    }));
            });
            const combinedArray = Object.values(newData)
                .flat()
                .filter(
                    (obj) =>
                        handleDateExpire(obj?.startTime, obj?.endTime) &&
                        !(
                            obj.type === "selfProfile" &&
                            obj.data &&
                            Object.keys(obj.data).length === 0
                        )
                )
                .sort((a, b) => a.order - b.order);
            setNewProfileData(combinedArray);
        }
        setFaceImg(faceImg);
        if (publishData?.idCard && publishData?.idCard.idCard) {
            setImages(publishData?.idCard && publishData?.idCard.idCard);
        }
    }, [publishData, isUpdated]);
    const style = `
        .opacity-background::before{
            content: "";
            background-image:${
                publishData?.bgURL ? `url(${publishData?.bgURL})` : ""
            };
            background-repeat: no-repeat;
            background-position: top center;
            position: absolute;
            opacity: 0.6;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
        }
    `;

    return (
        <div
            className=" w-full relative"
            style={{
                backgroundColor: publishData?.bgColor,
            }}
        >
            <style>{style}</style>
            {/* <Link to="/dashboard" className="inline-block"><img alt="logo" src="/image/logo.png" width="150" className='pt-3 pl-5 mb-5' /></Link> */}
            <div className="mx-auto opacity-background max-w-[480px]">
                {!notShowBack &&
                    previousPage /* && window.history.length > 2 */ && (
                        <Link to={-1}>
                            <img
                                src="/image/turn-left.png"
                                alt="return"
                                className="relative w-[40px] h-[60px] cursor-pointer py-2"
                            ></img>
                        </Link>
                    )}

                <div className="relative min-w-[335px] w-full max-h-[500px] aspect-[4/3]  border-1 overflow-hidden mx-auto">
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={10}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        className="relative min-w-[335px] w-full h-full aspect-[4/3] border-1 overflow-hidden mx-auto cursor-pointer"
                    >
                        {images.map((image, index) => (
                            <SwiperSlide
                                key={index}
                                onClick={() => {
                                    setIsShowPreview(true);
                                    setPreviewImageUrl(image);
                                }}
                            >
                                <div
                                    className={`relative aspect-[4/3] overflow-hidden mx-auto flex items-center justify-center`}
                                >
                                    <label className="h-full w-full">
                                        <img
                                            src={image}
                                            className="max-w-full max-h-full object-contain"
                                            alt=""
                                            style={{
                                                objectFit: "contain",
                                                backgroundSize: "contain",
                                                aspectRatio: "4/3",
                                                width: "100%",
                                                height: "100%",
                                            }} // ここで object-fit: contain を適用
                                        />
                                    </label>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="flex justify-center mt-8 mb-[40px] items-center relative">
                    <div className="w-[120px] rounded-full bg-white overflow-hidden relative cursor-pointer">
                        {faceImg ? (
                            <img
                                src={faceImg}
                                alt="Avatar"
                                onClick={() => {
                                    setIsShowPreview(true);
                                    setPreviewImageUrl(faceImg);
                                }}
                                className="w-full h-full object-contain"
                            />
                        ) : (
                            <svg
                                className="w-full h-full text-gray-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="0.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="12" y1="10" x2="12" y2="14" />
                                <line x1="10" y1="12" x2="14" y2="12" />
                            </svg>
                        )}
                    </div>
                    <div className="w-[55%] py-1 pl-4">
                        <div
                            name="online_name"
                            className="w-fit h-8 text-xl m-1 px-1 bg-white/50"
                        >
                            {publishData?.realName || username || "氏名"}
                        </div>
                        <div className="grid grid-flow-col w-fit gap-2">
                            {publishData?.socialLink &&
                                publishData?.socialLink.socialLink &&
                                publishData?.socialLink.socialLink.map(
                                    (item, index) =>
                                        item.icon_link !== "/image/.png" && (
                                            <Link
                                                to={
                                                    isValidEmail(item.urlLink)
                                                        ? `mailto:${item.urlLink}`
                                                        : item.urlLink
                                                        ? handleTextLink(
                                                              item.urlLink
                                                          )
                                                        : ""
                                                }
                                                target="_blank"
                                                key={index}
                                            >
                                                <img
                                                    className="shrink-0 w-[50px] max-h-[50px] aspect-square max-[800px]:w-[40px] max-[800px]:h-[40px] cursor-pointer rounded-md"
                                                    src={
                                                        item.iconLink ||
                                                        getImageSrc(
                                                            item.title
                                                        ) ||
                                                        "/image/plus.png"
                                                    }
                                                    alt="edit"
                                                    title={item.title}
                                                />
                                            </Link>
                                        )
                                )}
                        </div>
                    </div>
                </div>
                {/* <DraggableForm /> */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: publishData?.cardColor ?? "white",
                        backgroundImage:
                            publishData?.cardURL && publishData?.cardURL !== ""
                                ? `url(${publishData?.cardURL})`
                                : "",
                        backgroundSize: "cover",
                    }}
                    className="rounded-md shadow-lg p-4 mb-[30px] w-[90%] mx-auto relative"
                >
                    {publishData?.companyName && (
                        <div
                            className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <CompanyNameIcon />
                            </div>
                            {publishData?.companyName}
                        </div>
                    )}
                    {publishData?.position && (
                        <div
                            className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <PositionIcon />
                            </div>
                            {publishData?.position}
                        </div>
                    )}
                    {publishData?.phoneNumber && (
                        <Link to={`tel:${publishData.phoneNumber}`}>
                            <div
                                className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                                style={{
                                    margin: "8px",
                                    borderBottom: "0.5px solid",
                                    borderColor: "#7a7b7d",
                                    padding: "8px",
                                    color: publishData?.wordColor,
                                }}
                            >
                                <div className="w-8">
                                    <HotlineIcon />
                                </div>
                                {publishData.phoneNumber}
                            </div>
                        </Link>
                    )}
                    {publishData?.mobilePhoneNumber && (
                        <div
                            className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <PhoneIcon />
                            </div>
                            <a href={`tel:${publishData?.mobilePhoneNumber}`}>
                                モバイルに電話する
                            </a>
                        </div>
                    )}
                    {publishData?.mailAddress && (
                        <div
                            className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <MailIcon />
                            </div>
                            <a href={`mailto:${publishData?.mailAddress}`}>
                                メールを送る
                            </a>
                        </div>
                    )}
                    {publishData?.address && (
                        <div
                            className="flex gap-4 cursor-grab active:cursor-grabbing text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <LocationIcon />
                            </div>
                            <a
                                href={`http://maps.google.com?q=${publishData?.address}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {publishData?.address}
                            </a>
                        </div>
                    )}
                    {publishData?.company_url && (
                        <div
                            className="flex gap-4 text-lg lg:text-base dark:text-white w-[96%]"
                            style={{
                                margin: "8px",
                                borderBottom: "0.5px solid",
                                borderColor: "#7a7b7d",
                                padding: "8px",
                                color: publishData?.wordColor,
                            }}
                        >
                            <div className="w-8">
                                <LinkIcon
                                    color="primary"
                                    // sx={{ width: "30px" }}
                                    fontSize="large"
                                />
                            </div>
                            <a
                                href={handleTextLink(publishData?.company_url)}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {publishData?.company_url}
                            </a>
                        </div>
                    )}
                </div>
                <div className="w-full relative">
                    {newProfileData.map((item, index) => (
                        <div key={index} className="py-3">
                            {item.type === "imgLink1" && (
                                <div>
                                    {/* <div
                                        className={classNameImage}
                                        style={imageStyle(item.url)}
                                        onClick={() => {
                                            setIsShowPreview(true);
                                            setPreviewImageUrl(item.url);
                                        }}
                                    ></div> */}
                                    <Swiper
                                        modules={[Pagination]}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        pagination={{ clickable: true }}
                                        className="relative min-w-[335px] w-full max-h-[500px] aspect-[4/3] border-1 overflow-hidden mx-auto cursor-pointer"
                                    >
                                        {typeof item.url === "string" ? (
                                            <SwiperSlide
                                                key={index}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link
                                                        )
                                                    );
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${item.url})`,
                                                        backgroundSize:
                                                            "contain",
                                                        objectFit: "contain",
                                                        backgroundPosition:
                                                            "center",
                                                        backgroundRepeat:
                                                            "no-repeat",
                                                        scale: 1,
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                ></div>
                                            </SwiperSlide>
                                        ) : (
                                            Array.isArray(item.url) &&
                                            item.url
                                                .filter((item) => item)
                                                .map((image, urlIndex) => (
                                                    <SwiperSlide
                                                        key={urlIndex}
                                                        onClick={() => {
                                                            setIsShowPreview(
                                                                true
                                                            );
                                                            setPreviewImageUrl(
                                                                image
                                                            );
                                                            setUrlPreview(
                                                                handleTextLink(
                                                                    item.link
                                                                )
                                                            );
                                                        }}
                                                        className=""
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundImage: `url(${image})`,
                                                                backgroundSize:
                                                                    "contain",
                                                                objectFit:
                                                                    "contain",
                                                                backgroundPosition:
                                                                    "center",
                                                                backgroundRepeat:
                                                                    "no-repeat",
                                                                scale: 1,
                                                                width: "100%",
                                                                height: "100%",
                                                            }}
                                                        ></div>
                                                    </SwiperSlide>
                                                ))
                                        )}
                                    </Swiper>
                                    <div>
                                        <div className="w-full text-sm text-center px-3 break-all">
                                            {item.title}
                                        </div>
                                        <CustomTypography
                                            className="w-full text-sm text-center px-3 break-words text-gray-500"
                                            text={item.text}
                                            expandable
                                            maxLines={3}
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "imgLink2" && (
                                <div className="flex">
                                    {item.url1 && (
                                        <div className="w-full">
                                            <div
                                                className={classNameImage}
                                                style={imageStyle(item.url1)}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url1
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link1
                                                        )
                                                    );
                                                }}
                                            />
                                            <div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text1}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {item.url2 && (
                                        <div className="w-full">
                                            <div
                                                className={classNameImage}
                                                style={imageStyle(item.url2)}
                                                onClick={() => {
                                                    setIsShowPreview(true);
                                                    setPreviewImageUrl(
                                                        item.url2
                                                    );
                                                    setUrlPreview(
                                                        handleTextLink(
                                                            item.link2
                                                        )
                                                    );
                                                }}
                                            ></div>
                                            <div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text2}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            {item.type === "imgLink3" && (
                                <>
                                    {/* パソコン画面の大きさの時 */}
                                    <div className="hidden lg:flex">
                                        {item.url1 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url1
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url1
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link1
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text1}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                        {item.url2 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url2
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url2
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link2
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text2}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                        {item.url3 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url3
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url3
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link3
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title3}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text3}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* それより小さい画面の時 */}
                                    <div className="lg:hidden">
                                        <Swiper
                                            spaceBetween={0}
                                            slidesPerView={1}
                                        >
                                            {[
                                                {
                                                    url: item.url1,
                                                    title: item.title1,
                                                    text: item.text1,
                                                    link: item.link1,
                                                },
                                                {
                                                    url: item.url2,
                                                    title: item.title2,
                                                    text: item.text2,
                                                    link: item.link2,
                                                },
                                                {
                                                    url: item.url3,
                                                    title: item.title3,
                                                    text: item.text3,
                                                    link: item.link3,
                                                },
                                            ]
                                                .filter((item) => item.url)
                                                .map((image, i) => (
                                                    <SwiperSlide key={i}>
                                                        <div className="w-full">
                                                            <div
                                                                className={
                                                                    classNameImage
                                                                }
                                                                style={imageStyle(
                                                                    image.url
                                                                )}
                                                                onClick={() => {
                                                                    setIsShowPreview(
                                                                        true
                                                                    );
                                                                    setPreviewImageUrl(
                                                                        image.url
                                                                    );
                                                                    setUrlPreview(
                                                                        handleTextLink(
                                                                            image.link
                                                                        )
                                                                    );
                                                                }}
                                                            ></div>
                                                            <div className="w-full text-sm font-bold text-center px-3">
                                                                {image.title}
                                                            </div>
                                                            <CustomTypography
                                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                                expandable
                                                                maxLines={3}
                                                                text={
                                                                    image.text
                                                                }
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    </div>
                                </>
                            )}
                            {item.type === "imgLink4" && (
                                <>
                                    {/* パソコン画面の大きさの時 */}
                                    <div className="hidden lg:flex">
                                        {item.url1 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url1
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url1
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link1
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title1}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text1}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                        {item.url2 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url2
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url2
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link2
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title2}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text2}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                        {item.url3 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url3
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url3
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link3
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title3}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text3}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                        {item.url4 && (
                                            <div className="w-full">
                                                <div
                                                    className={classNameImage}
                                                    style={imageStyle(
                                                        item.url4
                                                    )}
                                                    onClick={() => {
                                                        setIsShowPreview(true);
                                                        setPreviewImageUrl(
                                                            item.url4
                                                        );
                                                        setUrlPreview(
                                                            handleTextLink(
                                                                item.link4
                                                            )
                                                        );
                                                    }}
                                                ></div>
                                                <div className="w-full text-sm text-center px-3 break-all">
                                                    {item.title4}
                                                </div>
                                                <CustomTypography
                                                    className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                    text={item.text4}
                                                    expandable
                                                    maxLines={3}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* それより小さい画面の時 */}
                                    <div className="lg:hidden">
                                        <Swiper
                                            spaceBetween={0}
                                            slidesPerView={1}
                                        >
                                            {[
                                                {
                                                    url: item.url1,
                                                    title: item.title1,
                                                    text: item.text1,
                                                    link: item.link1,
                                                },
                                                {
                                                    url: item.url2,
                                                    title: item.title2,
                                                    text: item.text2,
                                                    link: item.link2,
                                                },
                                                {
                                                    url: item.url3,
                                                    title: item.title3,
                                                    text: item.text3,
                                                    link: item.link3,
                                                },
                                                {
                                                    url: item.url4,
                                                    title: item.title4,
                                                    text: item.text4,
                                                    link: item.link4,
                                                },
                                            ]
                                                .filter((item) => item.url)
                                                .map((image, i) => (
                                                    <SwiperSlide key={i}>
                                                        <div className="w-full">
                                                            <div
                                                                className={
                                                                    classNameImage
                                                                }
                                                                style={imageStyle(
                                                                    image.url
                                                                )}
                                                                onClick={() => {
                                                                    setIsShowPreview(
                                                                        true
                                                                    );
                                                                    setPreviewImageUrl(
                                                                        image.url
                                                                    );
                                                                    setUrlPreview(
                                                                        handleTextLink(
                                                                            image.link
                                                                        )
                                                                    );
                                                                }}
                                                            ></div>
                                                            <div className="w-full text-sm text-center px-3 break-all">
                                                                {image.title}
                                                            </div>
                                                            <CustomTypography
                                                                className="w-full text-sm text-center px-3 break-words text-gray-500"
                                                                text={
                                                                    image.text
                                                                }
                                                                expandable
                                                                maxLines={3}
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                        </Swiper>
                                    </div>
                                </>
                            )}
                            {item.type === "videoLink" && (
                                <div className="relative w-full h-auto aspect-video rounded-lg border border-white overflow-hidden mx-auto">
                                    {(item.data || item.url) && (
                                        <ReactPlayer
                                            url={
                                                item.url ||
                                                item.data ||
                                                item.data.url
                                            }
                                            playing={isPlaying}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            onClick={() => {
                                                handlePlaying();
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            {item.type === "text" && (
                                <div
                                    style={{
                                        backgroundColor:
                                            item.bgColor || "transparent",
                                    }}
                                    className="w-full h-auto text-wrap "
                                >
                                    <div className="p-4 text-wrap break-all ql-editor custom-quilljs">
                                        <CustomTypography
                                            html={new QuillDeltaToHtmlConverter(
                                                item.text.ops,
                                                {}
                                            ).convert()}
                                            maxLines={5}
                                            expandable
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "textLink" && (
                                <div
                                    style={{
                                        backgroundColor:
                                            item.bgColor || "transparent",
                                    }}
                                    className="w-full h-auto text-wrap "
                                >
                                    <div className="p-4 text-wrap break-all ql-editor custom-quilljs">
                                        <CustomTypography
                                            html={new QuillDeltaToHtmlConverter(
                                                item.text.ops,
                                                {}
                                            ).convert()}
                                            maxLines={5}
                                            expandable
                                        />
                                    </div>
                                </div>
                            )}
                            {item.type === "mapLink" && (
                                <div className="aspect-video">
                                    <iframe
                                        title="Google Map"
                                        width="100%"
                                        height="100%"
                                        style={{ border: "0" }}
                                        src={
                                            item.mapLink.startsWith(
                                                "https://www.google.com/maps/embed"
                                            )
                                                ? item.mapLink
                                                : item.mapLink.startsWith(
                                                      "https://www.google.com/maps/"
                                                  )
                                                ? generateEmbedUrl(item.mapLink)
                                                : getEmbedUrl(item.mapLink)
                                        }
                                        allowFullScreen
                                    />
                                </div>
                            )}
                            {item.type === "map" && (
                                <div
                                    style={{ width: "100%" }}
                                    className="aspect-video"
                                >
                                    <iframe
                                        title="Google Map"
                                        width="100%"
                                        style={{ border: "0" }}
                                        src={
                                            item.data.startsWith(
                                                "https://www.google.com/maps/embed"
                                            )
                                                ? item.data
                                                : item.data.startsWith(
                                                      "https://www.google.com/maps/"
                                                  )
                                                ? generateEmbedUrl(item.data)
                                                : getEmbedUrl(item.data)
                                        }
                                        className="aspect-video"
                                        allowFullScreen
                                    />
                                </div>
                            )}
                            {item.type === "spaceAdd" && (
                                <div
                                    className={`w-full p-2 bg-transparent opacity-50 rounded-lg`}
                                    style={{ height: item.space * 5 }}
                                ></div>
                            )}
                            {item.type === "selfProfile" && (
                                <div className={`w-full p-2 `}>
                                    <div
                                        style={{
                                            backgroundColor:
                                                item.color || "white",
                                        }}
                                        className="py-2 px-4 text-white rounded-lg cursor-pointer hover:opacity-90 active:opacity-80 overflow-hidden break-all"
                                    >
                                        {item.title}
                                    </div>
                                    <div className="w-full text-sm rounded-md p-2 px-3 my-1 overflow-hidden break-all">
                                        {item.content &&
                                            item.content
                                                .split("\n")
                                                .map((line, index) => (
                                                    <CustomTypography
                                                        key={index}
                                                        text={line}
                                                        maxLines={3}
                                                        expandable
                                                    />
                                                    // <p key={index}>{line}</p>
                                                ))}
                                    </div>
                                </div>
                            )}
                            {item.type === "slideLink" && (
                                <div className="flex flex-col items-center w-full">
                                    <div
                                        className="overflow-y-auto w-full aspect-[4/3] bg-slate-300"
                                        ref={docContainerRef}
                                    >
                                        <PdfLoader
                                            file={item.url}
                                            pdfWidth={pdfWidth}
                                        />
                                    </div>
                                    <div className="w-full text-sm font-bold text-center px-3  break-all">
                                        {item.title}
                                    </div>
                                    <div className="w-full text-sm text-center px-3 break-all">
                                        <CustomTypography
                                            text={item.text}
                                            expandable
                                            maxLines={3}
                                        />
                                    </div>
                                    {item?.enableDownload && (
                                        <button
                                            className="border-2 border-[#FF8C05] bg-white p-2 px-7 mt-3 rounded-full text-red-600 flex drop-shadow-lg font-bold"
                                            onClick={() =>
                                                handleDownload(
                                                    item.url,
                                                    item.title || "document"
                                                )
                                            }
                                        >
                                            ダウンロード
                                            <img
                                                className="shrink-0"
                                                alt="download"
                                                src="/image/ダウンロード.png"
                                                width="25"
                                            />
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <PreviewModal
                open={isShowPreview}
                onClose={() => {
                    setIsShowPreview(false);
                    setPreviewImageUrl("");
                    setUrlPreview("");
                }}
                imageUrl={previewImageUrl}
                imageLink={urlPreview}
            />
        </div>
    );
}

export const PreviewModal = ({ open, onClose, imageUrl, imageLink }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                border: "none",
                "& .MuiModal-backdrop": {
                    backgroundColor: "rgba(32,32,32,0.95);",
                },
            }}
        >
            <>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "fit-content",
                        border: "none",
                        bgcolor: "white",
                    }}
                >
                    <img
                        className="object-cover min-w-80 max-h-[99vh] md:min-h-[50vh] rounded-md cursor-pointer"
                        src={imageUrl}
                        onClick={() => {
                            if (!imageLink) return;
                            const a = document.createElement("a");
                            a.href = imageLink;
                            a.target = "_blank";
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }}
                        alt="..."
                    />
                </Box>
                <Close
                    sx={{
                        position: "absolute",
                        top: "4px",
                        right: "4px",
                        color: "white",
                        cursor: "pointer",
                    }}
                    onClick={onClose}
                />
            </>
        </Modal>
    );
};
