import React, { useState, useEffect } from "react";
import { Apis, urlAPI } from "../api";
import {
    Autocomplete,
    Badge,
    Button,
    Dialog,
    DialogContent,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const EstimatedEarnings = ({ data, info, fetchData }) => {
    const [openBacking, setOpenBacking] = useState(false);
    const [bankData, setBankData] = useState({
        bankLabel: null,
        branchLabel: null,
        card_type: "NOR",
    });
    const [loadingBank, setLoadingBank] = useState(false);
    const [isDisableSaveBank, setIsDisableSaveBank] = useState(true);
    const [banks, setBanks] = useState([]);
    const [branches, setBranches] = useState([]);

    const fetchDataBank = async (value) => {
        setLoadingBank(true);
        try {
            const response = (
                await Apis.myGet(
                    `${urlAPI.searchBank}?keySearch=${value ?? ""}`
                )
            )?.data;
            const data = response?.map((item) => {
                return {
                    ...item,
                    label: item.name + " 、" + item.code,
                };
            });
            setBanks(data);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoadingBank(false);
        }
    };

    const getBankBranches = async (bankCode, keySearch) => {
        if (!bankCode) {
            setBranches([]);
            return;
        }
        const response = (
            await Apis.myGet(
                `${urlAPI.searchBankBranch}?keySearch=${keySearch}&bankCode=${bankCode}`
            )
        )?.data;

        const data = response?.map((item) => {
            return {
                ...item,
                label: item.name + " 、" + item.code,
            };
        });
        setBranches(data);
    };

    const handleClickOpenBanking = () => {
        setOpenBacking(true);
        if (info?.user_bank) {
            setBankData({
                bank_code: info.user_bank?.bank_code,
                bank_name_kana: info.user_bank?.bank_name_kana,
                bank_name_hira: info.user_bank?.bank_name_hira,
                bank_name: info.user_bank?.bank_name,
                bankLabel: {
                    code: info.user_bank?.bank_code,
                    kana: info.user_bank?.bank_name_kana,
                    hira: info.user_bank?.bank_name_hira,
                    name: info.user_bank?.bank_name,
                    label:
                        info.user_bank?.bank_name +
                        " 、" +
                        info.user_bank?.bank_code,
                },
                bank_branch_code: info.user_bank?.bank_branch_code,
                bank_branch_kana: info.user_bank?.bank_branch_kana,
                bank_branch_hira: info.user_bank?.bank_branch_hira,
                bank_branch_name: info.user_bank?.bank_branch_name,
                branchLabel: {
                    code: info.user_bank?.bank_branch_code,
                    kana: info.user_bank?.bank_branch_kana,
                    hira: info.user_bank?.bank_branch_hira,
                    name: info.user_bank?.bank_branch_name,
                    label:
                        info.user_bank?.bank_branch_name +
                        " 、" +
                        info.user_bank?.bank_branch_code,
                },
                card_type: info.user_bank?.card_type,
                bank_number: info.user_bank?.bank_number,
                name: info.user_bank?.name,
            });
        }
    };

    const handleCloseBanking = () => {
        setOpenBacking(false);
        setBankData({ bankLabel: null, branchLabel: null, card_type: "NOR" });
    };

    const handleSelectBank = async (value) => {
        if (value) {
            const data = {
                ...bankData,
                bank_code: value.code,
                bank_name_kana: value.kana,
                bank_name_hira: value.hira,
                bank_name: value.name,
                bankLabel: value,
            };
            setBankData(data);
            await getBankBranches(value.code, "");
        } else {
            setBankData({
                ...bankData,
                bank_code: null,
                bank_name_kana: null,
                bank_name_hira: null,
                bank_name: null,
                bankLabel: null,
            });
            setBranches([]);
        }
    };

    const handleSelectBranch = (value) => {
        if (value) {
            const data = {
                ...bankData,
                bank_branch_code: value.code,
                bank_branch_kana: value.kana,
                bank_branch_hira: value.hira,
                bank_branch_name: value.name,
                branchLabel: value,
            };
            setBankData(data);
        } else {
            setBankData({
                ...bankData,
                bank_code: null,
                bank_name_kana: null,
                bank_name_hira: null,
                bank_name: null,
                branchLabel: null,
            });
        }
    };

    const handleChargeCardType = (event) => {
        setBankData({ ...bankData, card_type: event.target.value });
    };

    const handleSaveBankInfo = async () => {
        setLoadingBank(true);
        const response = (
            await Apis.myPost(urlAPI.createUserBank, {
                user_id: info.id,
                bank_code: bankData.bank_code,
                bank_name_kana: bankData.bank_name_kana,
                bank_name_hira: bankData.bank_name_hira,
                bank_name: bankData.bank_name,
                bank_branch_code: bankData.bank_branch_code,
                bank_branch_kana: bankData.bank_branch_kana,
                bank_branch_hira: bankData.bank_branch_hira,
                bank_branch_name: bankData.bank_branch_name,
                card_type: bankData.card_type,
                bank_number: bankData.bank_number,
                name: bankData.name,
            })
        )?.data;
        setLoadingBank(false);
        if (response) {
            await fetchData();
            handleCloseBanking();
        }
    };

    useEffect(() => {
        if (
            bankData.bankLabel &&
            bankData.branchLabel &&
            bankData.name &&
            bankData.bank_number
        ) {
            setIsDisableSaveBank(false);
        } else {
            setIsDisableSaveBank(true);
        }
    }, [bankData]);
    // Render the table
    return (
        <div className="shadow rounded-lg py-5 overflow-x-auto">
            <table className="w-full min-w-[900px] text-center ">
                <tbody>
                    <tr>
                        <th className="py-3">No</th>
                        <th className="py-3">期間</th>
                        <th className="py-3">つながり人数</th>
                        <th className="py-3">前月増加分</th>
                        <th className="py-3">購入者数</th>
                        <th className="py-3">販売代金合計</th>
                        <th className="py-3">推定収益合計</th>
                        <th className="py-3">振込結果</th>
                    </tr>
                    {data.map((item, index) => (
                        <tr
                            key={index}
                            className={`even:bg-white odd:bg-slate-50`}
                        >
                            <td className="p-2">{item.id}</td>
                            <td className="p-2">
                                {item.lastDate}~{item.date}
                            </td>
                            <td className="p-2">{item.total_user}</td>
                            <td className="p-2">
                                {item.count_contact_increase_this_month}
                            </td>
                            <td className="p-2">{item.total_buyer}</td>
                            <td className="p-2">
                                {/* <div className="text-blue-500">
                                    +{item.saleIncrease}
                                </div> */}
                                <div>{item.total_sale}</div>
                            </td>
                            <td className="p-2">
                                {/* <div className="text-blue-500">
                                    +{item.amount}
                                </div> */}
                                <div>{item.total_revenue}</div>
                            </td>
                            <td className="p-2">
                                {!item.isPaid ? (
                                    !!info.user_bank ? (
                                        <div className="rounded-lg inline-block bg-yellow-400 text-white text-sm p-1 md:px-4 !min-w-24">
                                            未振込
                                        </div>
                                    ) : (
                                        <Badge variant="dot" color="error">
                                            <div
                                                className="rounded-lg inline-block cursor-pointer bg-yellow-400 hover:bg-red-500 active:bg-yellow-600 text-white text-sm p-1 md:px-4 !min-w-24"
                                                onClick={() =>
                                                    handleClickOpenBanking()
                                                }
                                            >
                                                未振込
                                            </div>
                                        </Badge>
                                    )
                                ) : (
                                    <div className="rounded-lg inline-block  bg-blue-500 text-white text-sm p-1 md:px-4 !min-w-24">
                                        <div>振込済み</div>
                                        <div>{item.date}</div>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Dialog open={openBacking} onClose={handleCloseBanking}>
                <DialogContent>
                    <div className="px-10 pt-10">
                        <div className="w-full pl-20 flex gap-4 items-center mb-2">
                            <div className="text-2xl font-bold">
                                銀行口座入力フォーム
                            </div>
                            <HelpOutlineIcon />
                        </div>
                        <div
                            className="ml-20 w-full break-normal mb-4"
                            style={{ maxWidth: "400px" }}
                        >
                            登録者名と口座名義が同一であることをご確認の
                            うえ、指定の口座を入力してください
                        </div>
                        <div className="w-full break-normal mb-1">
                            金融機関名を1文字以上入力してください。
                            (候補が表示されます)、銀行番号4ケタでもOK
                        </div>
                        <Autocomplete
                            getOptionLabel={(option) => option.label}
                            options={banks}
                            loading={loadingBank}
                            value={bankData?.bankLabel}
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                            onInputChange={(event, value) => {
                                fetchDataBank(value);
                            }}
                            onChange={(event, value) => {
                                handleSelectBank(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    className="no-padding"
                                    placeholder="例:三菱UFJ銀行 、0812"
                                    {...params}
                                />
                            )}
                        />
                        <div className="w-full mt-6 mb-1">
                            支店名を1文字以上入力してください。
                            (候補が表示されます)、支店番号3ケタでもOK
                        </div>
                        <Autocomplete
                            getOptionLabel={(option) => option.label}
                            options={branches}
                            value={bankData?.branchLabel}
                            onInputChange={(event, value) => {
                                getBankBranches(bankData?.bank_code, value);
                            }}
                            onChange={(event, value) => {
                                handleSelectBranch(value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    className="no-padding"
                                    placeholder="例:本店 、001"
                                    {...params}
                                />
                            )}
                        />
                        <RadioGroup
                            row
                            value={bankData?.card_type}
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="mb-6 justify-between"
                            onChange={handleChargeCardType}
                        >
                            <FormControlLabel
                                value="NOR"
                                control={<Radio />}
                                label="普通"
                            />
                            <FormControlLabel
                                value="ACC"
                                control={<Radio />}
                                label="当座"
                            />
                            <FormControlLabel
                                value="SAV"
                                control={<Radio />}
                                label="貯蓄"
                            />
                            <FormControlLabel
                                value="OTH"
                                control={<Radio />}
                                label="その他"
                            />
                        </RadioGroup>
                        <div className="w-full break-normal">口座番号</div>
                        <input
                            className="border border-gray-300 hover:border-black rounded px-[9px] py-[7px] mb-6 block w-full"
                            value={bankData?.bank_number}
                            onChange={(event) => {
                                setBankData({
                                    ...bankData,
                                    bank_number: event.target.value,
                                });
                            }}
                        ></input>
                        <div className="w-full break-normal">
                            口座名義人(カタカナ入力)
                        </div>
                        <input
                            className="border border-gray-300 hover:border-black rounded px-[9px] py-[7px] mb-6 block w-full"
                            value={bankData?.name}
                            onChange={(event) => {
                                setBankData({
                                    ...bankData,
                                    name: event.target.value,
                                });
                            }}
                        ></input>
                    </div>
                    <div className="px-10 pb-10 flex flex-row-reverse">
                        <Button
                            variant="contained"
                            onClick={handleSaveBankInfo}
                            disabled={isDisableSaveBank}
                        >
                            保存する
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default EstimatedEarnings;
